import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Button, Fade, Modal, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { TxState } from '../../web3/types';
import TxLink from '../TxLink';
import { ReactComponent as DDDMenuLogo } from '../../images/dotdotmenu/logo.svg';

type Props = {
    txState: TxState;
    currentTxHash: string;
    setTxState: (state: any) => void;
}

export default function TxModal({ txState, currentTxHash, setTxState }: Props) {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setTxState('none');
    };

    useEffect(() => {
        if (txState === 'pending' || txState === 'confirm') {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [txState]);

    return (<Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#000000',
            background: '#ffffff',
            borderRadius: '4px',
            width: '416px',
            height: '426px',
            boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
          }}>
            {open ? <Box sx={{
                paddingX: '21px',
                paddingTop: '80px',
                paddingBottom: '21px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                {txState === 'confirm' ? <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: '60px' }}>
                        <DDDMenuLogo width="180px" />
                    </Box>
                    <Typography sx={{ fontSize: '24px', lineHeight: '30px', fontWeight: 700, color: '#002237' }}>Confirm in Wallet</Typography>
                </Box> : <></>}
                {txState === 'pending' ? <>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <ArrowUpwardIcon sx={{ fontSize: '72px', color: '#976CF3', mb: '20px' }} />
                        <Typography sx={{ fontSize: '24px', lineHeight: '30px', fontWeight: 700, color: '#002237' }}>Transaction submitted</Typography>
                        <TxLink currentTxHash={currentTxHash} />
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <Button variant="blue" sx={{ width: '100%' }} onClick={handleClose}>Close</Button>
                    </Box>
                </> : <></>}
            </Box> : <></>}
          </Box>
        </Fade>
      </Modal>
    );
}