import { ChangeEvent, useState } from 'react';
import { Grid, Box, TablePagination, Typography } from '@mui/material';
import TableItem from './TableItem';
import { HistoricalVote } from '../HistoricalVotesHandler';

type PoolHeadersProps = {
    headers: Array<{
        title: string,
        spacing: number,
    }>;
}

function PoolTableHeaders({ headers }: PoolHeadersProps) {
    return (<Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            border: 'unset',
            boxShadow: 'unset',
            mb: 'unset'
        }}>
        <Grid container sx={{ paddingX: '24px' }}>
            {headers.map((h: any) =>
            <Grid item xs={4} lg={h.spacing} key={h.title}>
                <Typography variant="subtitle2">{h.title}</Typography>
            </Grid>
            )}
        </Grid>
    </Box>);
}

type Props = {
    historicalVotes: Array<HistoricalVote>;
}

const DEFAULT_LP_TOKENS_PER_PAGE = 5;

export default function HistoricalVotesTable({ historicalVotes }: Props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_LP_TOKENS_PER_PAGE);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (<Box>
        <PoolTableHeaders headers={[
            {
                title: 'Pool',
                spacing: 2
            },
            {
                title: 'Total Bribes',
                spacing: 2
            },
            {
                title: 'Bribes/vlDDD',
                spacing: 2
            },
            {
                title: 'EPX/day',
                spacing: 2
            },
            {
                title: 'Total Votes',
                spacing: 2
            },
            {
                title: 'Your Votes',
                spacing: 2
            },
        ]} />
        {historicalVotes.length > 0 ?
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid container sx={{ height: '350px' }}>
                    {historicalVotes
                        .sort((a, b) => b.votes - a.votes)
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((p, k) => (
                            <Grid item xs={12} key={k} sx={{
                                display: 'flex',
                                borderTop: '1px solid #A7B3CE',
                                padding: [0, '8px 24px'],
                                '&:last-child': {
                                    borderBottom: '1px solid #A7B3CE'
                                }
                            }}>
                                <TableItem votes={p} />
                            </Grid>
                        ))
                    }
                </Grid>
                <Box sx={{ paddingX: '24px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <TablePagination
                        sx={{ '& > div > p.MuiTablePagination-displayedRows': { fontSize: '14px' } }}
                        rowsPerPageOptions={[5]}
                        labelRowsPerPage=""
                        component="div"
                        count={historicalVotes.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Box>
        : <Box sx={{ display: 'flex', p: '24px 24px'}}>
            <Typography>No results found</Typography>
        </Box>}
    </Box>);
}
