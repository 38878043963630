import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Tooltip } from '@mui/material';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import type { LinkProps } from "react-router-dom";
import { Link as MuiLink } from '@mui/material';
import { ReactComponent as DDDMenuLogo } from '../../../images/dotdotmenu/logo.svg';
import { ReactComponent as StakeLogo } from '../../../images/menu/stake/logo.svg';
import { ReactComponent as LockLogo } from '../../../images/menu/lock/logo.svg';
import { ReactComponent as ConvertLogo } from '../../../images/menu/convert/logo.svg';
import { ReactComponent as VoteLogo } from '../../../images/menu/vote/logo.svg';
import { ReactComponent as BribeLogo } from '../../../images/menu/bribe/logo.svg';
import { ReactComponent as DocsLogo } from '../../../images/menu/docs/logo.svg';
import { ReactComponent as TelegramLogo } from '../../../images/menu/telegram/logo.svg';
import { ReactComponent as TwitterLogo } from '../../../images/menu/twitter/logo.svg';
import { ReactComponent as AuditLogo } from '../../../images/menu/audit/logo.svg';

let pages = [
  {
      name: 'Stake',
      url: '/stake',
      disabled: false,
      icon: <StakeLogo />
  },
  {
    name: 'Lock DDD',
    url: '/lock',
    disabled: false,
    icon: <LockLogo />
  },
  {
    name: 'Convert EPX',
    url: '/convert',
    disabled: false,
    icon: <ConvertLogo />
  },
  {
    name: 'Vote',
    url: '/vote',
    disabled: false,
    icon: <VoteLogo />
  },
  {
    name: 'Bribe',
    url: '/bribe',
    disabled: false,
    icon: <BribeLogo />
  },
];

let links = [
  {
      name: 'Docs',
      url: 'https://docs.dotdot.finance',
      disabled: false,
      icon: <DocsLogo />
  },
  {
    name: 'Telegram',
    url: 'https://t.me/dotdotfinance',
    disabled: false,
    icon: <TelegramLogo />
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/dotdotdotfi',
    disabled: false,
    icon: <TwitterLogo />
  },
  {
      name: 'Audit',
      url: 'https://github.com/dotdot-ellipsis/dotdot-contracts/blob/main/PeckShield-Audit-Report-DotDot.pdf',
      disabled: false,
      icon: <AuditLogo />
  }
];

function ActiveLink({ children, to, ...props }: LinkProps) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (<Box sx={{ '& > a': {  textDecoration: 'none' }}}>
        <Link to={to} {...props}>
            <Box sx={{ '& > button': {
                opacity: match ? 1 : 0.6,
                borderBottom: match ? '8px solid rgba(2, 119, 250, 0.2)' : '8px solid transparent',
                '&::after': {
                content: "''",
                position: 'absolute',
                display: 'block',
                borderBottom: match ? '8px solid #0277FA' : '8px solid transparent',
                paddingTop: '14px',
                width: '100%',
                left: 0,
                },
                borderRadius: 0,
                '&:hover': {
                borderBottom: '8px solid rgba(2, 119, 250, 0.2)',
                },
                '&:hover::after': {
                borderBottom: '8px solid #0277FA',
                }
            } }}>
                {children}
            </Box>
        </Link>
    </Box>);
}

type Props = {
  open: boolean;
}

export default function MenuItems({ open }: Props) {
    return (<>
        <Box>
            <List>
                {pages.map((page, index) => (
                    !page.disabled ? <ActiveLink to={page.url} key={page.name}>
                        <ListItemButton
                            key={page.name}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                            disableRipple
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: '32px',
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                {page.icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={page.name}
                                sx={{
                                    opacity: open ? 1 : 0,
                                    textDecoration: 'none',
                                    color: '#ffffff',
                                    '& > span': {
                                        color: '#ffffff',
                                        fontWeight: 400,
                                        fontSize: '22px',
                                        lineHeight: '27px',
                                    }
                                }}
                            />
                        </ListItemButton>
                    </ActiveLink> :
                    <ListItemButton
                        key={page.name}
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                        disabled
                        disableRipple
                        >
                        <Tooltip key={page.name} arrow title={'Coming soon'} placement="right">
                            <>
                                <ListItemIcon
                                    sx={{
                                    minWidth: '32px',
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    }}
                                >
                                    {page.icon}
                                </ListItemIcon>
                                <ListItemText
                                primary={page.name}
                                sx={{
                                    opacity: open ? 1 : 0,
                                    textDecoration: 'none',
                                    color: '#ffffff',
                                    '& > span': {
                                    color: '#ffffff',
                                    fontWeight: 400,
                                    fontSize: '22px',
                                    lineHeight: '27px',
                                    }
                                }}
                                />
                            </>
                        </Tooltip>
                    </ListItemButton>
                ))}
            </List>
            <Divider sx={{ marginX: '20px', borderTop: '1px dashed #FFFFFF', background: '#1A2037' }} />
            <List>
                {links.map((page, index) => (
                    <MuiLink key={page.url} href={page.url} target="__blank" rel="noreferrer">
                        <ListItemButton
                            key={page.name}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                            disableRipple
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: '32px',
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                {page.icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={page.name}
                                sx={{
                                    opacity: open ? 1 : 0,
                                    textDecoration: 'none',
                                    color: '#ffffff',
                                    '& > span': {
                                        color: '#ffffff',
                                        fontWeight: 400,
                                        fontSize: '22px',
                                        lineHeight: '27px',
                                    }
                                }}
                            />
                        </ListItemButton>
                    </MuiLink>
                ))}
            </List>
            <Divider sx={{ marginX: '20px', borderTop: '1px dashed #FFFFFF', background: '#1A2037' }} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '22px', opacity: 0.8 }}>
            <DDDMenuLogo width="150px" />
        </Box>
    </>);
}
