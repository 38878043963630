import { ChangeEvent, ReactNode, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import BigNumber from 'bignumber.js';

type Props = {
  assetSymbol?: ReactNode;
  balance?: BigNumber;
  maxAmount?: BigNumber;
  setRealInput: (i: BigNumber) => void;
  inputValue: number | string;
  setInputValue: (i: number | string) => void;
  placeholderText?: string;
  balanceText?: string;
  decimals?: number;
}

export default function InputNumber({
  assetSymbol,
  balance,
  maxAmount,
  setRealInput,
  inputValue,
  setInputValue,
  placeholderText = 'Enter Amount',
  balanceText = 'Balance',
  decimals = 10 ** 18
}: Props) {
  const [error, setError] = useState<boolean>(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setError(false);
    const input = parseFloat(event.target.value)
    if (maxAmount?.lte(new BigNumber(input*decimals))) {
      setInputValue(maxAmount.toNumber() / decimals)
      setRealInput(maxAmount);
      return;
    }
    // TODO: need to fix isNaN causing input filled with 0 but unable to
    if (input < 0 || isNaN(input)) {
      setInputValue('');
      setRealInput(new BigNumber(0));
      return;
    }
    setInputValue(input);
    setRealInput(new BigNumber(input*decimals));
  };

  return (
    <Box sx={{
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      '.Mui-focused': { border: '1px solid #A7B3CE' },
      boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.15)'
    }}>
        <TextField
          sx={{ background: '#ffffff' }}
          autoComplete='off'
          type="number"
          value={inputValue === -1 ? '' : inputValue}
          error={error}
          placeholder={placeholderText}
          onChange={handleChange}
          id="input-number"
          InputProps={{
            sx: {
              padding: '2px 0px 2px 0px'
            },
            endAdornment: <>{assetSymbol ? <Box sx={{ ml: 1, minWidth: '80px'}}>
              <Typography sx={{ fontSize: '14px', color: '#000000', opacity: 0.8 }}>
                {assetSymbol}
              </Typography>
            </Box> : <></>}</>,
          }}
          inputProps={{
            inputMode: 'decimal',
            pattern: '[0-9]*',
          }}
          //inputRef={callbackRef}
        />
    </Box>
  );
}
