
import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import Vote from './vote';
import VoteTokenApproval from '../votes/vote-token-approval';
import HistoricalVotes from './historical-votes';

function VotePage() {

    return (<Container sx={{ marginTop: 10, paddingBottom: 4, marginBottom: 3 }}>
        <Box sx={{ width: '100%', my: 3 }}>
            <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} lg={12}>
                    <Vote />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <HistoricalVotes />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <VoteTokenApproval />
                </Grid>
            </Grid>
        </Box>
    </Container>);
}

export default VotePage;
