import React, { useState } from 'react';
import { useOnboardProvider } from '../../../../../providers/onboard-provider';
import LoadingButton from '../../../../../components/LoadingButton';
import CompleteButton from '../../../../../components/CompleteButton';
import { TxState, UserLpDeposit } from '../../../../../web3/types';
import { claim } from '../../../../../web3/LpDepositor/claim';
import BigNumber from 'bignumber.js';
import {  maxUint256 } from '../../../../../web3/utils';
import TxModal from '../../../../../components/TxModal';

type Props = {
    earnings: Array<UserLpDeposit>;
    totalBaseEarningsUSD: number;
    withBonus: boolean;
}

export default function ClaimAllRewards({ earnings, totalBaseEarningsUSD, withBonus }: Props){
    const { signer, account } = useOnboardProvider();

    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const handleClaimAllPoolsRewards = async () => {
        if (signer !== undefined && account !== undefined && account !== null) {
            const poolAddresses = earnings.filter((e) => e.totalBaseClaimableUSD > 0.01).map((e) => e.token);
            const claimMaxAmount = new BigNumber(maxUint256().toString());

            const txFinished = () => {
                setTxState('finished');
            }

            await claim(
                {
                    setTxHash: setCurrentTxHash,
                    setTxState: setTxState,
                    setTxError: () => {},
                    txFinished: txFinished,
                },
                signer,
                account,
                poolAddresses,
                withBonus ? claimMaxAmount : new BigNumber(0)
            );
        }
    }

    return (<>
        {txState === 'none' || txState === 'pending' || txState === 'confirm' ? <LoadingButton
                content={`Claim all DDD & EPX`}
                variant={"blue"}
                onClick={handleClaimAllPoolsRewards}
                disabled={totalBaseEarningsUSD === 0 || txState === 'pending' ? true : false}
                loading={txState === 'pending' ? true : false }
        /> : <CompleteButton content={`Claimed`} />}
        <TxModal txState={txState} currentTxHash={currentTxHash} setTxState={setTxState} />
    </>)
}