
import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Checkbox, Grid, Link, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Web3 from 'web3';
import Multicall from '@dopex-io/web3-multicall';
import { AbiItem } from 'web3-utils';
import BigNumber from 'bignumber.js';
import LpDepositsList from './components/LpDepositsList';
import CardTitle from '../../../components/CardTitle';
import StatsTable from '../../../components/StatsTable';
import ClaimAllRewards from './components/ClaimAllRewards';
import { LpDetails, useApiDataContext } from '../../../providers/api-provider';
import { useOnboardProvider } from '../../../providers/onboard-provider';
import { UserLpDeposit, UserLpDeposts } from '../../../web3/types';
import DDDLpDepositorAbi from '../../../abi/DDDLpDepositor.json';
import EPSLpTokenAbi from '../../../abi/EPSLpToken.json';
import { CONFIG } from '../../../global';
import ZeroState from '../../../components/ZeroState';

const loadData = async (user: string, lps: LpDetails[], dddPrice: number, epxPrice: number) => {
    console.log(`loading epslp data for ${user}`);
    const web3 = new Web3(CONFIG.rpc);

    const multicall = new Multicall({
        multicallAddress: CONFIG.multicall,
        provider: CONFIG.rpc,
    });

    const dddLpDepositor = new web3.eth.Contract(DDDLpDepositorAbi as AbiItem[], CONFIG.DDDLpDepositor)

    const lpAddresses = lps.map((lp) => lp.token);

    const userDepositsCalls = [];

    for (let i = 0; i < lpAddresses.length; i++) {
        const token = new web3.eth.Contract(EPSLpTokenAbi as AbiItem[], lpAddresses[i]);
        userDepositsCalls.push(token.methods.balanceOf(user));
        userDepositsCalls.push(token.methods.allowance(user, CONFIG.DDDLpDepositor));
        userDepositsCalls.push(dddLpDepositor.methods.userBalances(user, lpAddresses[i]));
        userDepositsCalls.push(dddLpDepositor.methods.claimableExtraRewards(user, lpAddresses[i]));
    }

    const userDepositsData = await multicall.aggregate(userDepositsCalls);
    const claimables = await dddLpDepositor.methods.claimable(user, lpAddresses).call();

    let userDeposits: UserLpDeposts = {}; 

    let totalDeposits = 0;
    let totalEarnings = 0;
    let totalBaseEarnings = 0;

    let j = 0;
    for (let i = 0; i < lpAddresses.length; i++) {
        const balance = new BigNumber(userDepositsData[j]);
        const allowance = new BigNumber(userDepositsData[j+1]);
        const deposit = new BigNumber(userDepositsData[j+2]);

        const depositUSD = deposit.dividedBy(1e18).multipliedBy(lps[i].price ? lps[i].price : 0).toNumber();

        const myClaimable = claimables[i];

        const epxClaimable = new BigNumber(myClaimable[0]).dividedBy(1e18).toNumber();
        const dddClaimable = new BigNumber(myClaimable[1]).dividedBy(1e18).toNumber();

        const epxClaimableUSD = epxClaimable * epxPrice;
        const dddClaimableUSD = dddClaimable * dddPrice;

        const extraRewards = userDepositsData[j+3];

        let totalExtraClaimable = 0;

        const extraClaimable = [];

        for (let x = 0; x < lps[i].extraRewardsLength; x++) {
            const extraRewardInfo = lps[i].extraRewards[x];
            if (extraRewards[x] !== undefined && extraRewards[x].length > 0 && extraRewardInfo.address === extraRewards[x][0]) {
                const amount = new BigNumber(extraRewards[x][1]).dividedBy(10 ** extraRewardInfo.decimals).toNumber()
                const amountUSD = amount * extraRewardInfo.price;
                extraClaimable.push({
                    amount: amount,
                    amountUSD: amountUSD,
                    token: extraRewardInfo.address,
                    symbol: extraRewardInfo.symbol,
                    name: extraRewardInfo.name,
                    decimals: extraRewardInfo.decimals,
                    price: extraRewardInfo.price,
                });
                totalExtraClaimable += amountUSD;
            }
        }

        const userDeposit: UserLpDeposit = {
            symbol: lps[i].symbol,
            token: lps[i].token,
            balance: balance,
            allowance: allowance,
            deposit: deposit,
            depositUSD: depositUSD,
            epxClaimable: epxClaimable,
            epxClaimableUSD: epxClaimableUSD,
            dddClaimable: dddClaimable,
            dddClaimableUSD: dddClaimableUSD,
            totalBaseClaimableUSD: epxClaimableUSD + dddClaimableUSD,
            totalClaimableUSD: epxClaimableUSD + dddClaimableUSD + totalExtraClaimable,
            totalExtraClaimableUSD: totalExtraClaimable,
            extraClaimable: extraClaimable
        };

        userDeposits[lpAddresses[i]] = userDeposit;

        totalDeposits += depositUSD;
        totalEarnings += epxClaimableUSD + dddClaimableUSD + totalExtraClaimable;
        totalBaseEarnings += epxClaimableUSD + dddClaimableUSD;

        j += 4;
    }
    return { userDeposits, totalDeposits, totalEarnings, totalBaseEarnings }
}

function StakeEpsLp() {
    const { lps, dddPrice, epxPrice } = useApiDataContext();

    const { account } = useOnboardProvider();

    const [userDeposits, setUserDeposits] = useState<UserLpDeposts>({'0x00': {
            symbol: '',
            token: '',
            balance: new BigNumber(0),
            allowance: new BigNumber(0),
            deposit: new BigNumber(0),
            depositUSD: 0,
            epxClaimable: 0,
            epxClaimableUSD: 0,
            dddClaimable: 0,
            dddClaimableUSD: 0,
            totalBaseClaimableUSD: 0,
            totalExtraClaimableUSD: 0,
            totalClaimableUSD: 0,
            extraClaimable: [],
        }
    });
    const [totalDepositsUSD, setTotalDepositsUSD] = useState(0);
    const [totalEarningsUSD, setTotalEarningsUSD] = useState(0);
    const [totalBaseEarningsUSD, setTotalBaseEarningsUSD] = useState(0);
    const [sortedLpDeposits, setSortedLpDeposits] = useState<LpDetails[]>([])

    const [sortByBalance, setSortByBalance] = useState<1 | 2 | 3>(1);
    const [sortByDeposits, setSortByDeposits] = useState<1 | 2 | 3>(2);
    const [sortByAPR, setSortByAPR] = useState<1 | 2 | 3>(1);
    const [sortByTVL, setSortByTVL] = useState<1 | 2 | 3>(1);

    const handleSetSortByBalance = () => {
        switch (sortByBalance) {
            case 1:
                setSortByDeposits(1);
                setSortByBalance(2);
                setByBalance(2);
                break;
            case 2:
                setSortByDeposits(1);
                setSortByBalance(3);
                setByBalance(3);
                break;
            default:
                setSortByBalance(1);
                setDefault(sortByAPR, sortByTVL, 1, 1);
        }
    }

    const handleSetSortByDeposits = () => {
        switch (sortByDeposits) {
            case 1:
                setSortByDeposits(2);
                setByDeposits(2)
                break;
            case 2:
                setSortByDeposits(3);
                setByDeposits(3)
                break;
            default:
                setSortByDeposits(1);
                setDefault(sortByAPR, sortByTVL, 1, 1);
        }
    }

    const handleSetSortByAPR = () => {
        switch (sortByAPR) {
            case 1:
                setSortByAPR(2);
                setSortByTVL(1);
                setByAPR(2)
                break;
            case 2:
                setSortByAPR(3);
                setSortByTVL(1);
                setByAPR(3)
                break;
            default:
                setSortByAPR(1);
                setSortByTVL(1);
                setDefault(1, 1, sortByDeposits, sortByBalance);
        }
    }

    const handleSetSortByTVL = () => {
        switch (sortByTVL) {
            case 1:
                setSortByAPR(1);
                setSortByTVL(2);
                setByTVL(2);
                break;
            case 2:
                setSortByAPR(1);
                setSortByTVL(3);
                setByTVL(3);
                break;
            default:
                setSortByAPR(1);
                setSortByTVL(1);
                setDefault(1, 1, sortByDeposits, sortByBalance);
        }
    }

    const setDefault = (aprState: number, tvlState: number, depositState: number, balanceState: number) => {
        if (lps !== undefined) {
            let _lps: LpDetails[] = [];
            _lps = _lps.concat(lps);
            const sortedLpDeposits = _lps
                .sort((a, b) =>
                    aprState === 2 ? (b.realDddAPR + b.realEpxAPR) - (a.realDddAPR + a.realEpxAPR) :
                    aprState === 3 ? (a.realDddAPR + a.realEpxAPR) - (b.realDddAPR + b.realEpxAPR) :
                    0
                )
                .sort((a, b) =>
                    tvlState === 2 ? b.dddTvlUSD - a.dddTvlUSD :
                    tvlState === 3 ? a.dddTvlUSD - b.dddTvlUSD :
                    0
                )
                .sort((a, b) =>
                    userDeposits[b.token] && userDeposits[a.token] ?
                        depositState === 2 ? (userDeposits[b.token].depositUSD - userDeposits[a.token].depositUSD) :
                        depositState === 3 ? (userDeposits[a.token].depositUSD - userDeposits[b.token].depositUSD) :
                        0
                    : 0
                )
                .sort((a, b) =>
                    userDeposits[b.token] && userDeposits[a.token] ?
                        balanceState === 2 ? (userDeposits[b.token].balance.toNumber() - userDeposits[a.token].balance.toNumber()) :
                        balanceState === 3 ? (userDeposits[a.token].balance.toNumber() - userDeposits[b.token].balance.toNumber()) :
                        0
                    : 0
                );
            setSortedLpDeposits(sortedLpDeposits);
        }
    }

    const setByAPR = (state: number) => {
        if (lps !== undefined) {
            let _lps: LpDetails[] = [];
            _lps = _lps.concat(lps);
            const sortedLpDeposits = _lps
                .sort((a, b) =>
                    state === 2 ? (b.realDddAPR + b.realEpxAPR) - (a.realDddAPR + a.realEpxAPR) :
                    state === 3 ? (a.realDddAPR + a.realEpxAPR) - (b.realDddAPR + b.realEpxAPR) :
                    0
                )
                .sort((a, b) =>
                    userDeposits[b.token] && userDeposits[a.token] ?
                        sortByDeposits === 2 ? (userDeposits[b.token].depositUSD - userDeposits[a.token].depositUSD) :
                        sortByDeposits === 3 ? (userDeposits[a.token].depositUSD - userDeposits[b.token].depositUSD) :
                        0
                    : 0
                );
            setSortedLpDeposits(sortedLpDeposits);
        }
    }

    const setByTVL = (state: number) => {
        if (lps !== undefined) {
            let _lps: LpDetails[] = [];
            _lps = _lps.concat(lps);
            const sortedLpDeposits = _lps
                .sort((a, b) =>
                    state === 2 ? b.dddTvlUSD - a.dddTvlUSD :
                    state === 3 ? a.dddTvlUSD - b.dddTvlUSD :
                    0
                )
                .sort((a, b) =>
                    userDeposits[b.token] && userDeposits[a.token] ?
                        sortByDeposits === 2 ? (userDeposits[b.token].depositUSD - userDeposits[a.token].depositUSD) :
                        sortByDeposits === 3 ? (userDeposits[a.token].depositUSD - userDeposits[b.token].depositUSD) :
                        0
                    : 0
                );
            setSortedLpDeposits(sortedLpDeposits);
        }
    }

    const setByDeposits = (state: number) => {
        if (lps !== undefined) {
            let _lps: LpDetails[] = [];
            _lps = _lps.concat(lps);
            const sortedLpDeposits = _lps
                .sort((a, b) =>
                    sortByAPR === 2 ? (b.realDddAPR + b.realEpxAPR) - (a.realDddAPR + a.realEpxAPR) :
                    sortByAPR === 3 ? (a.realDddAPR + a.realEpxAPR) - (b.realDddAPR + b.realEpxAPR) :
                    0
                )
                .sort((a, b) =>
                    sortByTVL === 2 ? b.dddTvlUSD - a.dddTvlUSD :
                    sortByTVL === 3 ? a.dddTvlUSD - b.dddTvlUSD :
                    0
                )
                .sort((a, b) =>
                    userDeposits[b.token] && userDeposits[a.token] ?
                        state === 2 ? (userDeposits[b.token].depositUSD - userDeposits[a.token].depositUSD) :
                        state === 3 ? (userDeposits[a.token].depositUSD - userDeposits[b.token].depositUSD) :
                        0
                    : 0
                );
            setSortedLpDeposits(sortedLpDeposits);
        }
    }

    const setByBalance = (state: number) => {
        if (lps !== undefined) {
            let _lps: LpDetails[] = [];
            _lps = _lps.concat(lps);
            const sortedLpDeposits = _lps
                .sort((a, b) =>
                    sortByAPR === 2 ? (b.realDddAPR + b.realEpxAPR) - (a.realDddAPR + a.realEpxAPR) :
                    sortByAPR === 3 ? (a.realDddAPR + a.realEpxAPR) - (b.realDddAPR + b.realEpxAPR) :
                    0
                )
                .sort((a, b) =>
                    sortByTVL === 2 ? b.dddTvlUSD - a.dddTvlUSD :
                    sortByTVL === 3 ? a.dddTvlUSD - b.dddTvlUSD :
                    0
                )
                .sort((a, b) =>
                    userDeposits[b.token] && userDeposits[a.token] ?
                        state === 2 ? (userDeposits[b.token].balance.toNumber() - userDeposits[a.token].balance.toNumber()) :
                        state === 3 ? (userDeposits[a.token].balance.toNumber() - userDeposits[b.token].balance.toNumber()) :
                        0
                    : 0
                );
            setSortedLpDeposits(sortedLpDeposits);
        }
    }

    useEffect(() => {
        if (lps !== undefined) {
            let _lps: LpDetails[] = [];
            _lps = _lps.concat(lps);
            const sortedLpDeposits = _lps
                .sort((a, b) =>
                    sortByAPR === 2 ? (b.realDddAPR + b.realEpxAPR) - (a.realDddAPR + a.realEpxAPR) :
                    sortByAPR === 3 ? (a.realDddAPR + a.realEpxAPR) - (b.realDddAPR + b.realEpxAPR) :
                    0
                )
                .sort((a, b) =>
                    sortByTVL === 2 ? b.dddTvlUSD - a.dddTvlUSD :
                    sortByTVL === 3 ? a.dddTvlUSD - b.dddTvlUSD :
                    0
                )
                .sort((a, b) =>
                    userDeposits[b.token] && userDeposits[a.token] ?
                        sortByDeposits === 2 ? (userDeposits[b.token].depositUSD - userDeposits[a.token].depositUSD) :
                        sortByDeposits === 3 ? (userDeposits[a.token].depositUSD - userDeposits[b.token].depositUSD) :
                        0
                    : 0
                )
                .sort((a, b) =>
                    userDeposits[b.token] && userDeposits[a.token] ?
                        sortByBalance === 2 ? (userDeposits[b.token].balance.toNumber() - userDeposits[a.token].balance.toNumber()) :
                        sortByBalance === 3 ? (userDeposits[a.token].balance.toNumber() - userDeposits[b.token].balance.toNumber()) :
                        0
                    : 0
                );
            setSortedLpDeposits(sortedLpDeposits);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lps, userDeposits]);

    const doAction = async (acc: string | undefined | null, _lps: LpDetails[] | undefined, dddPrice: number, epxPrice: number) => {
        if (acc !== undefined && acc != null && _lps !== undefined) {
            const { userDeposits, totalDeposits, totalEarnings, totalBaseEarnings } = await loadData(acc, _lps, dddPrice, epxPrice);
            setUserDeposits(userDeposits);
            setTotalDepositsUSD(totalDeposits);
            setTotalEarningsUSD(totalEarnings);
            setTotalBaseEarningsUSD(totalBaseEarnings);
        }
    }

    useEffect(() => {
        doAction(account, lps, dddPrice, epxPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    useEffect(() => {
        const interval = setInterval(() => doAction(account, lps, dddPrice, epxPrice), 5000);
        return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    const [withBonus, setWithBonus] = useState(false);

    const handleChange = () => {
        setWithBonus(!withBonus);
    };

    const stats1 = [
        {
            title: 'Your Total Deposits',
            value: totalDepositsUSD,
            compact: true,
            width: '50%',
            symbolFront: '$',
            subSymbolBack: 'USD'
        },
        {
            title: 'Your Total Claimable',
            value: totalEarningsUSD,
            compact: true,
            width: '50%',
            symbolFront: '$',
            subSymbolBack: 'USD'
        },
    ];

    return (<Card>
        <CardContent>
            <Box sx={{ py: '24px', px: '24px', background: 'linear-gradient(90deg, #966DF3 0%, #0CB9FC 100%);' }}>
                <CardTitle title={'Stake Ellipsis LP tokens'} />
            </Box>
            <Box sx={{ pb: '24px', px: '24px', mt: 2 }}>
                <Grid container columnSpacing={2} rowSpacing={1}>
                    <Grid item xs={12} lg={4}>
                        <StatsTable stats={stats1} />
                    </Grid>
                    {totalDepositsUSD > 0 ? <Grid item xs={12} lg={8}>
                        <Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: 400, lineHeight: '140%', color: '#1A2037' }}>
                            At the time of claim, LPs may optionally convert their earned EPX into bonded dEPX to get a 3x bonus on their DDD.
                        </Typography>
                    </Grid> : <></>}
                </Grid>
            </Box>
            {totalDepositsUSD === 0 ? <ZeroState
                title={<Typography sx={{ textAlign: 'center', fontSize: '28px', fontWeight: 400, lineHeight: '38px', color: '#ffffff' }}>
                    Stake Ellipsis LP tokens <span style={{ fontWeight: 700 }}>to earn DDD</span>
                </Typography>}
                subtitle={
                    <Typography sx={{ textAlign: 'center', fontSize: '18px', fontWeight: 400, lineHeight: '25.2px', color: '#ffffff' }}>
                        Stake your Ellipsis LP tokens on Dot Dot to earn boosted EPX rewards<br /> without having to lock EPX, as well as additional DDD rewards.&nbsp;
                        <Link
                            target="__blank"
                            rel="noreferrer"
                            href="https://docs.dotdot.finance/guides/staking-ellipsis-lp-tokens"
                        >
                                Learn more.
                        </Link>
                    </Typography>
                }
                background='stakelp'
            /> : <></>}
            <Box sx={{ pb: '16px', px: '16px', mt: 2, mb: 1.3, boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15);' }}>
                <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item xs={12} lg={6}>
                        <Typography sx={{ textAlign: 'left', color: '#000000', fontWeight: 700, fontSize: '20px', lineHeight: '25px' }}>
                            Ellipsis Pools
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: ['row', 'row'],
                            alignItems: 'center',
                        }}>
                            <ClaimAllRewards
                                earnings={Object.values(userDeposits)}
                                totalBaseEarningsUSD={totalBaseEarningsUSD}
                                withBonus={withBonus}
                            />
                            <Button
                                sx={{
                                    ml: 0.8,
                                    width: '100%'
                                }}
                                variant="transparent"
                                onClick={handleChange}
                            >
                                <Checkbox
                                    disableRipple
                                    sx={{ display: 'flex', alignItems: 'center', color: '#976CF3', p: 'unset' }}
                                    checked={withBonus}
                                    onChange={handleChange}
                                />
                                <Typography sx={{ fontSize: '16px', fontWeight: 500, width: 'max-content', mr: '2px' }}>
                                    Claim with bonus
                                </Typography>
                                <Tooltip
                                    title={<>
                                        At the time of claim, you may choose to convert your earned EPX into dEPX to get a 3x bonus on your DDD.&nbsp;
                                        This dEPX is staked on the Dot Dot platform and starts earning additional rewards or it can be withdrawn and sold/swapped via a secondary market such as&nbsp;
                                        <Link
                                            target="__blank"
                                            rel="noreferrer"
                                            href="https://ellipsis.finance/pool/0x45859D71D4caFb93694eD43a5ecE05776Fc2465d"
                                        >
                                                Ellipsis Finance.
                                        </Link>
                                    </>}
                                    placement="top"
                                    arrow
                                >
                                    <InfoIcon fontSize='inherit' sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                                </Tooltip>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Grid container>
                <Grid item xs={12} lg={12}>
                    <LpDepositsList
                        lpDeposits={sortedLpDeposits}
                        userLpDeposits={userDeposits}
                        withBonus={withBonus}
                        tvlState={sortByTVL}
                        handleSetSortByTVL={handleSetSortByTVL}
                        aprState={sortByAPR}
                        handleSetSortByAPR={handleSetSortByAPR}
                        depositState={sortByDeposits}
                        handleSetSortByDeposits={handleSetSortByDeposits}
                        balanceState={sortByBalance}
                        handleSetSortByBalance={handleSetSortByBalance}
                    />
                </Grid>
            </Grid>
        </CardContent>
    </Card>);
}

export default StakeEpsLp;
