import React, { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { Box, Button, Grid } from '@mui/material';
import { useOnboardProvider } from '../../../../../providers/onboard-provider';
import { CONFIG } from '../../../../../global';
import InputNumber from '../../../../../components/Inputs/InputNumber';
import CompleteButton from '../../../../../components/CompleteButton';
import LoadingButton from '../../../../../components/LoadingButton';
import DisplayValue from '../../../../../components/DisplayValue';
import { TxState, UserPool2Deposit } from '../../../../../web3/types';
import { useBlockNumberDataContext } from '../../../../../providers/blocknumber-provider';
import Info from '../../../../../components/Info';
import Balance from '../../../../../components/Balance';
import TxModal from '../../../../../components/TxModal';
import { withdraw } from '../../../../../web3/Pool2/withdraw';

type Props = {
    stakedAmount: BigNumber,
    lpDeposits: Array<UserPool2Deposit> | undefined;
}

const calculateWithdrawFeeOnAmount = (
    liquidityMiningStake: BigNumber,
    userLpDeposts: Array<UserPool2Deposit>,
    blockTimestamp: number
): BigNumber => {
    let feeAmount = new BigNumber(0);

    let remaining = liquidityMiningStake;

    for (let i = 0; i < userLpDeposts.length; i++) {
        const deposit = userLpDeposts[i];

        let weeklyAmount = deposit.amount;
        if (weeklyAmount.isGreaterThan(remaining)) {
            weeklyAmount = remaining
        }

        const weeksSinceDeposit = Math.floor((blockTimestamp - deposit.timestamp) / 604800);
        if (weeksSinceDeposit < 8) {
            const feeMultiplier = 8 - weeksSinceDeposit;
            feeAmount = feeAmount.plus(weeklyAmount.multipliedBy(feeMultiplier).dividedBy(100));
        }
        remaining = remaining.minus(weeklyAmount);
        if (remaining.isZero()) {
            break;
        }
    }
    return feeAmount;
}

export default function LpWithdraw({ stakedAmount, lpDeposits }: Props) {
    const { blockTimestamp } = useBlockNumberDataContext();
    const { signer, account } = useOnboardProvider();

    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const [amount, setAmount] = useState<BigNumber>();
    const [inputValue, setInputValue] = useState<number | string>('');

    const [withdrawalFee, setWithdrawalFee] = useState<BigNumber>(new BigNumber(0));

    useEffect(() => {
        if (amount !== undefined && lpDeposits !== undefined) {
            const withdrawalFee = calculateWithdrawFeeOnAmount(
                amount,
                lpDeposits,
                blockTimestamp
            );
            setWithdrawalFee(withdrawalFee);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount, inputValue]);

    const handleSubmit = async () => {
        if (signer !== undefined && account !== undefined && account !== null && amount !== undefined) {
            const txFinished = () => {
                setInputValue('');
                setAmount(undefined);
                setTxState('finished');
            }
            await withdraw(
                {
                    setTxHash: setCurrentTxHash,
                    setTxState: setTxState,
                    setTxError: () => {},
                    txFinished: txFinished,
                },
                signer,
                account,
                amount,
                false
            );
        }
    }

    const setMaxAmount = (event: any) => {
        event.preventDefault();
        if (stakedAmount) {
          setInputValue(stakedAmount.toNumber() / 1e18)
          setAmount(stakedAmount);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const feeMessage = `The unstaking fee starts at 8% and decreases by 1% a week until reaching 0 after 8 weeks. The LP tokens taken as a fee are held within the contract as protocol owned liquidity forver.`;

    return (<>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1, width: '100%' }}>
                <Button disableRipple onClick={setMaxAmount} variant="text" sx={{ p: 0 }}>
                    <Balance title={'Staked amount'} tokenBalance={stakedAmount} tokenSymbol={`${CONFIG.lpSymbol} LP`} />
                </Button>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Grid container columnSpacing={0.5} rowSpacing={0.5}>
                    <Grid item xs={12} lg={3}>
                        <InputNumber
                            assetSymbol={CONFIG.lpSymbol}
                            maxAmount={stakedAmount}
                            setRealInput={setAmount}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        {txState === 'none' || txState === 'pending' || txState === 'confirm' ? <LoadingButton
                            content={'Unstake'}
                            variant={"blue"}
                            onClick={handleSubmit}
                            disabled={stakedAmount.isZero() || txState === 'pending' ? true : false || amount === undefined || amount.isZero()}
                            loading={txState === 'pending' ? true : false }
                        /> : <CompleteButton content={'LP tokens unstaked'} />}
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Info>
                            LP unstaking fee is&nbsp;
                            <DisplayValue value={withdrawalFee} decimals={1e18} precision={4} />&nbsp;
                        </Info>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <TxModal txState={txState} currentTxHash={currentTxHash} setTxState={setTxState} />
    </>);
}