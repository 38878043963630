import numeral from 'numeral';

type Props = {
    value: number;
    precision?: number;
}

export default function DisplayPreciseNumber({ value, precision = 1 }: Props) {
  return (<>
    {numeral(value).format(`0.${'0'.repeat(precision)}`)}
  </>);
}