import React, { useState } from 'react';
import BigNumber from 'bignumber.js';
import { Typography, Box, FormControl, Select, SelectChangeEvent, MenuItem, ListItemText, OutlinedInput, Button, Grid } from '@mui/material';
import { useOnboardProvider } from '../../../../../providers/onboard-provider';
import { CONFIG } from '../../../../../global';
import InputNumber from '../../../../../components/Inputs/InputNumber';
import CompleteButton from '../../../../../components/CompleteButton';
import LoadingButton from '../../../../../components/LoadingButton';
import { TxState } from '../../../../../web3/types';
import Balance from '../../../../../components/Balance';
import ApproveButton from '../../../../../components/ApproveButton';
import TxModal from '../../../../../components/TxModal';
import { lock } from '../../../../../web3/TokenLocker/lock';

type Props = {
    tokenBalance: BigNumber,
    tokenAllowance: BigNumber,
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Lock({ tokenBalance, tokenAllowance }: Props) {
    const { signer, account } = useOnboardProvider();

    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const [weeks, setWeeks] = useState<string>('16');
    const [amount, setAmount] = useState<BigNumber>();
    const [inputValue, setInputValue] = useState<number | string>('');

    const isApproved = tokenAllowance.gt(tokenBalance);

    const handleSubmit = async () => {
        if (signer !== undefined && account !== undefined && account !== null && amount !== undefined) {
            const txFinished = () => {
                setInputValue('');
                setAmount(undefined);
                setWeeks('16');
                setTxState('finished');
            }
            await lock(
                {
                    setTxHash: setCurrentTxHash,
                    setTxState: setTxState,
                    setTxError: () => {},
                    txFinished: txFinished,
                },
                signer,
                account,
                amount,
                weeks
            );
        }
    }

    const handleChange = (event: SelectChangeEvent<typeof weeks>) => {
        const {
            target: { value },
        } = event;
        setWeeks(value);
    };

    const setMaxAmount = (event: any) => {
        event.preventDefault();
        if (tokenBalance) {
          setInputValue(tokenBalance.toNumber() / 1e18)
          setAmount(tokenBalance);
        }
    }

    return (<Box sx={{
        backgroundColor: 'linear-gradient(262.93deg, #00BFFC -33.57%, #1A2037 18.4%, #1A2037 81.38%, #976CF3 122.67%);',
        background: `url(/zero/lock.svg); no-repeat`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        padding: '32px 28px'
    }}>
        <Box>
            <Typography sx={{ textAlign: 'center', fontSize: '28px', fontWeight: 400, lineHeight: '38px', color: '#ffffff' }}>
                Lock DDD to <span style={{ fontWeight: 700 }}>earn rewards<br />& control voting power</span>
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', '& > button > h4': { color: '#ffffff', transition: 'all .2s ease', '&:hover': { color: '#8477F4' } } }}>
            <Button disableRipple onClick={setMaxAmount} variant="text">
                <Balance title='Wallet balance' tokenBalance={tokenBalance} tokenSymbol={CONFIG.symbol} />
            </Button>
        </Box>
        <Box sx={{ width: '100%' }}>
            <Grid container columnSpacing={0.5} rowSpacing={0.5}>
                <Grid item xs={12} lg={3}>
                    <InputNumber
                        assetSymbol={CONFIG.symbol}
                        maxAmount={tokenBalance}
                        setRealInput={setAmount}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <FormControl sx={{ width: '100%', height: '100%' }}>
                        <Select
                            sx={{ background: '#ffffff' }}
                            displayEmpty
                            value={weeks}
                            onChange={handleChange}
                            renderValue={(selected) => {
                                return <Typography sx={{
                                    fontSize: '18px',
                                    fontFamily: 'Outfit',
                                    fontWeight: 500,
                                    textAlign: 'left',
                                    color: '#000000'
                                }}>
                                    {selected} {selected === '1' ? `week`: `weeks`}
                                </Typography>;
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ 'aria-label': 'Without label'}}
                            input={<OutlinedInput />}
                        >
                            {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16].map((i) => (
                                <MenuItem key={i.toString()} value={i.toString()}>
                                    <ListItemText primary={`${i.toString()} ${i === 1 ? `week`: `weeks`}`} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <ApproveButton isApproved={isApproved} token={CONFIG.Token} contract={CONFIG.DDDLocker} />
                </Grid>
                <Grid item xs={12} lg={3}>
                    {txState === 'none' || txState === 'pending' || txState === 'confirm' ? <LoadingButton
                        content={'Lock'}
                        variant={isApproved ? "blue" : "white"}
                        onClick={handleSubmit}
                        disabled={!isApproved || txState === 'pending' || txState === 'confirm' ? true : false || amount === undefined || amount.isZero()}
                        loading={txState === 'pending' || txState === 'confirm' ? true : false }
                    /> : <CompleteButton content={'Locked'} />}
                </Grid>
            </Grid>
        </Box>
        <TxModal txState={txState} currentTxHash={currentTxHash} setTxState={setTxState} />
    </Box>);
}