import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import DDDIncentivesDistributorAbi from '../../abi/DDDIncentivesDistributor.json';
import { CONFIG } from '../../global';
import { TxActions } from '../types';
import { calcGas, toHex } from '../utils';

export async function depositIncentive(
    actions: TxActions,
    web3: Web3,
    account: string,
    lpToken: string,
    incentive: string,
    amount: BigNumber,
): Promise<any> {
    actions.setTxState('confirm');
    const c = new web3.eth.Contract(DDDIncentivesDistributorAbi as AbiItem[], CONFIG.DDDIncentivesDistributor);
    const est = await c.methods.depositIncentive(lpToken, incentive, toHex(amount)).estimateGas({'from': account});
    c.methods.depositIncentive(lpToken, incentive, toHex(amount)).send({'from': account, gas: calcGas(est), maxFeePerGas: 3 * 1e9, maxPriorityFeePerGas: 3 * 1e9 })
    .once('transactionHash', (hash: any) => {
        actions.setTxState('pending');
        actions.setTxHash(hash);
    }).once('error', (error: any) => {
        console.error(error)
        actions.setTxError(error);
        actions.setTxState('none');
    }).once('confirmation', (conf: any, receipt: any) => {
    }).then((receipt: any) => {
        actions.txFinished();
    }).finally(() => {
        actions.txFinished();
    });
}