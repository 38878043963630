import React, { useState } from 'react';
import BigNumber from 'bignumber.js';
import { Typography, Box, FormControl, Select, SelectChangeEvent, MenuItem, ListItemText, OutlinedInput, Button } from '@mui/material';
import { useOnboardProvider } from '../../../../../providers/onboard-provider';
//import { extendLock } from '../../../web3/actions/TokenLocker/extendLock';
import { UserLock } from '../../../../../web3/types';
import { CONFIG } from '../../../../../global';
import { useBlockNumberDataContext } from '../../../../../providers/blocknumber-provider';
import InputNumber from '../../../../../components/Inputs/InputNumber';
import CompleteButton from '../../../../../components/CompleteButton';
import LoadingButton from '../../../../../components/LoadingButton';
import { TxState } from '../../../../../web3/types';
import Balance from '../../../../../components/Balance';
import { extendLock } from '../../../../../web3/TokenLocker/extendLock';
import TxContent from '../../../../../components/TxContent';

type Props = {
    lock: UserLock;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ExtendLockToken({ lock }: Props) {
    const { signer, account } = useOnboardProvider();

    const { blockTimestamp } = useBlockNumberDataContext();

    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const calcLockLength = (timestamp: number, expiry: number) => {
       let diff = expiry - timestamp;

       if (diff < 0) {
           diff = 0;
       }

       return Math.ceil(diff / 86400 / 7);
    }

    const currentWeeksLeft = calcLockLength(blockTimestamp, lock.expiry)

    const weeksToSelect = [];

    for (let i = currentWeeksLeft+1; i < 17; i++) {
        weeksToSelect.push(i);
    }

    const [weeks, setWeeks] = useState<string>(weeksToSelect.length > 0 ? weeksToSelect[weeksToSelect.length-1].toString() : '16');
    const [amount, setAmount] = useState<BigNumber>();
    const [inputValue, setInputValue] = useState<number | string>('');

    const handleSubmit = async () => {
        if (signer !== undefined && account !== undefined && account !== null && amount !== undefined && weeks !== undefined) {
            const txFinished = () => {
                setInputValue('');
                setAmount(undefined);
                setTxState('finished');
            }
            await extendLock(
                {
                    setTxHash: setCurrentTxHash,
                    setTxState: setTxState,
                    setTxError: () => {},
                    txFinished: txFinished,
                },
                signer,
                account,
                amount,
                lock.weeksLeft.toString(),
                weeks
            );
        }
    }

    const handleChange = (event: SelectChangeEvent<typeof weeks>) => {
        const {
            target: { value },
        } = event;
        setWeeks(value);
    };

    const setMaxAmount = (event: any) => {
        event.preventDefault();
        if (lock.amount) {
          setInputValue(lock.amount.toNumber() / 1e18)
          setAmount(lock.amount);
        }
    }

    return (<Box sx={{ my: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', px: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, mb: 0.5, width: '100%' }}>
            <Typography variant="h4" sx={{ pl: 1 }}>
                Current weeks left:&nbsp;{currentWeeksLeft}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1, width: '100%' }}>
            <FormControl sx={{ width: '100%', height: '100%' }}>
                <Select
                    sx={{ background: '#ffffff' }}
                    displayEmpty
                    value={weeks}
                    onChange={handleChange}
                    renderValue={(selected) => {
                        return <Typography sx={{
                            fontSize: '18px',
                            fontFamily: 'Outfit',
                            fontWeight: 500,
                            textAlign: 'left',
                            color: '#000000'
                        }}>
                            {selected} {selected === '1' ? `week`: `weeks`}
                        </Typography>;
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label'}}
                    input={<OutlinedInput />}
                >
                    {weeksToSelect.map((i) => (
                        <MenuItem key={i.toString()} value={i.toString()}>
                            <ListItemText primary={`${i.toString()} ${i === 1 ? `week`: `weeks`}`} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, width: '100%' }}>
            <Button disableRipple onClick={setMaxAmount} variant="text">
                <Balance title='Current lock amount' tokenBalance={lock.amount} tokenSymbol={CONFIG.symbol} />
            </Button>
        </Box>
        <Box sx={{ width: '100%', mb: txState === 'pending' || txState === 'confirm' ? '8px' : 'unset' }}>
            <InputNumber
                assetSymbol={CONFIG.symbol}
                maxAmount={lock.amount}
                setRealInput={setAmount}
                inputValue={inputValue}
                setInputValue={setInputValue}
            />
            <Box sx={{ mt: 3, width: '100%', display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
                {txState === 'none' || txState === 'pending' || txState === 'confirm' ? <LoadingButton
                    content={'Extend Lock'}
                    variant={"blue"}
                    onClick={handleSubmit}
                    disabled={txState === 'pending' || txState === 'confirm' ? true : false || amount === undefined || amount.isZero()}
                    loading={txState === 'pending' || txState === 'confirm' ? true : false }
                /> : <CompleteButton content={'Extended Lock'} />}
            </Box>
        </Box>
        <TxContent txState={txState} currentTxHash={currentTxHash} />
    </Box>);
}
