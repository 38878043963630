
import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, Link, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import Multicall from '@dopex-io/web3-multicall';
import { AbiItem } from 'web3-utils';
import 'moment-timezone';
import LpDepositsList from './components/LpDepositsList';
import TabsPanel from '../../../components/TabsPanel';
import LpDeposit from './components/LpDeposit';
import LpWithdraw from './components/LpWithdraw';
import CardTitle from '../../../components/CardTitle';
import StatsTable from '../../../components/StatsTable';
import { useApiDataContext } from '../../../providers/api-provider';
import { CONFIG } from '../../../global';
import Pool2Abi from '../../../abi/Pool2.json';
import UniswapV2PairAbi from '../../../abi/UniswapV2Pair.json';
import { useOnboardProvider } from '../../../providers/onboard-provider';
import { UserPool2Deposit } from '../../../web3/types';
import ContractList from '../../../components/ContractList';
import { useBlockNumberDataContext } from '../../../providers/blocknumber-provider';
import ClaimableRewards from './components/ClaimableRewards';
import TopStatsTable from '../../../components/TopStatsTable';
import ZeroState from '../../../components/ZeroState';

export const calcFeePercentage = (
    timestamp: number,
    blockTimestamp: number
) => {
    const weeksSinceDeposit = Math.floor((blockTimestamp - timestamp) / 604800);
    const fee = 8 - weeksSinceDeposit;
    if (fee < 0) {
        return new BigNumber(0);
    }
    return new BigNumber(fee);
}

export const calcDecayDate = (timestamp: number) => {
    return timestamp + (86400 * 7 * 9);
}

export const calcFees = (
    amount: BigNumber,
    timestamp: number,
    blockTimestamp: number
): BigNumber => {
    return amount.multipliedBy(calcFeePercentage(timestamp, blockTimestamp).dividedBy(100));
};

const loadData = async (user: string, lpTokenPrice: number, blockTimestamp: number) => {
    console.log(`loading pool2 data for ${user}`);
    const web3 = new Web3(CONFIG.rpc);

    const multicall = new Multicall({
        multicallAddress: CONFIG.multicall,
        provider: CONFIG.rpc,
    });

    const pool2 = new web3.eth.Contract(Pool2Abi as AbiItem[], CONFIG.Pool2)
    const token = new web3.eth.Contract(UniswapV2PairAbi as AbiItem[], CONFIG.LpToken);

    const userCalls = [
        pool2.methods.balanceOf(user),
        pool2.methods.userDeposits(user),
        pool2.methods.claimable(user),
        token.methods.balanceOf(user),
        token.methods.allowance(user, CONFIG.Pool2)
    ];

    const [totalDeposits, userDeposits, claimable, balance, allowance] = await multicall.aggregate(userCalls);

    let deposits: UserPool2Deposit[] = [];
    for (let i = 0; i < userDeposits[0].length; i++) {
        const amount = new BigNumber(userDeposits[0][i].amount);
        const amountUSD = amount.multipliedBy(lpTokenPrice);
        const timestamp = new BigNumber(userDeposits[0][i].timestamp).toNumber();

        deposits.push({
            amount: amount,
            amountUSD: amountUSD,
            timestamp: timestamp,
            withdrawalFee: calcFees(amount, timestamp, blockTimestamp).dividedBy(1e18).toNumber(),
            withdrawalFeePercent: calcFeePercentage(timestamp, blockTimestamp).toNumber(),
            decayDate: calcDecayDate(timestamp)
        });
    }

    return {
        totalDeposits: new BigNumber(totalDeposits),
        claimable: new BigNumber(claimable).dividedBy(1e18).toNumber(),
        balance: new BigNumber(balance),
        allowance: new BigNumber(allowance),
        userLpDeposits: deposits,
    };
}

function StakePool2() {
    const { pool2, dddPrice } = useApiDataContext();
    const { blockTimestamp } = useBlockNumberDataContext();

    const { account } = useOnboardProvider();

    const [totalDeposits, setTotalDeposits] = useState<BigNumber>(new BigNumber(0));
    const [claimable, setClaimable] = useState<number>(0);
    const [balance, setBalance] = useState<BigNumber>(new BigNumber(0));
    const [allowance, setAllowance] = useState<BigNumber>(new BigNumber(0));
    const [userLpDeposits, setUserLpDeposits] = useState<UserPool2Deposit[] | undefined>();

    const doAction = async (acc: string | undefined | null, lpPrice: number, timestamp: number) => {
        if (acc !== undefined && acc != null) {
            const { totalDeposits, claimable, balance, allowance, userLpDeposits } = await loadData(acc, lpPrice, timestamp);
            setUserLpDeposits(userLpDeposits);
            setTotalDeposits(totalDeposits);
            setClaimable(claimable);
            setBalance(balance);
            setAllowance(allowance);
        }
    }

    useEffect(() => {
        doAction(account, pool2.lpTokenPrice, blockTimestamp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    useEffect(() => {
        const interval = setInterval(() => doAction(account, pool2.lpTokenPrice, blockTimestamp), 5000);
        return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    const share = (((totalDeposits.toNumber() / 1e18)) / pool2.totalLpStaked);

    const stats1 = [
        {
            title: 'Your staked DDD/BNB LP',
            value: (totalDeposits.toNumber() / 1e18) * pool2.lpTokenPrice,
            compact: true,
            width: '70%',
            symbolFront: '$',
            subValue: (totalDeposits.toNumber() / 1e18),
            subSymbolBack: ' DDD/BNB LP',
            subCompact: true,
        },
        {
            title: 'Claimable',
            value: claimable * dddPrice,
            compact: true,
            width: '60%',
            symbolFront: '$',
            subSymbolBack: 'USD',
        },
        {
            title: 'Share',
            value: share * 100,
            compact: true,
            width: '50%',
            symbolBack: '%'
        },
    ];

    const stats2 = [
        {
            title: 'TVL',
            value: pool2.totalLpStakedUSD,
            compact: true,
            width: '50%',
            symbolFront: '$',
            //subValue: pool2.reserves0,
            //subSymbolBack: ' DDD',
            //subCompact: true,
            //subValueTwo: pool2.reserves1,
            //subSymbolBackTwo: ' BNB',
            //subCompactTwo: true,
        },
        {
            title: 'vAPR',
            value: pool2.apr,
            compact: false,
            width: '50%',
            symbolBack: '%'
        },
        {
            title: 'Protocol Owned Liquidity',
            value: pool2.polPercentOfSupply,
            compact: true,
            width: '50%',
            symbolBack: '%'
        },
    ];

    const tabsWithRewards = [
        {
            paddingStr: '16px 0px',
            title: <>Rewards</>,
            icon: <></>,
            component: <ClaimableRewards earnings={[{
                lpToken: '',
                lpSymbol: '',
                feeSymbol: 'DDD',
                feeToken: CONFIG.Token,
                claimable: claimable,
                claimableUSD: claimable * dddPrice,
            }]} earningsTotalUSD={claimable * dddPrice} />
        },
        {
            title: <>Stake</>,
            icon: <></>,
            component: <LpDeposit tokenBalance={balance} tokenAllowance={allowance} lpDepositFee={pool2.depositFee} />
        },
        {
            title: <>Unstake</>,
            icon: <></>,
            component: <LpWithdraw stakedAmount={totalDeposits} lpDeposits={userLpDeposits} />
        },
        {
            title: <>Info</>,
            icon: <></>,
            component: <ContractList contracts={[
                {
                    name: 'DddLpStaker',
                    address: CONFIG.Pool2
                },
                {
                    name: 'DDD/BNB LP Token',
                    address: CONFIG.LpToken
                }
            ]} />
        },
    ];

    const tabsWithoutRewards = [
        {
            title: <>Stake</>,
            icon: <></>,
            component: <LpDeposit tokenBalance={balance} tokenAllowance={allowance} lpDepositFee={pool2.depositFee} />
        },
        {
            title: <>Unstake</>,
            icon: <></>,
            component: <LpWithdraw stakedAmount={totalDeposits} lpDeposits={userLpDeposits} />
        },
        {
            paddingStr: '16px 0px',
            title: <>Rewards</>,
            icon: <></>,
            component: <>
                {claimable > 0 ? <ClaimableRewards earnings={[{
                    lpToken: '',
                    lpSymbol: '',
                    feeSymbol: 'DDD',
                    feeToken: CONFIG.Token,
                    claimable: claimable,
                    claimableUSD: claimable * dddPrice,
                }]} earningsTotalUSD={claimable * dddPrice} />
                : <Typography variant='h3' sx={{ p: 2 }}>Stake DDD/BNB LP tokens to earn DDD.</Typography>}
            </>
        },
        {
            title: <>Info</>,
            icon: <></>,
            component: <ContractList contracts={[
                {
                    name: 'DddLpStaker',
                    address: CONFIG.Pool2
                },
                {
                    name: 'DDD/BNB LP Token',
                    address: CONFIG.LpToken
                }
            ]} />
        },
    ];

    return (<Card>
        <CardContent>
            <Box sx={{
                p: '24px 24px',
                background: 'linear-gradient(90deg, #966DF3 0%, #0CB9FC 100%);'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: ['column', 'row'],
                    justifyContent: 'space-between',
                    alignItems: ['unset', 'center']
                }}>
                    <CardTitle
                        title={'Stake Cake DDD/BNB LP tokens'}
                        subtitle={totalDeposits.toNumber() > 0 ? <>Get Cake DDD/BNB LP tokens <a target="_new" href="https://pancakeswap.finance/add/0x84c97300a190676a19d1e13115629a11f8482bd1/BNB">here</a></> : <></>}
                    />
                    <TopStatsTable stats={stats2} />
                </Box>
            </Box>
            <Box sx={{ p: '24px 24px'}}>
                <Grid item xs={12} lg={12}>
                    <Grid container columnSpacing={2} rowSpacing={0.5}>
                        <Grid item xs={12} lg={6}>
                            <StatsTable stats={stats1} />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {totalDeposits.toNumber() === 0 ? <ZeroState
                title={<Typography sx={{ textAlign: 'center', fontSize: '28px', fontWeight: 400, lineHeight: '38px', color: '#ffffff' }}>
                    Stake DDD/BNB LP tokens <span style={{ fontWeight: 700 }}>to earn DDD</span>
                </Typography>}
                subtitle={
                    <Typography sx={{ textAlign: 'center', fontSize: '18px', fontWeight: 400, lineHeight: '25.2px', color: '#ffffff' }}>
                        Get Cake DDD/BNB LP tokens&nbsp;
                        <Link
                            target="__blank"
                            rel="noreferrer"
                            href="https://pancakeswap.finance/add/0x84c97300a190676a19d1e13115629a11f8482bd1/BNB"
                            sx={{
                                color: '#976CF3',
                                textDecoration: 'underline',
                                '&:hover': {
                                    color: '#ffffff',
                                    textDecoration: 'underline',
                                }
                            }}>
                                Pancake Swap
                        </Link>
                    </Typography>
                }
                background='pancakelp'
            /> : <></>}
            <Grid container>
                <Grid item xs={12} lg={12}>
                    <LpDepositsList lpDeposits={userLpDeposits} />
                </Grid>
                <Grid item xs={12} lg={12}>
                    {claimable > 0 ? <TabsPanel tabs={tabsWithRewards} /> : <TabsPanel tabs={tabsWithoutRewards} /> }
                </Grid>
            </Grid>
        </CardContent>
    </Card>);
}

export default StakePool2;
