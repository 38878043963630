import React from 'react';
import { Typography, Backdrop, Box, Modal, Fade, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  disabled?: boolean,
  children: React.ReactNode,
  buttonTitle: string | React.ReactNode,
  modalTitle?: string,
  buttonType?: 'text' | 'outlined' | 'contained' | 'white' | 'blue' | 'connect' | undefined,
  width?: string[] | undefined,
  headerBg?: string
};

export default function ModalHandler({ buttonType = 'text', disabled = false, buttonTitle, modalTitle, width = ['350px', '600px'], headerBg = 'white', children }: Props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  }

  const title = modalTitle ? <Box
    sx={{
        justifyContent: 'center',
        background: headerBg,
        py: 2,
        px: 2,
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
    }}
  >
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <Typography sx={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center' }}>
            {modalTitle}
        </Typography>
    </Box>
    <Box sx={{ position: 'absolute', right: 0, top: 0 }}>
        <IconButton onClick={handleClose} aria-label="close" color="primary">
          <CloseIcon />
        </IconButton>
    </Box>
  </Box> : <></>;

  return (
    <>
      <Button sx={{fontSize: ['12px', '14px'] }} disabled={disabled} variant={buttonType} onClick={handleOpen}>{buttonTitle}</Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#000000',
            background: '#ffffff',
            borderRadius: '4px',
            width: width,
            boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
          }}>
            {open ? <>{title}{children}</> : <></>}
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
