import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import BondedDistributorAbi from '../../abi/BondedFeeDistributor.json';
import { CONFIG } from '../../global';
import { TxActions } from '../types';
import { calcGas } from '../utils';

export async function withdrawUnbondedTokens(
    actions: TxActions,
    web3: Web3,
    account: string,
): Promise<any> {
    actions.setTxState('confirm');
    const c = new web3.eth.Contract(BondedDistributorAbi as AbiItem[], CONFIG.BondedFeeDistributor);
    const est = await c.methods.withdrawUnbondedTokens(account).estimateGas({'from': account});
    c.methods.withdrawUnbondedTokens(account).send({'from': account, gas: calcGas(est), maxFeePerGas: 3 * 1e9, maxPriorityFeePerGas: 3 * 1e9 })
    .once('transactionHash', (hash: any) => {
        actions.setTxState('pending');
        actions.setTxHash(hash);
    }).once('error', (error: any) => {
        console.error(error)
        actions.setTxError(error);
        actions.setTxState('none');
    }).once('confirmation', (conf: any, receipt: any) => {
    }).then((receipt: any) => {
        actions.txFinished();
    }).finally(() => {
        actions.txFinished();
    });
}