import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import CardTitle from '../../../components/CardTitle';
import { useApiDataContext } from '../../../providers/api-provider';
import LpList from './components/LpList';
import TopStatsTable from '../../../components/TopStatsTable';

function Bribes() {
    const { lps, totalBribes, totalBribesApr, totalBribesPerLockedDDD, lpToWeeklyAmountUSD, lpToTokenInfo } = useApiDataContext();

    const stats = [
        {
            title: 'Total Bribes (USD)',
            value: totalBribes,
            compact: true,
            width: '100%',
            symbolFront: '$'
        },
        {
            title: 'Projected Total Bribes vAPR',
            value: totalBribesApr,
            compact: false,
            width: '100%',
            symbolBack: '%'
        },
        {
            title: '$ per locked DDD',
            value: totalBribesPerLockedDDD,
            compact: false,
            width: '100%',
            symbolFront: '$',
            precision: 5,
            preciseNumber: true
        },
    ];

    return (<Card>
        <CardContent>
            <Box sx={{
                p: '24px 24px',
                background: 'linear-gradient(90deg, #966DF3 0%, #0CB9FC 100%);'
            }}>
                <Box sx={{ display: 'flex', flexDirection: ['column', 'row'], justifyContent: 'space-between', alignItems: ['unset', 'center'] }}>
                    <CardTitle
                        title={'Bribe'}
                    />
                    <TopStatsTable stats={stats} />
                </Box>
            </Box>
            <Box sx={{ p: '24px 24px' }}>
                <Grid item xs={12} lg={12}>
                    <Grid container columnSpacing={2} rowSpacing={0.5}>
                        <Grid item xs={12} lg={12}>
                            <Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: 400, lineHeight: '140%', color: '#1A2037' }}>
                                Bribes are paid out in the following week to DDD Lockers who voted for the pool.
                            </Typography>
                            <Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: 600, lineHeight: '140%', color: '#1A2037' }}>
                                Please Note: the USD price of bribe token is fetched from CoinGecko, please ensure the token you are bribing with has the BSC token address added to the token on CoinGecko.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Grid container>
                <Grid item xs={12} lg={12}>
                    {lpToWeeklyAmountUSD ?
                        <LpList lpDeposits={lps} lpToTokenInfo={lpToTokenInfo} lpToWeeklyAmountUSD={lpToWeeklyAmountUSD} /> :
                        <></>
                    }
                </Grid>
            </Grid>
        </CardContent>
    </Card>);
}

export default Bribes;
