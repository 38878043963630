import React, { useState } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import { Box, Typography } from '@mui/material';
import { useOnboardProvider } from '../../../../../providers/onboard-provider';
import { DisplayValue2 } from '../../../../../components/DisplayValue';
import { CONFIG } from '../../../../../global';
import { useBlockNumberDataContext } from '../../../../../providers/blocknumber-provider';
import { TxState } from '../../../../../web3/types';
import LoadingButton from '../../../../../components/LoadingButton';
import CompleteButton from '../../../../../components/CompleteButton';
import TxContent from '../../../../../components/TxContent';
import { initiateExitStream } from '../../../../../web3/TokenLocker/initiateExitStream';

type Props = {
    expiredLocksBalance: number;
}

export default function InitStream({ expiredLocksBalance }: Props) {
    const { blockTimestamp } = useBlockNumberDataContext();

    const streamByDate = blockTimestamp + 86400 * 7;

    const { signer, account } = useOnboardProvider();
    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const handleInitExitStream = async () => {
        if (signer !== undefined && account !== undefined && account !== null) {
            const txFinished = () => {
                setTxState('finished');
            }
            await initiateExitStream(
                {
                    setTxHash: setCurrentTxHash,
                    setTxState: setTxState,
                    setTxError: () => {},
                    txFinished: txFinished,
                },
                signer,
                account
            );
        }
    }

    return (<Box sx={{ my: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', px: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', my: 2 }}>
            <Typography variant="h3" sx={{ textAlign: 'center', fontSize: '18px', opacity: 0.8 }}>
                You are about to initiate an exit stream on <DisplayValue2 value={expiredLocksBalance} /> {CONFIG.symbol}.
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', my: 2 }}>
            <Typography variant="h3" sx={{ textAlign: 'center', fontSize: '18px', opacity: 0.8 }}>
                It will be streamed over the next week and claimable in its entirety by&nbsp;
                <Moment unix format="dddd MMM D, YYYY TZ" tz="UTC">{streamByDate}</Moment>.
            </Typography>
        </Box>
        <Box sx={{ my: 2, width: '100%' }}>
                {txState === 'none' || txState === 'pending' || txState === 'confirm' ? <LoadingButton
                    content={'Initiate Exit Stream'}
                    variant={"blue"}
                    onClick={handleInitExitStream}
                    disabled={txState === 'pending' || txState === 'confirm'  ? true : false}
                    loading={txState === 'pending' || txState === 'confirm'  ? true : false }
                /> : <CompleteButton content={'Exit Stream Initiated'} />}
        </Box>
        <TxContent txState={txState} currentTxHash={currentTxHash} />
    </Box>);
}