import React, { ReactNode } from 'react';
import { Button, CSSObject, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
    message: string | ReactNode;
    open: boolean;
    handleClose: () => void;
    vertical?: 'top' | 'bottom';
    horizontal?: 'right' | 'left' | 'center';
    style: CSSObject;
}

export default function GlobalSnackbar({ open, handleClose, vertical = 'bottom', horizontal = 'center', message, style }: Props) {
    const close = (event: any, reason: string) => {
        if (reason === "clickaway") return;
        handleClose();
    }

    return (<Snackbar
        sx={{ width: ['auto', 'max-content'], '& > div': style }}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={close}
        message={message}
        key={vertical + horizontal}
        action={
            <Button variant='text' onClick={handleClose}>
                <CloseIcon color="inherit" />
            </Button>
        }
    />);
}