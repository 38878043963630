import { useState } from "react";

type Props = {
    address: string;
}

export default function TokenIcon({ address }: Props) {
  const [didLoad, setLoad] = useState<boolean | undefined>(undefined);

  const onLoad = () => {
    setLoad(true);

  }

  const onError = () => {
    setLoad(false);
  }

  return <>{didLoad === undefined ? <img
    src={`/tokens/${address}/logo.png`}
    alt="token-icon"
    onLoad={onLoad}
    onError={onError}
    height="32"
    width="32"
  /> : didLoad ? <img
    src={`/tokens/${address}/logo.png`}
    alt="token-icon"
    height="32"
    width="32"
  /> : <img
    src={`/tokens/unknown-logo.png`}
    alt="token-icon"
    height="32"
    width="32"
  />}
  </>
}