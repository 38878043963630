import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { TxState, UserVotes } from '../../../../../web3/types';
import DisplayValue from '../../../../../components/DisplayValue';
import { useOnboardProvider } from '../../../../../providers/onboard-provider';
import TxLink from '../../../../../components/TxLink';
import CompleteButton from '../../../../../components/CompleteButton';
import LoadingButton from '../../../../../components/LoadingButton';
import { vote } from '../../../../../web3/DotDotVoting/vote';
import { toHex } from '../../../../../web3/utils';

type Props = {
    userVotes: UserVotes | undefined;
    setUserVotes: (uv: UserVotes) => void;
}

export default function Voter({ userVotes, setUserVotes }: Props) {
    const { signer, account } = useOnboardProvider();

    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const [sortedVotes, setSortedVotes] = useState<UserVotes>({});

    useEffect(() => {
        if (userVotes !== undefined) {
            setSortedVotes(userVotes);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async () => {
        if (signer !== undefined && account !== undefined && account !== null && userVotes !== undefined) {
            const voteKeys = Object.keys(userVotes);

            let pools: Array<string> = [];
            let votes: Array<string> = [];
            // eslint-disable-next-line array-callback-return
            voteKeys.map((k) => {
                if (!userVotes[k].amount.isZero()) {
                    pools.push(k);
                    votes.push(toHex(userVotes[k].amount));
                }
            });

            try {
                setTxState('pending');
                const tx = await vote(signer, account, pools, votes);
                setCurrentTxHash(tx.transactionHash)
                setTxState('finished');
            } catch (e) {
                setTxState('none');
                console.log(e);
            } finally {
                setUserVotes({});
            }
        }
    }

    const voteKeys = Object.keys(sortedVotes);
    return (<Box>
        <Box sx={{ pb: '16px'}}>
            <TableContainer component={Box} sx={{ color: '#ffffff', mb: 4 }}>
                <Table sx={{ minWidth: 1 }} aria-label="table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="subtitle2">Pool</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle2">Vote</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {voteKeys.map((k) => 
                            <TableRow
                                key={k}
                            >
                                <TableCell>
                                    <Typography variant="h4">
                                        {sortedVotes[k].symbol}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Typography variant="h4" sx={{ minWidth: '60px' }}>
                                            <DisplayValue value={sortedVotes[k].amount} />
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ paddingX: '24px' }}>
                {txState === 'none' || txState === 'pending' ? <LoadingButton
                    content={'Submit Votes'}
                    variant={"blue"}
                    onClick={handleSubmit}
                    disabled={txState === 'pending' ? true : false}
                    loading={txState === 'pending' ? true : false }
                /> : <CompleteButton content={'Votes submitted'} />}
            </Box>
        </Box>
        {txState === 'pending' ?
            currentTxHash.length > 0 ?
            <Box sx={{ px: 4, py: 1, pb: 3 }}>
                <TxLink currentTxHash={currentTxHash} />
            </Box> : <></> :
            txState === 'finished' ? <Box sx={{ px: 4, py: 1, pb: 3 }}>
                <TxLink currentTxHash={currentTxHash} />
            </Box> : <></>
        } 
    </Box>);
}