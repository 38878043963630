import * as React from 'react';
import { styled, useTheme, useMediaQuery } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MobileDrawer from '../Navigation/MobileDrawer';
import MenuItems from '../Navigation/MenuItems';
import SideBarMenu from '../Navigation/SideBarMenu';
import MobileToolBar from './MobileToolBar';
import DesktopToolBar from './DesktopToolBar';

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    background: 'linear-gradient(82.88deg, #976CF3 0%, #00BFFC 100%)',
    zIndex: 1201,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        paddingRight: 'unset !important',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function MainAppBar({ children }: React.PropsWithChildren<{}>) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = React.useState(isMobile ? false : true);
  
    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

    const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
            ) {
            return;
        }

        setOpen(!open);
    };
  
    return (<>
        <AppBar position="fixed" open={open}>
            <Toolbar>
                {isMobile ?
                    <MobileToolBar toggleDrawer={toggleDrawer} /> :
                    <DesktopToolBar open={open} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />
                }
            </Toolbar>
        </AppBar>
        {isMobile ?
            <MobileDrawer open={open} toggleDrawer={toggleDrawer} >
                <MenuItems open={open} />
            </MobileDrawer> :
            <SideBarMenu open={open}>
                <MenuItems open={open} />
            </SideBarMenu>
        }
    </>);
}