import React from 'react';
import './App.css';
import { Box, CssBaseline } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import { Navigate, Route, Routes } from "react-router-dom";
import StakePage from './pages/stake';
import LockPage from './pages/lock';
import ConvertPage from './pages/convert';
import VotePage from './pages/votes';
import BribesPage from './pages/bribes';
import MainAppBar from './components/AppBar';
import PageBackground from './components/PageBackground';
import { useSnackbarDataContext } from './providers/snackbar-provider';
import GlobalSnackbar from './components/GlobalSnackbar';

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { showSnackbar, closeSnackBar, message, snackBarStyle } = useSnackbarDataContext();
  return (
    <div className="App">
      <CssBaseline />
      <Box sx={{
          display: isMobile ? 'none' : 'block',
          position: 'absolute',
          height: '250px',
          width: '100%',
          background: 'linear-gradient(272.06deg, #ECF2F7 51.73%, #00BFFC 508.07%)',
          opacity: 1
        }}
      />
      <Box sx={{ position: 'relative', display: 'flex' }}>
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/stake" />}
          />
          <Route
            path="*"
            element={<Navigate to="/stake" />}
          />
          <Route
            path="/stake"
            element={<>
              <PageBackground background='stake' height='339px' width='1172px' />
              <MainAppBar />
              <Box component="main" sx={{ flexGrow: 1, zIndex: 1000 }}>
                <StakePage />
              </Box>
            </>}
          />
          <Route
            path="/lock"
            element={<>
              <PageBackground background='lock' height='444px' width='736px' />
              <MainAppBar />
              <Box component="main" sx={{ flexGrow: 1, zIndex: 1000 }}>
                <LockPage />
              </Box>
            </>}
          />
          <Route
            path="/convert"
            element={<>
              <PageBackground background='convert' height='618px' width='1071px' />
              <MainAppBar />
              <Box component="main" sx={{ flexGrow: 1, zIndex: 1000 }}>
                <ConvertPage />
              </Box>
            </>}
          />
          <Route
            path="/vote"
            element={<>
              <PageBackground background='vote' height='429px' width='807px' />
              <MainAppBar />
              <Box component="main" sx={{ flexGrow: 1, zIndex: 1000 }}>
                <VotePage />
              </Box>
            </>}
          />
          <Route
            path="/bribe"
            element={<>
              <PageBackground background='bribe' height='467px' width='1080px' />
              <MainAppBar />
              <Box component="main" sx={{ flexGrow: 1, zIndex: 1000 }}>
                <BribesPage />
              </Box>
            </>}
          />
        </Routes>
      </Box>
      <GlobalSnackbar open={showSnackbar} message={message} handleClose={closeSnackBar} style={snackBarStyle} />
    </div>);
}

export default App;
