
import React from 'react';
import TabsPanel from '../../../../../components/TabsPanel';
import DepositBribe from '../DepositBribe';
import BribesList from '../BribesList';
import { ApprovalVotes } from '../../../../../providers/api-provider';

type Props = {
    approvalVote: ApprovalVotes;
}

function BribeControls({ approvalVote }: Props) {
    return (<TabsPanel
        sx={{
            '.MuiTabs-flexContainer': {
                flexDirection: ['column', 'row'],
                marginTop: '8px',
                marginBottom: '8px',
                border: 'unset',
                '& > button': {
                    minHeight: 'unset',
                    maxWidth: ['unset', 'inherit'],
                },
            },
            '& > .MuiTabs-scroller > span': {
            }
        }}
        tabs={!approvalVote.expired && (approvalVote.dddGivenVotes < approvalVote.dddRequiredVotes) ? [
            {
                title: <>All Bribes</>,
                icon: <></>,
                component: <BribesList incentives={approvalVote.incentives} />
            },
            {
                title: <>Deposit</>,
                icon: <></>,
                component: <DepositBribe approvalVote={approvalVote} />
            },
        ] : [
            {
                title: <>All Bribes</>,
                icon: <></>,
                component: <BribesList incentives={approvalVote.incentives} />
            },
            {
                title: <>Deposit</>,
                icon: <></>,
                component: <>Bribe deposits can only be done before the vote has passed or expired.</>
            },
        ]}
    />);
}

export default BribeControls;
