import  React, { ChangeEvent, ReactNode, useState } from 'react';
import { Typography, Box, TableHead, Table, TableBody, TableCell, TableContainer, TableRow, TablePagination } from '@mui/material';

export type RowItem = {
    item: ReactNode;
    key: string;
}

type Props = {
    headings: string[] | ReactNode[],
    rows: RowItem[],
    claimButton: ReactNode,
}

const DEFAULT_POOLS_PER_PAGE = 5;

export default function DisplayTableRewards({ headings, rows, claimButton }: Props)  {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_POOLS_PER_PAGE);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <TableContainer component={Box}>
            <Table aria-label="table">
                <TableHead>
                    <TableRow
                        sx={{
                            '& > th > h6': { fontSize: ['12px', '14px'] },
                            '& > th': { padding: ['1px 1px', '16px'] }
                        }}
                    >
                        {headings.map((h, k) => 
                            <TableCell key={k}>
                                {typeof(h) === 'string' ?
                                    <Typography variant="subtitle2">{h}</Typography>
                                : h}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((r) => (
                        <TableRow
                            key={r.key}
                            sx={{
                                '& > td > h6': { fontSize: ['12px', '14px'] },
                                '& > td': { padding: ['4px 4px', '16px'] }
                            }}
                        >
                            {r.item}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: ['center', 'flex-end'], paddingX: ['0px', '16px'] }}>
            <TablePagination
                sx={{ '& > div > p.MuiTablePagination-displayedRows': { fontSize: ['12px', '14px'] } }}
                rowsPerPageOptions={[5]}
                labelRowsPerPage=""
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Box sx={{ width: 'fit-content' }}>
                {claimButton}
            </Box>
        </Box>
    </Box>)
}
