import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import { Box, Skeleton, TableCell, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DisplayValue, { DisplayValue2 } from '../../../../../components/DisplayValue';
import { UserPool2Deposit } from '../../../../../web3/types';
import DisplayTable from '../../../../../components/DisplayTable';
import { CONFIG } from '../../../../../global';
import TokenIcon from '../../../../../components/TokenIcon';

type Props = {
    lpDeposits: Array<UserPool2Deposit> | undefined;
}

export default function LpDepositsList({ lpDeposits }: Props) {
    const [sortedLpDeposits, setSortedLpDeposits] = useState<any[]>([])

    useEffect(() => {
        if (lpDeposits) {
            const sortedLpDeposits = lpDeposits.sort((a, b) => a.timestamp - b.timestamp)
                .filter((a) => a.amount.isGreaterThan(0));
            setSortedLpDeposits(sortedLpDeposits);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lpDeposits]);

    const headings = [
        '',
        'Amount staked',
        <Tooltip
            title={`This is the fee applied upon removing your ${CONFIG.lpSymbol} tokens from the stake.`}
            placement="top-start"
            arrow
        >
            <Typography variant="subtitle2">
                Current unstaking fee
                <InfoIcon fontSize='inherit' />
            </Typography>
        </Tooltip>,
        <Tooltip
            title={`The unstaking fee starts at 8% and is reduced by 1% each week until the decay date when it will become 0%.`}
            placement="top"
            arrow
        >
            <Typography variant="subtitle2">
                Fee decay date
                <InfoIcon fontSize='inherit' />
            </Typography>
        </Tooltip>
    ];

    const rows = lpDeposits ? sortedLpDeposits.map((l, k) => {return {
        item: <>
            <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TokenIcon address={CONFIG.LpToken} />
                    <Typography variant="h6" sx={{ ml: 0.5, fontSize: ['12px', '14px'] }}>
                        Cake DDD/BNB LP
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Typography variant="h6">
                    <DisplayValue value={l.amount} decimals={1e18} />
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="h6">
                    <DisplayValue2 value={l.withdrawalFee} />&nbsp;
                    (<DisplayValue2 value={l.withdrawalFeePercent} />%)
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="h6">
                    <Moment unix format="dddd MMM D, YYYY TZ" tz="UTC">
                        {l.decayDate}
                    </Moment>
                </Typography>
            </TableCell>
        </>,
        key: k.toString()
    }}) : [];

    return (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {lpDeposits !== undefined ? <DisplayTable headings={headings} rows={rows} /> : <>
            <Skeleton variant="rectangular" width={'100%'} height={10} sx={{ my: 2, borderRadius: '4px' }} />
            <Skeleton variant="rectangular" width={'100%'} height={10} sx={{ my: 2, borderRadius: '4px' }} />
        </>}
    </Box>);
}