import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Skeleton, TableCell, Typography } from '@mui/material';
import { DisplayValue2 } from '../../../../../components/DisplayValue';
import { useOnboardProvider } from '../../../../../providers/onboard-provider';
import LoadingButton from '../../../../../components/LoadingButton';
import CompleteButton from '../../../../../components/CompleteButton';
import { FeeUserClaimable, TxState } from '../../../../../web3/types';
import DisplayTableRewards from '../../../../../components/DisplayTableRewards';
import TokenIcon from '../../../../../components/TokenIcon';
import TxModal from '../../../../../components/TxModal';
import { claim } from '../../../../../web3/DddIncentiveDistributor/claim';

type Props = {
    earnings: Array<FeeUserClaimable>,
    earningsTotalUSD: number
}

type SelectedTokens = {
    [key: string]: boolean;
}

export default function ClaimableBribes({
    earnings,
    earningsTotalUSD
}: Props) {
    const { signer, account } = useOnboardProvider();

    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const [selectedTokens, setSelectedTokens] = useState({} as SelectedTokens);
    const [totalSelected, setTotalSelected] = useState(0);
    const [selectedToFees, setSelectedToFees] = useState({} as {[key: string]: string[]});

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        selectedTokens[event.target.name] = event.target.checked;
        const newSelectedTokens = {} as SelectedTokens;
        Object.assign(newSelectedTokens, selectedTokens)
        setSelectedTokens(newSelectedTokens);
        let totalSelected = 0;
        let selectedToFees: { [key: string]: string[] } = {};
        for (let i = 0; i < earnings.length; i++) {
            if (newSelectedTokens[earnings[i].lpToken] === true && earnings[i].claimableUSD > 0) {
                totalSelected += earnings[i].claimableUSD;

                let currentFees: string[] = [];
                if (selectedToFees[earnings[i].lpToken]) {
                    currentFees = selectedToFees[earnings[i].lpToken];
                }

                currentFees.push(earnings[i].feeToken);

                const asd = {[earnings[i].lpToken]: currentFees};
        
                Object.assign(asd, selectedToFees)
                Object.assign(selectedToFees, asd);
            }
        }
        setSelectedToFees(selectedToFees);
        setTotalSelected(totalSelected);
    };

    const handleClaimRewards = async () => {
        const keys = Object.keys(selectedTokens);

        let lps = [];
        for (let i = 0; i < keys.length; i++) {
            if (selectedTokens[keys[i]] === true) {
                lps.push(keys[i])
            }
        }

        if (signer !== undefined && account !== undefined && account !== null && lps.length > 0) {
            try {
                for (let i = 0; i < lps.length; i++) {
                    const txFinished = () => {
                        setTxState('finished');
                    }
                    await claim(
                        {
                            setTxHash: setCurrentTxHash,
                            setTxState: setTxState,
                            setTxError: () => {},
                            txFinished: txFinished,
                        },
                        signer,
                        account,
                        lps[i],
                        selectedToFees[lps[i]]
                    );
                }
            } catch (e) {
                setTxState('none');
                console.log(e);
            } finally {
            }
        }
    }
    const [sortedEarnings, setSortedEarnings] = useState<FeeUserClaimable[]>([])

    useEffect(() => {
        if (earnings) {
            const sortedEarnings = earnings.sort(
                (a, b) => (b.claimableUSD - a.claimableUSD)).filter((e) => e.claimable !== 0
            );
            setSortedEarnings(sortedEarnings);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [earnings]);

    const headings = [<Typography variant="h4" minWidth={['', '56px']} sx={{ fontSize: ['12px', '14px'] }}>Bribes</Typography>, 'Token', 'Claimable (USD)', 'Amount', ''];

    const rows = sortedEarnings.map((e, k) => {return {
        item: <>
            <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                    <TokenIcon address={e.lpToken} />
                    <Typography variant="h6" sx={{ ml: 0.5, fontSize: ['12px', '14px'] }}>
                        {e.lpSymbol}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                    <TokenIcon address={e.feeToken} />
                    <Typography variant="h6" sx={{ ml: 0.5, fontSize: ['12px', '14px'] }}>
                        {e.feeSymbol}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Typography variant="h6">
                    $<DisplayValue2
                        value={e.claimableUSD}
                        precision={2}
                    />
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="h6">
                    <DisplayValue2 value={e.claimable} precision={3} />
                </Typography>
            </TableCell>
            <TableCell>
                <Checkbox
                    disableRipple
                    sx={{ color: '#976CF3', width: '100%', p: 'unset', minWidth: [0, '115px'], justifyContent: ['flex-start', 'flex-end'], paddingRight: [0, '32px'] }}
                    checked={selectedTokens[e.lpToken] === undefined ? false : selectedTokens[e.lpToken]}
                    onChange={handleChange}
                    name={e.lpToken}
                />
            </TableCell>
            </>,
        key: k.toString()
    }});

    const claimButton = <>{txState === 'none' || txState === 'pending' || txState === 'confirm' ? <LoadingButton
            content={'Claim selected'}
            variant={"blue"}
            onClick={handleClaimRewards}
            disabled={totalSelected === 0 || txState === 'pending' ? true : false}
            loading={txState === 'pending' ? true : false }
        /> : <CompleteButton content={'Earnings claimed'} />}</>

    return (<>
        <Box sx={{ display: 'flex', background: '#ECF2F4' }}>
            {earnings !== undefined ? <DisplayTableRewards headings={headings} rows={rows} claimButton={claimButton} /> : <>
                <Skeleton variant="rectangular" width={'100%'} height={10} sx={{ my: 2, borderRadius: '4px' }} />
                <Skeleton variant="rectangular" width={'100%'} height={10} sx={{ my: 2, borderRadius: '4px' }} />
            </>}
        </Box>
        <TxModal txState={txState} currentTxHash={currentTxHash} setTxState={setTxState} />
    </>);
}

