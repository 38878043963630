import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { APPROVAL_VOTE_BIRBS, CONFIG } from '../../global';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import DDDVoterAbi from '../../abi/DDDVoter.json';

export type Incentive = {
  address: string;
  price: number;
  symbol: string;
  name: string;
  decimals: number;
  weeklyAmounts: number;
  weeklyAmountsUSD: number;
  apr: number;
};

export type TokenInfos = {
  [key: string]: Incentive;
}

export type LpToTokenInfos = {
  [key: string]: Incentive[];
}

export type LpToWeeklyAmountUSD = {
  [key: string]: {totalBribesPerLockedDDD: number, totalBribes: number, totalApr: number};
}

export type Pool2 = {
  reserves0: number;
  reserves1: number;
  token0: string;
  token1: string;
  symbol0: string;
  symbol1: string;
  lpTokenPrice: number;
  lpTokenSymbol: string;
  lpTokenName: string;
  totalSupply: number;
  totalSupplyUSD: number;
  yearlyRewardsUSD: number;
  pool2RewardsPerSecond: number;
  totalLpStaked: number;
  totalLpStakedUSD: number;
  apr: number;
  depositFee: number;
  startTime: number;
  polAmount: number;
  polAmountUSD: number;
  polPercentOfSupply: number;
};

export type TokenStats = {
  dddSupplyLocked: number;
  dddSupplyLockedUSD: number;
  dddTotalSupply: number;
  dddTotalSupplyUSD: number;
  dddMarketCapUSD: number;
  votingPower: number;
  depxTotalSupply: number;
  depxLockedSupply: number;
  depxTotalSupplyUSD: number;
  depxLockedSupplyUSD: number;
  currentWeek: number;
  startTime: number;
  dddEllipsisWeight: number;
  vlEPXdominance: number;
};

export type TokenVotes = {
  token: string;
  name: string;
  symbol: string;
  votes: number;
  userVote: number;
  hide: boolean;
  epxRewardsPerSecond: number;
  epxRewardsPerDay: number;
}

export type VoterData = {
  votingOpen: boolean;
  currentWeek: number;
  totalVotes: number;
  votes: TokenVotes[];
  minWeightForNewTokenApprovalVote: number;
  depositCap: number;
  receivedDeposits: number;
  reservedDeposits: number;
  totalWeeklyReservedDeposits: number;
  epxCurrentWeek: number;
  currentVoteRatio: number;
  startTime: number;
};

export type FeeToken = {
  address: string;
  symbol: string;
  decimals: number;
  price: number;
  weeklyAmount: number;
  weeklyAmountUSD: number;
  apr: number;
}

export type DepxData = {
  feeTokens: FeeToken[];
  feeTokenAddresses: [];
  totalValue: number;
  totalApr: number;
};

export type ExtraReward = {
  address: string;
  symbol: string;
  name: string;
  decimals: number;
  price: number;
  apr: number;
};

export type LpDetails = {
  token: string;
  symbol: string;
  name: string;
  decimals: number;
  totalSupply: number;
  pool: string;
  price: number;
  totalSupplyUSD: number;
  adjustedSupply: number;
  adjustedSupplyUSD: number;
  epxRewardsPerSecond: number;
  baseApr: number;
  dddTvl: number;
  dddTvlUSD: number;
  epsTvl: number;
  epsTvlUSD: number;
  dddAPR: number;
  epxAPR: number;
  realDddAPR: number;
  realEpxAPR: number;
  minDddAPR: number;
  minEpxAPR: number;
  rewardRateRatio: number;
  dddPercentage: number;
  boost: number;
  extraRewardsLength: number;
  extraRewards: ExtraReward[];
  extraRewardsTotalApr: number;
  hide: boolean;
}

export type Tvls = {
  totalTvl: number;
  dddSupplyLockedUSD: number;
  depxLockedSupplyUSD: number;
  dddLpTvl: number;
  epsLpTvl: number;
  dominance: number;
}

export type ApprovalVotesIncentive = {
  address: string;
  symbol: string;
  name: string;
  decimals: number;
  amount: number;
  amountUSD: number;
};

export type ApprovalVotes = {
  hide: boolean;
  index: number;
  token: string;
  pool: string;
  name: string;
  symbol: string;
  startTime: number;
  week: number;
  epsRequiredVotes: number;
  epxGivenVotes: number;
  dddRequiredVotes: number;
  dddGivenVotes: number;
  expired: boolean;
  incentives: ApprovalVotesIncentive[];
  totalIncentivesUSD: number;
}

export type ApprovalVotesIncentivesInfo = {
  [key: string]: {
    address: string, 
    price: number,
    symbol: string,
    name: string,
    decimals: number;
  }
}

export type ApprovalVotesData = {
  totalApprovalVotes: number;
  tokenApprovalVotes: ApprovalVotes[];
  quorumPercentRequired: number;
  minWeightForNewTokenApprovalVote: number;
  currentRatio: number;
  tokenIncentiveInfo: ApprovalVotesIncentivesInfo;
}

export interface ApiContextData {
  dddPrice: number;
  epxPrice: number;
  lpTokenPrice: number;
  depxPrice: number;
  pool2: Pool2;
  tokenStats: TokenStats;
  voterData: VoterData;
  depxData: DepxData;
  lps: LpDetails[] | undefined;
  tokenInfo: TokenInfos;
  lpToTokenInfo: LpToTokenInfos;
  tvls: Tvls;
  lpToWeeklyAmountUSD: LpToWeeklyAmountUSD;
  totalBribes: number;
  totalBribesApr: number;
  lastWeekTotalBribes: number;
  lastWeekTotalBribesApr: number;
  totalRewards: number;
  totalRewardsApr: number;
  lastWeekTotalRewards: number;
  lastWeekTotalRewardsApr: number;
  totalBribesPerLockedDDD: number;
  approvalVotesData: ApprovalVotesData;
}

const ApiDataContext = React.createContext({} as ApiContextData);

const PRICE_TIME_INTERVAL = 600000;
const POOL2_TIME_INTERVAL = 600000 * 5;
const TOKENSTATS_TIME_INTERVAL = 600000;
const VOTERDATA_TIME_INTERVAL = 600000 * 5;
const DEPXDATA_TIME_INTERVAL = 600000 * 5;
const LPDETAILS_TIME_INTERVAL = 600000;
//const INCENTIVES_TIME_INTERVAL = 600000 * 5;

export const BASE_API = CONFIG.apiDomain + '/api';

export function ApiProvider({ children }: PropsWithChildren<{}>) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dddPrice, setDddPrice] = useState<number>(0);
  const [epxPrice, setEpxPrice] = useState<number>(0);
  const [depxPrice, setDepxPrice] = useState<number>(0);
  const [lpTokenPrice, setLpTokenPrice] = useState<number>(0);
  const [pool2, setPool2] = useState<Pool2>({
    reserves0: 0,
    reserves1: 0,
    token0: '',
    token1: '',
    symbol0: '',
    symbol1: '',
    lpTokenPrice: 0,
    lpTokenSymbol: '',
    lpTokenName: '',
    totalSupply: 0,
    totalSupplyUSD: 0,
    yearlyRewardsUSD: 0,
    pool2RewardsPerSecond: 0,
    totalLpStaked: 0,
    totalLpStakedUSD: 0,
    apr: 0,
    depositFee: 0,
    startTime: 0,
    polAmount: 0,
    polAmountUSD: 0,
    polPercentOfSupply: 0,
  });
  const [tokenStats, setTokenStats] = useState<TokenStats>({
    dddSupplyLocked: 0,
    dddSupplyLockedUSD: 0,
    dddTotalSupply: 0,
    dddTotalSupplyUSD: 0,
    dddMarketCapUSD: 0,
    votingPower: 0,
    depxTotalSupply: 0,
    depxLockedSupply: 0,
    depxTotalSupplyUSD: 0,
    depxLockedSupplyUSD: 0,
    currentWeek: 0,
    startTime: 0,
    dddEllipsisWeight: 0,
    vlEPXdominance: 0,
  });
  const [voterData, setVoterData] = useState<VoterData>({
    votingOpen: false,
    currentWeek: 0,
    totalVotes: 0,
    votes: [],
    minWeightForNewTokenApprovalVote: 0,
    depositCap: 0,
    receivedDeposits: 0,
    reservedDeposits: 0,
    totalWeeklyReservedDeposits: 0,
    epxCurrentWeek: 0,
    currentVoteRatio: 0,
    startTime: 0
  });
  const [depxData, setDepxData] = useState<DepxData>({
    feeTokens: [],
    feeTokenAddresses: [],
    totalValue: 0,
    totalApr: 0
  });
  const [lps, setLps] = useState<LpDetails[] | undefined>(undefined);
  const [tokenInfo, setTokenInfo] = useState<TokenInfos>({});
  const [lpToTokenInfo, setLpToTokenInfo] = useState<LpToTokenInfos>({});
  const [lpToWeeklyAmountUSD, setLpToWeeklyAmountUSD] = useState<LpToWeeklyAmountUSD>({})
  const [tvls, setTvls] = useState<Tvls>({
    totalTvl: 0,
    dddSupplyLockedUSD: 0,
    depxLockedSupplyUSD: 0,
    dddLpTvl: 0,
    epsLpTvl: 0,
    dominance: 0,
  });
  const [totalBribesApr, setTotalBribesApr] = useState(0);
  const [totalBribes, setTotalBribes] = useState(0);
  const [totalRewardsApr, setTotalRewardsApr] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [lastWeekTotalBribesApr, setLastWeekTotalBribesApr] = useState(0);
  const [lastWeekTotalBribes, setLastWeekTotalBribes] = useState(0);
  const [lastWeekTotalRewardsApr, setLastWeekTotalRewardsApr] = useState(0);
  const [lastWeekTotalRewards, setLastWeekTotalRewards] = useState(0);
  const [totalBribesPerLockedDDD, setTotalBribesPerLockedDDD] = useState(0);
  const [approvalVotesData, setApprovalVotesData] = useState<ApprovalVotesData>({
    totalApprovalVotes: 0,
    tokenApprovalVotes: [],
    quorumPercentRequired: 0,
    minWeightForNewTokenApprovalVote: 0,
    currentRatio: 0,
    tokenIncentiveInfo: {}
  });
  
  const getPrice = async () => {
    axios.get(`${BASE_API}/prices`).then((result: any) => {
      setDddPrice(result.data.data.dddPrice);
      setEpxPrice(result.data.data.epxPrice);
      setDepxPrice(result.data.data.depxPrice);
      setLpTokenPrice(result.data.data.lpTokenPrice);
    }).catch((e) => console.error(e));
  };

  useEffect(() => {
    getPrice();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(getPrice, PRICE_TIME_INTERVAL);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPool2 = async () => {
    axios.get(`${BASE_API}/pool2`).then((result: any) => {
      setPool2(result.data.data);
    }).catch((e) => console.error(e));
  };

  useEffect(() => {
    getPool2();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(getPool2, POOL2_TIME_INTERVAL);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTokenStats = async () => {
    axios.get(`${BASE_API}/tokenStats`).then((result: any) => {
      setTokenStats(result.data.data);
    }).catch((e) => console.error(e));
  };

  useEffect(() => {
    getTokenStats();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(getTokenStats, TOKENSTATS_TIME_INTERVAL);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVoterData = async () => {
    axios.get(`${BASE_API}/voterData`).then((result: any) => {
      setVoterData(result.data.data);
    }).catch((e) => console.error(e));
  };

  useEffect(() => {
    getVoterData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(getVoterData, VOTERDATA_TIME_INTERVAL);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDepxData = async () => {
    axios.get(`${BASE_API}/depxData`).then((result: any) => {
      setDepxData(result.data.data);
    }).catch((e) => console.error(e));
  };

  useEffect(() => {
    getDepxData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(getDepxData, DEPXDATA_TIME_INTERVAL);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLpDetails = async () => {
    axios.get(`${BASE_API}/lpDetails`).then((result: any) => {
      setLps(result.data.data.tokens.filter((lp: LpDetails) => !lp.hide));
    }).catch((e) => console.error(e));
  };

  useEffect(() => {
    getLpDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(getLpDetails, LPDETAILS_TIME_INTERVAL);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIncentives = async () => {
    axios.get(`${BASE_API}/incentives`).then((result: any) => {
      setTokenInfo(result.data.data.tokenInfo);
      setLpToTokenInfo(result.data.data.lpTokenToIncentiveInfo);
      setLpToWeeklyAmountUSD(result.data.data.lpTokenToWeeklyAmountUSD)
      setTotalBribesApr(result.data.data.totalBribesApr)
      setTotalBribes(result.data.data.totalBribes)
      setTotalRewardsApr(result.data.data.totalRewardsApr)
      setTotalRewards(result.data.data.totalRewards)
      setTotalBribesPerLockedDDD(result.data.data.totalBribesPerLockedDDD)
    }).catch((e) => console.error(e));
  };

  useEffect(() => {
    getIncentives();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(getIncentives, 60000);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLastWeekIncentives = async () => {
      const web3 = new Web3(CONFIG.rpc);
      const dddVoting = new web3.eth.Contract(DDDVoterAbi as AbiItem[], CONFIG.DDDVoter);
      dddVoting.methods.getWeek().call().then((res: any) => {
        // So we can get current streamed rewards APR (rewards accumlated last week)
        axios.get(`${BASE_API}/incentives?week=${parseInt(res)-1}`).then((result: any) => {
            setLastWeekTotalBribesApr(result.data.data.totalBribesApr)
            setLastWeekTotalBribes(result.data.data.totalBribes)
            setLastWeekTotalRewardsApr(result.data.data.totalRewardsApr)
            setLastWeekTotalRewards(result.data.data.totalRewards)
        }).catch((e) => console.error(e));
      })
  };

  useEffect(() => {
    getLastWeekIncentives();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
      const interval = setInterval(getLastWeekIncentives, 60000);
      return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTvl = async () => {
    axios.get(`${BASE_API}/tvl`).then((result: any) => {
      setTvls(result.data.data);
    }).catch((e) => console.error(e));
  };

  useEffect(() => {
    getTvl();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(getTvl, 60000);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getApprovalVotesData = async () => {
    axios.get(APPROVAL_VOTE_BIRBS ? `${BASE_API}/approvalVotesIncentives` : `${BASE_API}/approvalVotes`).then((result: any) => {
      setApprovalVotesData(result.data.data);
    }).catch((e) => console.error(e));
  };

  useEffect(() => {
    getApprovalVotesData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(getApprovalVotesData, 60000 * 5);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ApiDataContext.Provider
      value={{
        dddPrice,
        epxPrice,
        lpTokenPrice,
        depxPrice,
        pool2,
        tokenStats,
        voterData,
        depxData,
        lps,
        tokenInfo,
        lpToTokenInfo,
        tvls,
        lpToWeeklyAmountUSD,
        totalBribes,
        totalBribesApr,
        lastWeekTotalBribes,
        lastWeekTotalBribesApr,
        totalRewards,
        totalRewardsApr,
        lastWeekTotalRewards,
        lastWeekTotalRewardsApr,
        totalBribesPerLockedDDD,
        approvalVotesData
      }}
    >
      {children}
    </ApiDataContext.Provider>
  );
}

export const useApiDataContext = () => useContext(ApiDataContext);
