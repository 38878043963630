import  React, { ChangeEvent, ReactNode, useState } from 'react';
import { Typography, Box, TableHead, Table, TableBody, TableCell, TableContainer, TableRow, TablePagination } from '@mui/material';

export type RowItem = {
    item: ReactNode;
    key: string;
}

type Props = {
    headings: string[] | ReactNode[],
    rows: RowItem[],
    pagination?: boolean,
}

const DEFAULT_POOLS_PER_PAGE = 5;

export default function DisplayTable({ headings, rows, pagination = true }: Props)  {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_POOLS_PER_PAGE);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (<>
        <TableContainer component={Box}>
            <Table aria-label="table">
                <TableHead>
                    <TableRow
                        sx={{
                            '& > th > h6': { fontSize: ['12px', '14px'] },
                            '& > th': { padding: ['1px 1px', '16px'] }
                        }}
                    >
                        {headings.map((h, k) => 
                            <TableCell key={k}>
                                {typeof(h) === 'string' ?
                                    <Typography variant="subtitle2">{h}</Typography>
                                : h}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows
                        .slice(pagination ? page * rowsPerPage : 0, pagination ? page * rowsPerPage + rowsPerPage : undefined)
                        .map((r) => (
                        <TableRow
                            key={r.key}
                            sx={{
                                '& > td > h6': { fontSize: ['12px', '14px'] },
                                '& > td': { padding: ['4px 4px', '16px'] }
                            }}
                        >
                            {r.item}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        {pagination ? <TablePagination
            sx={{ '& > div > p.MuiTablePagination-displayedRows': { fontSize: ['12px', '14px'] } }}
            rowsPerPageOptions={[5]}
            labelRowsPerPage=""
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        /> : <></>}
    </>)
}
