import BigNumber from "bignumber.js";
import Web3 from "web3";

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export function calcGas(n: any) {
    BigNumber.config({ DECIMAL_PLACES: 0 });
    return '0x' + (new BigNumber(n).multipliedBy(1.5).absoluteValue().toString(16));
}

export function maxUint256() {
    return Web3.utils.toBN(2).pow(Web3.utils.toBN(256)).sub(Web3.utils.toBN(1));
}

export function toHex(n: BigNumber) {
    BigNumber.config({ DECIMAL_PLACES: 0 });
    return '0x' + n.toString(16);
}