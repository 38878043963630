import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { CONFIG } from '../../global';

type Props = {
    currentTxHash: string;
}

export default function TxLink({ currentTxHash }: Props) {
    return (<Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '28px',
        padding: '8px'
    }}>
        <Typography sx={{ color: '#976CF3', fontSize: '16px', lineHeight: '24px', fontWeight: 'normal', textAlign: 'center' }}>
            <Link
                sx={{ color: '#976CF3', '&:hover': { opacity: 0.5, color: '#976CF3' } }}
                rel="noreferrer"
                target="__blank"
                href={`${CONFIG.explorer}tx/${currentTxHash}`}
            >
                View on BSCscan
            </Link>
        </Typography>
    </Box>);
}