import React, { useState } from 'react';
import { useOnboardProvider } from '../../../../../providers/onboard-provider';
import LoadingButton from '../../../../../components/LoadingButton';
import CompleteButton from '../../../../../components/CompleteButton';
import { TxState, UserLpDeposit } from '../../../../../web3/types';
import { claimExtraRewards } from '../../../../../web3/LpDepositor/claimExtraRewards';
import TxModal from '../../../../../components/TxModal';

type Props = {
    earning: UserLpDeposit;
}

export default function ClaimExtraRewards({ earning }: Props) {
    const { signer, account } = useOnboardProvider();

    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const handleClaimExtraRewards = async () => {
        if (signer !== undefined && account !== undefined && account !== null && earning.token.length > 0) {
            const txFinished = () => {
                setTxState('finished');
            }

            await claimExtraRewards(
                {
                    setTxHash: setCurrentTxHash,
                    setTxState: setTxState,
                    setTxError: () => {},
                    txFinished: txFinished,
                },
                signer,
                account,
                earning.token
            );
        }
    }

    return (<>
       {txState === 'none' || txState === 'pending' || txState === 'confirm' ? <LoadingButton
            content={`Claim ${earning.extraClaimable.length > 1 ?
                earning.extraClaimable.reduce((s: string, ec: any) => ec.symbol + ', ' + s, '') : 
                earning.extraClaimable.length === 1 ? earning.extraClaimable[0].symbol : ''}`
            }
            variant={"blue"}
            onClick={handleClaimExtraRewards}
            disabled={earning.totalExtraClaimableUSD === 0 || txState === 'pending' ? true : false}
            loading={txState === 'pending' ? true : false }
        /> : <CompleteButton content={`Claimed`} />}
        <TxModal txState={txState} currentTxHash={currentTxHash} setTxState={setTxState} />
    </>)
}