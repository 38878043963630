import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ConnectionHandler from '../../ConnectionHandler';
import ConnectedNetwork from '../../ConnectionHandler/ConnectedNetwork';
import { ReactComponent as StatsButtonLogo } from '../../../images/statsbutton/logo.svg';
import { ReactComponent as MobileLogo } from '../../../images/mobilelogo/logo.svg';
import ModalHandler from '../../ModalHandler';
import AllStats from '../../AllStats';

type Props = {
    toggleDrawer: () => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

export default function MobileToolBar({ toggleDrawer }: Props) {
    return (<>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', px: '16px' }}>
            <MobileLogo />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <ConnectedNetwork />
            <ConnectionHandler />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', pl: '8px' }}>
            <ModalHandler buttonType='blue' buttonTitle={<>Stats&nbsp;&nbsp;<StatsButtonLogo /></>} disabled={false}>
                <AllStats />
            </ModalHandler>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginRight: '24px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingX: '12px' }}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer()}
                    edge="start"
                    sx={{
                        display: 'flex',
                        marginLeft: 'unset'
                    }}
                >
                    <MenuIcon />
                </IconButton>
            </Box>
        </Box>
    </>);
}