import React, { useEffect, useState } from 'react'

interface Props {
    endTime: number;
}

export const Countdown: React.FC<Props> = ({  endTime }: Props) => {
    //const currentUnixTime = Math.round(Date.now() / 1000);

    //const hasEnded = currentUnixTime > endTime;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ days, setDays ] = useState(-1);
    const [ hours, setHours ] = useState(-1);
    const [ minutes, setMinutes ] = useState(-1);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ seconds, setSeconds ] = useState(-1);

    const countdownTo = () => {
        let currentTime = new Date().getTime() / 1000;
        let futureTime = endTime;
        let timeRemaining = futureTime - currentTime;
        let minute = 60;
        let hour = 60 * 60;
        let day = 60 * 60 * 24;
        let dayFloor = Math.floor(timeRemaining / day);
        let hourFloor = Math.floor((timeRemaining - dayFloor * day) / hour);
        let minuteFloor = Math.floor((timeRemaining - dayFloor * day - hourFloor * hour) / minute);
        let secondFloor = Math.floor((timeRemaining - dayFloor * day - hourFloor * hour - minuteFloor * minute));
        
        if (futureTime > currentTime) {
            setDays(dayFloor);
            setHours(hourFloor);
            setMinutes(minuteFloor);
            setSeconds(secondFloor);
        } else {
            setDays(0);
            setHours(0);
            setMinutes(0);
            setSeconds(0);
        }
    }

    useEffect(() => {
        countdownTo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const interval = setInterval(countdownTo, 1000);
        return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    return (<>
        {days}d {hours}h {minutes}m
    </>)
}