
import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid } from '@mui/material';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import Multicall from '@dopex-io/web3-multicall';
import { AbiItem } from 'web3-utils';
import CardTitle from '../../../components/CardTitle';
import { useApiDataContext } from '../../../providers/api-provider';
import VotesList from './components/VotesList';
import { useOnboardProvider } from '../../../providers/onboard-provider';
import { CONFIG } from '../../../global';
import DDDVoterAbi from '../../../abi/DDDVoter.json';
import { UserApprovalVote } from '../../../web3/types';

const loadData = async (user: string, votesCount: number) => {
    console.log(`loading lock data for ${user}`);
    const web3 = new Web3(CONFIG.rpc);

    const multicall = new Multicall({
        multicallAddress: CONFIG.multicall,
        provider: CONFIG.rpc,
    });

    const dddVoter = new web3.eth.Contract(DDDVoterAbi as AbiItem[], CONFIG.DDDVoter)

    const userCalls = [];

    for (let i = 0; i < votesCount; i++) {
        userCalls.push(dddVoter.methods.availableTokenApprovalVotes(user, i));
        userCalls.push(dddVoter.methods.userTokenApprovalVotes(i, user));
    }

    const availableVotesData = await multicall.aggregate(userCalls);

    const userVotes: UserApprovalVote[] = [];

    let j = 0;
    for (let i = 0; i < votesCount; i++) {
        userVotes.push({
            index: i,
            availableVotes: new BigNumber(availableVotesData[j]),
            usedVotes: new BigNumber(availableVotesData[j+1]).toNumber(),
        })
        j += 2;
    }

    return { userApprovalVotes: userVotes };
}

function VoteTokenApproval() {
    const { approvalVotesData } = useApiDataContext();

    const { account } = useOnboardProvider();

    const [userApprovalVotes, setUserApprovalVotes] = useState<UserApprovalVote[]>([]);

    const doAction = async (
        acc: string | undefined | null,
        votesCount: number,
    ) => {
        if (acc !== undefined && acc != null) {
            try {
                const { userApprovalVotes } = await loadData(acc, votesCount);
                setUserApprovalVotes(userApprovalVotes);
            } catch (e) {
                console.error(e);
            }
        }
    }

    useEffect(() => {
        doAction(account, approvalVotesData.totalApprovalVotes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, approvalVotesData]);

    useEffect(() => {
        const interval = setInterval(() => doAction(account, approvalVotesData.totalApprovalVotes), 5000);
        return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, approvalVotesData]);

    return (<Card>
        <CardContent>
            <Box sx={{
                p: '24px 24px',
                background: 'linear-gradient(90deg, #966DF3 0%, #0CB9FC 100%);'
            }}>
                <CardTitle
                    title={'Pool Approval'}
                    subtitle='Vote for new pools to receive EPX emissions.'
                />
            </Box>
            <Grid container>
                <Grid item xs={12} lg={12}>
                    <VotesList approvalVotes={approvalVotesData.tokenApprovalVotes} userApprovalVotes={userApprovalVotes} />
                </Grid>
            </Grid>
        </CardContent>
    </Card>);
}

export default VoteTokenApproval;
