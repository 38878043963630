import React, { useEffect, useState } from 'react';
import { Box, Grid, Skeleton, Tooltip, Typography, useTheme, useMediaQuery } from '@mui/material';
import Moment from 'react-moment';
import 'moment-timezone';
import { DisplayValue2 } from '../../../../../components/DisplayValue';
import DisplayTableAccordian from '../../../../../components/DisplayTableAccordian';
import BribeControls from '../BribeControls';
import TokenIcon from '../../../../../components/TokenIcon';
import DisplayPreciseNumber from '../../../../../components/DisplayPreciseNumber';
import { ApprovalVotes } from '../../../../../providers/api-provider';

type Props = {
    approvalVotes: ApprovalVotes[];
}

export default function ApprovalVoteList({ approvalVotes }: Props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [sorted, setSorted] = useState<ApprovalVotes[]>([])

    useEffect(() => {
        if (approvalVotes) {
            const sort = approvalVotes.sort((a, b) => b.index - a.index);
            setSorted(sort);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approvalVotes]);

    const headings = isMobile ? [] : [
        '',
        'Total Bribes (USD)',
        '$/vlDDD',
        'Vote Status',
        'Vote Expiry'
    ];

    const rows = approvalVotes !== undefined ? sorted.map((l, k) => {return {
        item: <Grid container sx={{ alignItems: ['flex-start', 'center'], ml: ['16px', 'unset'] }}>
            <Grid item lg={2.4} xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: ['flex-start', 'center'], mb: ['8px', 'unset'] }}>
                    <TokenIcon address={l.token} />
                    <Tooltip title={l.name} placement="top" arrow>
                        <Typography variant="h4" sx={{ width: '100px' }}>
                            {l.symbol}
                        </Typography>
                    </Tooltip>
                </Box>
            </Grid>
            <Grid item xs={6} sx={{ display: ['flex', 'none'] }}>
                <Typography variant="h4">
                    Total Bribes (USD):
                </Typography>
            </Grid>
            <Grid item lg={2.4} xs={6}>
                <Typography variant="h4" sx={{ textAlign: ['left', 'inherit'] }}>
                    $<DisplayValue2 value={l.totalIncentivesUSD} />
                </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: ['flex', 'none'] }}>
                <Typography variant="h4">
                    $/vlDDD:
                </Typography>
            </Grid>
            <Grid item lg={2.4} xs={6}>
                <Typography variant="h4" sx={{ textAlign: ['left', 'inherit'] }}>
                    $<DisplayPreciseNumber precision={6} value={l.totalIncentivesUSD / l.dddGivenVotes} />
                </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: ['flex', 'none'] }}>
                <Typography variant="h4">
                    Vote Status:
                </Typography>
            </Grid>
            <Grid item lg={2.4} xs={6}>
                <Typography variant="h4" sx={{
                    color: l.epxGivenVotes > l.epsRequiredVotes ? '#4BC789' : 'inherit',
                    fontWeight: 600
                }}>
                    {l.epxGivenVotes > l.epsRequiredVotes ? 'Passed' : l.expired ? 'Failed' : 'Voting'}
                </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: ['flex', 'none'] }}>
                <Typography variant="h4">
                    Vote Expiry:
                </Typography>
            </Grid>
            <Grid item lg={2.4} xs={6}>
                {l.expired ? 
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                        Finished
                    </Typography>
                : <Typography variant="h4" sx={{ color: '#4BC789', fontWeight: 600 }}>
                    <Moment unix format="ddd MMM D, YYYY" tz="UTC">
                        {l.startTime + 86400 * 7}
                    </Moment>
                </Typography>}
            </Grid>
        </Grid>,
        key: k.toString(),
        content: <BribeControls approvalVote={l} />
    }}) : [];

    return (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {rows !== undefined ? <DisplayTableAccordian headings={headings} rows={rows} /> : <>
            <Skeleton variant="rectangular" width={'100%'} height={10} sx={{ my: 2, borderRadius: '4px' }} />
            <Skeleton variant="rectangular" width={'100%'} height={10} sx={{ my: 2, borderRadius: '4px' }} />
        </>}
    </Box>);
}