import React, { useState } from 'react';
import BigNumber from 'bignumber.js';
import { Box, Button, FormControl, FormControlLabel, FormGroup, Switch, Grid } from '@mui/material';
import { useOnboardProvider } from '../../../../../providers/onboard-provider';
import InputNumber from '../../../../../components/Inputs/InputNumber';
import CompleteButton from '../../../../../components/CompleteButton';
import LoadingButton from '../../../../../components/LoadingButton';
import { TxState } from '../../../../../web3/types';
import Info from '../../../../../components/Info';
import Balance from '../../../../../components/Balance';
import { CONFIG } from '../../../../../global';
import { deposit } from '../../../../../web3/LockedEPX/deposit';
import ApproveButton from '../../../../../components/ApproveButton';
import TxModal from '../../../../../components/TxModal';

type Props = {
    tokenSymbol: string;
    tokenBalance: BigNumber;
    tokenAllowance: BigNumber;
    tokenAllowanceBond: BigNumber;
}

export default function ConvertEPX({ tokenSymbol, tokenBalance, tokenAllowance, tokenAllowanceBond }: Props) {
    const { signer, account } = useOnboardProvider();

    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const [amount, setAmount] = useState<BigNumber>();
    const [inputValue, setInputValue] = useState<number | string>('');

    const [bond, setBond] = useState(true);

    const isApproved = tokenAllowance.gt(tokenBalance);
    const isApprovedBonding = tokenAllowanceBond.gt(tokenBalance);

    const handleSubmit = async () => {
        if (signer !== undefined && account !== undefined && account !== null && amount !== undefined) {
            const txFinished = () => {
                setInputValue('');
                setAmount(undefined);
                setTxState('finished');
            }
            await deposit(
                {
                    setTxHash: setCurrentTxHash,
                    setTxState: setTxState,
                    setTxError: () => {},
                    txFinished: txFinished,
                },
                signer,
                account,
                amount,
                bond
            );
        }
    }

    const setMaxAmount = (event: any) => {
        event.preventDefault();
        if (tokenBalance) {
          setInputValue(tokenBalance.toNumber() / 1e18)
          setAmount(tokenBalance);
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBond(event.target.checked);
    };

    return (<>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch color="primary" checked={bond} onChange={handleChange} name="bond" />
                            }
                            label="Convert & Stake"
                        />
                    </FormGroup>
                </FormControl>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <Button disableRipple onClick={setMaxAmount} variant="text">
                    <Balance title={'Wallet balance'} tokenBalance={tokenBalance} tokenSymbol={tokenSymbol} />
                </Button>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Grid container columnSpacing={0.5} rowSpacing={0.5}>
                    <Grid item xs={12} lg={3}>
                        <InputNumber
                            assetSymbol={tokenSymbol}
                            maxAmount={tokenBalance}
                            setRealInput={setAmount}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <ApproveButton isApproved={isApproved && isApprovedBonding} token={CONFIG.EPX} contract={CONFIG.LockedEPX} />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        {txState === 'none' || txState === 'pending' || txState === 'confirm' ? <LoadingButton
                            content={bond ? 'Convert & Stake' : 'Convert'}
                            variant={isApproved ? "blue" : "outlined"}
                            onClick={handleSubmit}
                            disabled={!isApproved || txState === 'pending' || txState === 'confirm' ? true : false || amount === undefined || amount.isZero()}
                            loading={txState === 'pending' ? true : false }
                        /> : <CompleteButton content={'Converted'} />}
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Info>
                            {'Converting EPX to dEPX is irreversible.'}
                        </Info>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <TxModal txState={txState} currentTxHash={currentTxHash} setTxState={setTxState} />
    </>);
}