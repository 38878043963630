import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import Web3 from 'web3';
import { CONFIG } from '../../global';

export interface BlockNumberContextData {
  blockNumber: number;
  blockTimestamp: number;
}

const BlockNumberDataContext = React.createContext({} as BlockNumberContextData);

export function BlockNumberProvider({ children }: PropsWithChildren<{}>) {
  const [blockNumber, setBlockNumber] = useState<number>(-1);
  const [blockTimestamp, setBlockstamp] = useState<number>(-1);

  const load = async () => {
    try {
      const rpc = new Web3(CONFIG.rpc);
      rpc.eth.getBlock('latest')
        .then((result: any) => {
          setBlockstamp(result.timestamp);
          setBlockNumber(result.number);
        })
        .catch((e: any) => console.error(e));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    load();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(load, 10000);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BlockNumberDataContext.Provider
      value={{
        blockNumber,
        blockTimestamp
      }}
    >
      {children}
    </BlockNumberDataContext.Provider>
  );
}

export const useBlockNumberDataContext = () => useContext(BlockNumberDataContext);
