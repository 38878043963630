import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { HashRouter as Router} from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import './index.css';
import theme from './providers/mui-theme-provider';
import Main from './Main';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');

// @ts-ignore
const root = ReactDOMClient.createRoot(container);

root.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <Router>
        <Main />
      </Router>
    </MuiThemeProvider>,
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
