import React, { useState } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import BigNumber from 'bignumber.js';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import { useOnboardProvider } from '../../../../../providers/onboard-provider';
import { initiateUnbondingStream } from '../../../../../web3/BondedDistributor/initiateUnbondingStream';
import DisplayValue from '../../../../../components/DisplayValue';
import { CONFIG } from '../../../../../global';
import { useBlockNumberDataContext } from '../../../../../providers/blocknumber-provider';
import { TxState } from '../../../../../web3/types';
import LoadingButton from '../../../../../components/LoadingButton';
import CompleteButton from '../../../../../components/CompleteButton';
import InputNumber from '../../../../../components/Inputs/InputNumber';
import Info from '../../../../../components/Info';
import Balance from '../../../../../components/Balance';
import TxModal from '../../../../../components/TxModal';

type Props = {
    unbondableBalance: BigNumber;
    bondingStream: BigNumber;
}

export default function Unbond({ unbondableBalance, bondingStream }: Props) {
    const { blockTimestamp } = useBlockNumberDataContext();

    const streamByDate = blockTimestamp + 86400 * 15;

    const { signer, account } = useOnboardProvider();
    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const [amount, setAmount] = useState<BigNumber>();
    const [inputValue, setInputValue] = useState<number | string>('');

    const handleUnbonding = async () => {
        if (signer !== undefined && account !== undefined && account !== null && amount !== undefined && !amount.isZero()) {
            const txFinished = () => {
                setInputValue('');
                setAmount(undefined);
                setTxState('finished');
            }
            await initiateUnbondingStream(
                {
                    setTxHash: setCurrentTxHash,
                    setTxState: setTxState,
                    setTxError: () => {},
                    txFinished: txFinished,
                },
                signer,
                account,
                amount,
            );
        }
    }

    const setMaxAmount = (event: any) => {
        event.preventDefault();
        if (unbondableBalance) {
          setInputValue(unbondableBalance.toNumber() / 1e18)
          setAmount(unbondableBalance);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const message = <>{amount !== undefined && !amount.isZero() ? <Box sx={{ display: 'flex', flexDirection: 'column', my: 1, width: '100%', alignItems: 'center',  justifyContent: 'center' }}>
        <Tooltip
            title={``}
            placement="top"
            arrow
        >
            <Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', my: 1 }}>
                    <Typography variant="body2">
                        You are about to initate an unlocking stream on <DisplayValue value={amount} decimals={1e18} /> {CONFIG.symbol}.
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', my: 1 }}>
                    <Typography variant="body2">
                        It will be streamed over the next 15 days and claimable in its entirety by&nbsp;
                        <Moment unix format="dddd MMM D, YYYY TZ" tz="UTC">{streamByDate}</Moment>.&nbsp;
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', my: 1 }}>
                    <Typography variant="body2">
                        While unlocking the locked tokens do not earn any fees.
                    </Typography>
                </Box>
            </Box>
        </Tooltip>
    </Box> : <></>}</>

    return (<>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <Button disableRipple onClick={setMaxAmount} variant="text">
                    <Balance title={'Unstakeable balance'} tokenBalance={unbondableBalance} tokenSymbol={CONFIG.convertedSymbol} />
                </Button>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Grid container columnSpacing={0.5} rowSpacing={0.5}>
                    <Grid item xs={12} lg={3}>
                        <InputNumber
                            assetSymbol={CONFIG.convertedSymbol}
                            maxAmount={unbondableBalance}
                            setRealInput={setAmount}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                    {txState === 'none' || txState === 'pending' || txState === 'confirm' ? <LoadingButton
                        content={'Unstake'}
                        variant={"blue"}
                        onClick={handleUnbonding}
                        disabled={txState === 'pending' || txState === 'confirm' ? true : false || amount === undefined || amount.isZero()}
                        loading={txState === 'pending' || txState === 'confirm' ? true : false }
                    /> : <CompleteButton content={'Initated Unstaking'} />}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        {!bondingStream.isZero() ?
                            <Info>
                                You have an existing exit stream, if you start a new stream, the existing stream will be added to the new stream.
                            </Info> :
                        <></>}
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <TxModal txState={txState} currentTxHash={currentTxHash} setTxState={setTxState} />
    </>);
}