import React, { useContext, useEffect, useState } from 'react';

export const getNetworkName = (chainId: number) => {
  switch (chainId) {
      case 56:
        return 'BNB Chain'
      case 1337:
        return 'localhost';
      case 31337:
        return 'localhost';
      default:
        return 'Unsupported Network';
  }
}

export interface ConnectedNetworkProviderData {
  chainId: number;
}

const ConnectedNetworkDataContext = React.createContext({} as ConnectedNetworkProviderData);

export function ConnectedNetworkProvider({ children }: React.PropsWithChildren<{}>) {
  const [chainId, setChainId] = useState((global.window as any)?.ethereum === undefined ? 56 : (global.window as any)?.ethereum?.networkVersion);

  (global.window as any)?.ethereum?.on('chainChanged', (_chainId: string) => {
    setChainId(_chainId);
  });

  (global.window as any)?.ethereum?.on('connect', () => {
    setChainId((global.window as any)?.ethereum?.networkVersion);
  });

  useEffect(() => {
    const doAction = async () => {
      try {
        const chainId = await (global.window as any)?.ethereum?.request({ method: 'eth_chainId' });
        if (chainId === undefined) {
          setChainId(56);
        } else {
          setChainId(chainId);
        }
      } catch {
        setChainId(56);
      }
    };
    doAction();
  }, []);

  return (
    <ConnectedNetworkDataContext.Provider
      value={{
        chainId: typeof(chainId)  === 'string' ? parseInt(chainId, 16) : chainId,
      }}
    >
      {children}
    </ConnectedNetworkDataContext.Provider>
  );
}

export const useConnectedNetworkContext = () => useContext(ConnectedNetworkDataContext);
