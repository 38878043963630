import React, { useState } from 'react';
import BigNumber from 'bignumber.js';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { useOnboardProvider } from '../../../../../providers/onboard-provider';
import InputNumber from '../../../../../components/Inputs/InputNumber';
import CompleteButton from '../../../../../components/CompleteButton';
import LoadingButton from '../../../../../components/LoadingButton';
import Balance from '../../../../../components/Balance';
import ApproveButton from '../../../../../components/ApproveButton';
import { CONFIG } from '../../../../../global';
import { TxState, UserLpDeposit } from '../../../../../web3/types';
import { deposit } from '../../../../../web3/LpDepositor/deposit';
import { LpDetails } from '../../../../../providers/api-provider';
import { ReactComponent as LinkLogo } from '../../../../../images/link/logo.svg'; 
import TxModal from '../../../../../components/TxModal';

type Props = {
    lp: LpDetails;
    userLpDeposit: UserLpDeposit;
}

export default function LpDeposit({ userLpDeposit, lp }: Props) {
    const { signer, account } = useOnboardProvider();

    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const [amount, setAmount] = useState<BigNumber>();
    const [inputValue, setInputValue] = useState<number | string>('');

    const isApproved = userLpDeposit.allowance.gt(userLpDeposit.balance);

    const handleSubmit = async () => {
        if (signer !== undefined && account !== undefined && account !== null && amount !== undefined) {
            const txFinished = () => {
                setInputValue('');
                setAmount(undefined);
                setTxState('finished');
            }
            await deposit(
                {
                    setTxHash: setCurrentTxHash,
                    setTxState: setTxState,
                    setTxError: () => {},
                    txFinished: txFinished,
                },
                signer,
                account,
                userLpDeposit.token,
                amount
            );
        }
    }

    const setMaxAmount = (event: any) => {
        event.preventDefault();
        if (userLpDeposit.balance) {
          setInputValue(userLpDeposit.balance.toNumber() / 1e18)
          setAmount(userLpDeposit.balance);
        }
    }

    return (<>
        <Grid container rowSpacing={0.5}>
            <Grid item lg={4} xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'row', mb: '12px' }}>
                    <Typography sx={{ textAlign: 'left', fontSize: '14px', lineHeight: '24px', fontWeight: 700 }}>How to Stake</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: '12px' }}>
                    <Typography sx={{ textAlign: 'left', fontSize: '14px', lineHeight: '24px', fontWeight: 400 }}>
                        1. Deposit liquidity into<br />
                        <Link
                            sx={{
                                color: '#976CF3',
                                '&:hover': { color: '#976CF3' },
                                '& > svg > path': { fill: '#976CF3', fillOpacity: 'unset' }
                            }}
                            target="__blank"
                            rel="noreferrer"
                            href={`https://ellipsis.finance/pool/${lp.pool}`}>
                            {lp.name}
                            <LinkLogo />
                        </Link>&nbsp;
                    </Typography>
                    <Typography sx={{ textAlign: 'left', fontSize: '12px', lineHeight: '24px', fontWeight: 400 }}>You will receive {lp.symbol} LP tokens in return.</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ textAlign: 'left', fontSize: '14px', lineHeight: '24px', fontWeight: 400 }}>2. Then, stake the {lp.symbol} LP tokens here</Typography>
                    <Typography sx={{ textAlign: 'left', fontSize: '12px', lineHeight: '24px', fontWeight: 400  }}>You'll begin earnings rewards on the Rewards tab.</Typography>
                </Box>
            </Grid>
            <Grid item lg={8} xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                    <Typography sx={{ textAlign: 'left', fontSize: '14px', lineHeight: '24px', fontWeight: 700 }}>
                        Stake {lp.symbol} LP tokens here
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                    <Button disableRipple onClick={setMaxAmount} variant="text" sx={{ p: 0 }}>
                        <Balance title={'Wallet balance'} tokenBalance={userLpDeposit.balance} tokenSymbol={userLpDeposit.symbol} />
                    </Button>
                </Box>
                <Box>
                    <Grid container columnSpacing={0.5} rowSpacing={0.5}>
                        <Grid item xs={12} lg={3.5}>
                            <InputNumber
                                assetSymbol={userLpDeposit.symbol}
                                maxAmount={userLpDeposit.balance}
                                setRealInput={setAmount}
                                inputValue={inputValue}
                                setInputValue={setInputValue}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <ApproveButton isApproved={isApproved} token={userLpDeposit.token} contract={CONFIG.DDDLpDepositor} />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            {txState === 'none' || txState === 'pending' || txState === 'confirm' ? <LoadingButton
                                content={'Stake'}
                                variant={isApproved ? "blue" : "outlined"}
                                onClick={handleSubmit}
                                disabled={!isApproved || txState === 'pending' ? true : false || amount === undefined || amount.isZero()}
                                loading={txState === 'pending' ? true : false }
                            /> : <CompleteButton content={'Staked LP tokens'} />}
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
        <TxModal txState={txState} currentTxHash={currentTxHash} setTxState={setTxState} />
    </>);
}