import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { UserLpDeposit } from '../../../../../web3/types';
import { DisplayValue2 } from '../../../../../components/DisplayValue';
import TokenIcon from '../../../../../components/TokenIcon';
import { CONFIG } from '../../../../../global';
import ClaimExtraRewards from '../ClaimExtraRewards';
import ClaimPoolRewards from '../ClaimPoolRewards';
import { DisplayLargeNumber2 } from '../../../../../components/DisplayLargeNumber';

type Props = {
    userLpDeposit: UserLpDeposit;
    withBonus: boolean;
}

type PoolRewardsProps = {
    token: string;
    rewardName: string;
    claimable: number;
    claimableUSD: number;
}

function PoolRewards({ token, rewardName, claimable, claimableUSD }: PoolRewardsProps) {
    return (<Grid container sx={{
            alignItems: 'center',
            paddingY: '16px',
            paddingX: ['8px', '0'],
            justifyContent: ['space-between', 'unset']
        }} columnSpacing={1}>
        <Grid item lg={2.4}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <TokenIcon address={token} />
                <Typography sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '18px', letterSpacing: '-0.025em', colour: '#002237', width: '50px' }}>
                    {rewardName}
                </Typography>
            </Box>
        </Grid>
        <Grid item lg={2.4}>
            <Typography sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '20px', colour: '#002237' }}>
                $<DisplayValue2 value={claimableUSD} precision={6} />
            </Typography>
        </Grid>
        <Grid item lg={2.4}>
            <Typography sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '20px', colour: '#002237' }}>
                <DisplayValue2 value={claimable} precision={6} />
            </Typography>
        </Grid>
        {rewardName === 'DDD' ? 
            <Grid item lg={2.4} xs={12} sx={{ paddingTop: ['8px', '0'] }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: 'fit-content',
                    margin: ['unset', 'auto'],
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '3px 8px',
                    background: 'rgba(151, 108, 243, 0.2)',
                    borderRadius: '4px',
                }}>
                    <Typography sx={{ lineHeight: '13px', color: '#002237', fontSize: '10px', fontWeight: 400, display: ['block', 'none'] }}>
                        CLAIM WITH BONUS:&nbsp;
                    </Typography>
                    <Typography sx={{ fontSize: '16px', fontWeight: 700, lineHeight: '20px', colour: '#002237' }}>
                        <DisplayLargeNumber2 value={claimable * 3} />
                    </Typography>&nbsp;
                    <Typography sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '15px', colour: '#002237', opacity: 0.8 }}>
                        $<DisplayValue2 value={claimableUSD * 3} precision={6} />
                    </Typography>
                </Box>
            </Grid> : <Grid item lg={2.4} sx={{ display: ['none', 'flex'] }}></Grid>
        }
        <Grid item lg={2.4} sx={{ display: ['none', 'flex'] }}>
        </Grid>
    </Grid>);
}

function Headers() {
    return (<Grid container sx={{
            alignItems: 'center',
            paddingTop: '32px',
            paddingX: ['8px', '0'],
            justifyContent: ['space-between', 'unset']
        }} columnSpacing={1}>
        <Grid item lg={2.4}>
            <Typography sx={{ lineHeight: '13px', color: '#002237', fontSize: '10px', fontWeight: 400 }}>
                ASSET
            </Typography>
        </Grid>
        <Grid item lg={2.4}>
            <Typography sx={{ lineHeight: '13px', color: '#002237', fontSize: '10px', fontWeight: 400 }}>
                CLAIMABLE
            </Typography>
        </Grid>
        <Grid item lg={2.4}>
            <Typography sx={{ lineHeight: '13px', color: '#002237', fontSize: '10px', fontWeight: 400 }}>
                AMOUNT
            </Typography>
        </Grid>
        <Grid item lg={2.4} sx={{ display: ['none', 'block'] }}>
            <Typography sx={{ lineHeight: '13px', color: '#002237', fontSize: '10px', fontWeight: 400 }}>
                CLAIM WITH BONUS
            </Typography>
        </Grid>
        <Grid item lg={2.4} sx={{ display: ['none', 'flex'] }}>
        </Grid>
    </Grid>);
}

function PoolClaimableRewards({ userLpDeposit, withBonus }: Props) {
    return (<Box sx={{ display: 'flex', background: '#ECF2F4', paddingX: ['8px', 'none'] }}>
        <Grid container columnSpacing={0.5}>
            <Grid item xs={12} lg={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Typography sx={{ lineHeight: '140%', color: '#1A2037', fontSize: '14px', ml: ['0', '64px'] }}>
                    At the time of claim, you may optionally convert your earned EPX into staked dEPX to get a 3x bonus on your DDD.
                </Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
                <Headers />
            </Grid>
            <Grid item xs={12} lg={12}>
                <PoolRewards
                    token={CONFIG.Token}
                    rewardName={'DDD'}
                    claimable={userLpDeposit.dddClaimable}
                    claimableUSD={userLpDeposit.dddClaimableUSD}
                />
            </Grid>
            <Grid item xs={12} lg={12}>
                <PoolRewards
                    token={CONFIG.EPX}
                    rewardName={'EPX'}
                    claimable={userLpDeposit.epxClaimable}
                    claimableUSD={userLpDeposit.epxClaimableUSD}
                />
            </Grid>
            {userLpDeposit.extraClaimable.map((ec, key) => <Grid key={key} item xs={12} lg={12}>
                <PoolRewards
                    token={ec.token}
                    rewardName={ec.symbol}
                    claimable={ec.amount}
                    claimableUSD={ec.amountUSD}
                />
            </Grid>)}
            <Grid item lg={.7} sx={{ display: ['none', 'flex'] }}></Grid>
            <Grid item lg={2.4} xs={6}>
                <ClaimPoolRewards earning={userLpDeposit} withBonus={withBonus} />
            </Grid>
            <Grid item lg={2.4} xs={6}>
                {userLpDeposit.extraClaimable.length > 0 ? <ClaimExtraRewards earning={userLpDeposit} /> : <></>}
            </Grid>
        </Grid>
    </Box>);
}

export default PoolClaimableRewards;
