
import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, Link, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import Multicall from '@dopex-io/web3-multicall';
import { AbiItem } from 'web3-utils';
import LocksList from './components/LocksList';
import Lock from './components/Lock';
import TabsPanel from '../../../components/TabsPanel';
import ClaimExitStream from './components/ClaimExitStream';
import CardTitle from '../../../components/CardTitle';
import StatsTable from '../../../components/StatsTable';
import ERC20Abi from '../../../abi/ERC20.json';
import DDDLockerAbi from '../../../abi/DDDLocker.json';
import TokenApprovalIncentivesAbi from '../../../abi/TokenApprovalIncentives.json';
import DDDIncentivesDistributorAbi from '../../../abi/DDDIncentivesDistributor.json';
import { APPROVAL_VOTE_BIRBS, CONFIG } from '../../../global';
import { ApprovalVotes, ApprovalVotesIncentivesInfo, LpDetails, LpToTokenInfos, TokenInfos, useApiDataContext } from '../../../providers/api-provider';
import { useOnboardProvider } from '../../../providers/onboard-provider';
import { FeeUserClaimable, RewardsFromApprovalVote, UserLock } from '../../../web3/types';
import ClaimableRewards from './components/ClaimableRewards';
import ClaimableBribes from './components/ClaimableBribes';
import TopStatsTable from '../../../components/TopStatsTable';
import ClaimableRewardsFromApprovalVote from './components/ClaimableRewardFromApprovalVote';

const loadPoolIncentiveVoteData = async (user: string, tokenApporvalVotes: ApprovalVotes[], incentiveTokens: ApprovalVotesIncentivesInfo) => {
    const web3 = new Web3(CONFIG.rpc);

    const multicall = new Multicall({
        multicallAddress: CONFIG.multicall,
        provider: CONFIG.rpc,
    });

    const approvalIncentives = new web3.eth.Contract(TokenApprovalIncentivesAbi as AbiItem[], CONFIG.TokenApprovalIncentives);

    let userDataCalls = [];

    for (let i = 0; i < tokenApporvalVotes.length; i++) {
        userDataCalls.push(approvalIncentives.methods.claimableIncentives(tokenApporvalVotes[i].index, user));
    }

    const userData = await multicall.aggregate(userDataCalls);

    let rewardsFromApprovalVote = [];

    for (let i = 0; i < tokenApporvalVotes.length; i++) {
        let rewards = [];
        for (let j = 0; j < userData[i].length; j++) {
            const incentiveData = userData[i][j];
            if (incentiveTokens[incentiveData.token] !== undefined) {
                const amount = new BigNumber(incentiveData.amount).dividedBy(10 ** incentiveTokens[incentiveData.token].decimals).toNumber();
                const amountUSD = amount * incentiveTokens[incentiveData.token].price;
                rewards.push({
                    token: incentiveData.token,
                    name: incentiveTokens[incentiveData.token].name,
                    symbol: incentiveTokens[incentiveData.token].symbol,
                    amount: amount,
                    amountUSD: amountUSD
                });
            }
        }
        if (rewards.length > 0) {
            rewardsFromApprovalVote.push({
                index: tokenApporvalVotes[i].index,
                token: tokenApporvalVotes[i].token,
                pool: tokenApporvalVotes[i].pool,
                name: tokenApporvalVotes[i].name,
                symbol: tokenApporvalVotes[i].symbol,
                expired: tokenApporvalVotes[i].expired,
                passed: tokenApporvalVotes[i].dddGivenVotes > tokenApporvalVotes[i].dddRequiredVotes,
                rewards: rewards
            });
        }
    }

    return { rewardsFromApprovalVote: rewardsFromApprovalVote };
}

const loadRewardsData = async (user: string, lps: LpDetails[], incentiveTokens: LpToTokenInfos, feeToken: TokenInfos) => {
    console.log(`loading claimable ddd data for ${user}`);
    const web3 = new Web3(CONFIG.rpc);

    const multicall = new Multicall({
        multicallAddress: CONFIG.multicall,
        provider: CONFIG.rpc,
    });

    const dddIncentivesDistributor = new web3.eth.Contract(DDDIncentivesDistributorAbi as AbiItem[], CONFIG.DDDIncentivesDistributor)

    const lpAddresses = lps.map((lp) => lp.token);
    lpAddresses.push('0x0000000000000000000000000000000000000000');

    const userDataCalls = [];

    let allFeeTokens = [];

    for (let i = 0; i < lpAddresses.length; i++) {
        if (incentiveTokens[lpAddresses[i]]) {
            const feeTokenAddress = incentiveTokens[lpAddresses[i]].map((ft) => ft.address);
            allFeeTokens.push(feeTokenAddress);
            userDataCalls.push(dddIncentivesDistributor.methods.claimable(user, lpAddresses[i], feeTokenAddress));
        }
    }

    const userData = await multicall.aggregate(userDataCalls);

    let shitkoins = [];
    let rewards = [];
    let earningsUSD = 0;

    for (let i = 0; i < lpAddresses.length; i++) {
        const userFees = userData[i];
        if (allFeeTokens.length > 0) {
            const userFeeTokens = allFeeTokens[i];
            if (userFees.length > 0) {
                for (let j = 0; j < userFees.length; j++) {
                    if (userFeeTokens[j] === CONFIG.LockedEPX && lpAddresses[i] === '0x0000000000000000000000000000000000000000') {
                        const amount = new BigNumber(userFees[j]).dividedBy(10 ** feeToken[userFeeTokens[j]].decimals).toNumber()
                        const amountUSD = amount * feeToken[userFeeTokens[j]].price;
                        rewards.push({
                            lpToken: lpAddresses[i],
                            lpSymbol: 'dEPX Farmed',
                            feeToken: userFeeTokens[j],
                            feeSymbol: feeToken[userFeeTokens[j]].symbol,
                            claimable: amount,
                            claimableUSD: amountUSD
                        });
                        earningsUSD += amountUSD;
                    } else {
                        const amount = new BigNumber(userFees[j]).dividedBy(10 ** feeToken[userFeeTokens[j]].decimals).toNumber()
                        const amountUSD = new BigNumber(amount).multipliedBy(feeToken[userFeeTokens[j]].price).toNumber()
                        shitkoins.push({
                            lpToken: lpAddresses[i],
                            lpSymbol: lpAddresses[i] === '0x0000000000000000000000000000000000000000' ? 'All Tokens' :  lps[i].symbol,
                            feeToken: userFeeTokens[j],
                            feeSymbol: feeToken[userFeeTokens[j]].symbol,
                            claimable: amount,
                            claimableUSD: amountUSD
                        });
                        earningsUSD += amountUSD;
                    }
                }
            }
        }
    }

    return { shitkoins: shitkoins, rewards: rewards, earningsUSD: earningsUSD };
}

const loadData = async (user: string, startTime: number, week: number) => {
    console.log(`loading lock data for ${user}`);
    const web3 = new Web3(CONFIG.rpc);

    const multicall = new Multicall({
        multicallAddress: CONFIG.multicall,
        provider: CONFIG.rpc,
    });

    const ddd = new web3.eth.Contract(ERC20Abi as AbiItem[], CONFIG.Token)
    const dddLocker = new web3.eth.Contract(DDDLockerAbi as AbiItem[], CONFIG.DDDLocker)

    const userCalls = [
        ddd.methods.balanceOf(user),
        ddd.methods.allowance(user, CONFIG.DDDLocker),
        dddLocker.methods.userBalance(user),
        dddLocker.methods.streamableBalance(user),
        dddLocker.methods.claimableExitStreamBalance(user),
        dddLocker.methods.getActiveUserLocks(user),
        dddLocker.methods.exitStream(user),
    ];

    const [balance, allowance, totalLocked, streamableBalance, claimableExitStream, locks, exitStream] = await multicall.aggregate(userCalls);

    const currentWeekUnix = startTime + (week * (86400 * 7));

    let userLocks: UserLock[] = [];
    for (let i = 0; i < locks.length; i++) {
        const weeksLeft = new BigNumber(locks[i][0]).toNumber();
        const amount = new BigNumber(locks[i][1]);
        userLocks.push({
            amount: amount,
            weeksLeft: weeksLeft,
            expiry: currentWeekUnix + (weeksLeft * (86400 * 7)),
        })
    }

    if (new BigNumber(streamableBalance).dividedBy(1e18).toNumber() > 0) {
        userLocks.push({
            amount: new BigNumber(streamableBalance),
            weeksLeft: 0,
            expiry: 0
        });
    }

    return {
        balance: new BigNumber(balance),
        allowance: new BigNumber(allowance),
        totalLocked: new BigNumber(totalLocked).dividedBy(1e18).toNumber(),
        streamableBalance: new BigNumber(streamableBalance).dividedBy(1e18).toNumber(),
        claimableExitStream: new BigNumber(claimableExitStream).dividedBy(1e18).toNumber(),
        userLocks: userLocks,
        totalClaimableFromStream: new BigNumber(exitStream[1])
            .dividedBy(1e18).minus(
                new BigNumber(exitStream[2]).dividedBy(1e18)
            ).toNumber(),
        exitStreamEndTime: new BigNumber(exitStream[0]).toNumber() + (86400 * 7)
    };
}

function LockDDD() {
    const {
        tokenStats,
        lps,
        lpToTokenInfo,
        tokenInfo,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        lastWeekTotalBribesApr,
        lastWeekTotalBribes,
        lastWeekTotalRewardsApr,
        lastWeekTotalRewards,
        dddPrice,
        approvalVotesData
    } = useApiDataContext();

    const { account } = useOnboardProvider();

    const [balance, setBalance] = useState<BigNumber>(new BigNumber(0));
    const [allowance, setAllowance] = useState<BigNumber>(new BigNumber(0));
    const [totalLocked, setTotalLocked] = useState<number>(0);
    const [streamableBalance, setStreamableBalance] = useState<number>(0);
    const [claimableExitStream, setClaimableExitStream] = useState<number>(0);
    const [userLocks, setUserLocks] = useState<UserLock[]>([]);
    const [totalClaimableFromStream, setTotalClaimableFromStream] = useState<number>(0);
    const [exitStreamEndTime, setExitStreamEndTime] = useState<number>(0);

    const [shitkoins, setShitkoins] = useState<FeeUserClaimable[]>([]);
    const [rewards, setRewards] = useState<FeeUserClaimable[]>([]);
    const [earningsUSD, setEarningsUSD] = useState<number>(0);

    const [rewardsFromApprovalVote, setRewardsFromApprovalVote] = useState<RewardsFromApprovalVote[]>([]);

    const doAction = async (
        acc: string | undefined | null,
        startTime: number,
        week: number,
        _lps: LpDetails[] | undefined,
        lpToTokenInfo: LpToTokenInfos,
        tokenInfo: TokenInfos,
    ) => {
        if (acc !== undefined && acc != null && _lps !== undefined) {
            try {
                const {
                    balance,
                    allowance,
                    totalLocked,
                    streamableBalance,
                    claimableExitStream,
                    userLocks,
                    totalClaimableFromStream,
                    exitStreamEndTime
                } = await loadData(acc, startTime, week);
                setBalance(balance);
                setAllowance(allowance);
                setTotalLocked(totalLocked);
                setStreamableBalance(streamableBalance);
                setClaimableExitStream(claimableExitStream);
                setUserLocks(userLocks);
                setTotalClaimableFromStream(totalClaimableFromStream);
                setExitStreamEndTime(exitStreamEndTime);
                const { shitkoins, rewards, earningsUSD } = await loadRewardsData(acc, _lps, lpToTokenInfo, tokenInfo);
                setRewards(rewards);
                setShitkoins(shitkoins);
                setEarningsUSD(earningsUSD);
                if (APPROVAL_VOTE_BIRBS) {
                    const { rewardsFromApprovalVote } = await loadPoolIncentiveVoteData(acc, approvalVotesData.tokenApprovalVotes, approvalVotesData.tokenIncentiveInfo)
                    setRewardsFromApprovalVote(rewardsFromApprovalVote);
                }
            } catch (e) {
                console.error(e);
            }
        }
    }

    useEffect(() => {
        doAction(account, tokenStats.startTime, tokenStats.currentWeek, lps, lpToTokenInfo, tokenInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    useEffect(() => {
        const interval = setInterval(() => doAction(account, tokenStats.startTime, tokenStats.currentWeek, lps, lpToTokenInfo, tokenInfo), 5000);
        return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    const stats1 = [
        {
            title: 'Your Locked DDD',
            value: totalLocked * dddPrice,
            compact: true,
            width: '50%',
            symbolFront: '$',
            subValue: totalLocked,
            subSymbolBack: ' DDD',
            subCompact: true,
        },
        {
            title: 'Claimable',
            value: earningsUSD,
            compact: true,
            width: '50%',
            symbolFront: '$',
            subSymbolBack: 'USD',
        },
    ];

    const stats2 = [
        {
            title: 'TVL',
            value: tokenStats.dddSupplyLockedUSD,
            compact: true,
            width: '50%',
            symbolFront: '$'
        },
        {
            title: 'Rewards vAPR',
            value: lastWeekTotalRewardsApr,
            compact: false,
            width: '50%',
            symbolBack: '%'
        },
        {
            title: 'Total bribes & rewards',
            value: lastWeekTotalBribes + lastWeekTotalRewards,
            compact: true,
            width: 'fit-content',
            symbolFront: '$'
        },
    ];


    const tabsWithRewards = [
        {
            paddingStr: '16px 0px',
            title: <>Rewards & Bribes</>,
            icon: <></>,
            component: <>
                <Grid container>
                    {earningsUSD > 0 ? <>
                        <Grid item lg={12}>
                            <ClaimableRewards earnings={rewards} earningsTotalUSD={earningsUSD} />
                        </Grid>
                        <Grid item lg={12}>
                            <ClaimableBribes earnings={shitkoins} earningsTotalUSD={earningsUSD} />
                        </Grid>
                        {APPROVAL_VOTE_BIRBS ? <Grid item lg={12}>
                            <ClaimableRewardsFromApprovalVote rewardsFromApprovalVote={rewardsFromApprovalVote} />
                        </Grid>: <></>}
                    </> : <Grid item lg={12}>
                            <Typography variant='h3' sx={{ p: 2 }}>Lock DDD to earn bribes and dEPX.</Typography>
                        </Grid>
                    }
                </Grid>
            </>
        },
        {
            paddingStr: '0px 0px',
            title: <>Lock</>,
            icon: <></>,
            component: <Box sx={{ background: 'linear-gradient(262.93deg, #00BFFC -33.57%, #1A2037 18.4%, #1A2037 81.38%, #976CF3 122.67%);' }}>
                <Lock tokenBalance={balance} tokenAllowance={allowance} />
            </Box>,
        },
        {
            title: <>Your Locks</>,
            icon: <></>,
            component: <>
                {claimableExitStream > 0 ? <Box sx={{ marginBottom: '16px' }}>
                    <ClaimExitStream
                        currentClaiamble={claimableExitStream}
                        totalClaimable={totalClaimableFromStream}
                        exitStreamEndTime={exitStreamEndTime}
                    />
                </Box> : <></>}
                <LocksList locks={userLocks} streamableBalance={streamableBalance} />
            </>
        },
    ];

    const tabsWithoutRewards = [
        {
            paddingStr: '0px 0px',
            title: <>Lock</>,
            icon: <></>,
            component: <Box sx={{ background: 'linear-gradient(262.93deg, #00BFFC -33.57%, #1A2037 18.4%, #1A2037 81.38%, #976CF3 122.67%);' }}>
                <Lock tokenBalance={balance} tokenAllowance={allowance} />
            </Box>,
        },
        {
            title: <>Your Locks</>,
            icon: <></>,
            component: <>
                {claimableExitStream > 0 ? <Box sx={{ marginBottom: '16px' }}>
                    <ClaimExitStream
                        currentClaiamble={claimableExitStream}
                        totalClaimable={totalClaimableFromStream}
                        exitStreamEndTime={exitStreamEndTime}
                    />
                </Box> : <></>}
                <LocksList locks={userLocks} streamableBalance={streamableBalance} />
            </>
        },
        {
            paddingStr: '16px 0px',
            title: <>Rewards & Bribes</>,
            icon: <></>,
            component: <>
                <Grid container>
                    {earningsUSD > 0 ? <>
                        <Grid item lg={12}>
                            <ClaimableRewards earnings={rewards} earningsTotalUSD={earningsUSD} />
                        </Grid>
                        <Grid item lg={12}>
                            <ClaimableBribes earnings={shitkoins} earningsTotalUSD={earningsUSD} />
                        </Grid>
                        {APPROVAL_VOTE_BIRBS ? <Grid item lg={12}>
                            <ClaimableRewardsFromApprovalVote rewardsFromApprovalVote={rewardsFromApprovalVote} />
                        </Grid>: <></>}
                    </> : <Grid item lg={12}>
                            <Typography variant='h3' sx={{ p: 2 }}>Lock DDD to earn bribes and dEPX.</Typography>
                        </Grid>
                    }
                </Grid>
            </>
        },
    ];

    return (<Card>
        <CardContent>
            <Box sx={{
                p: '24px 24px',
                background: 'linear-gradient(90deg, #966DF3 0%, #0CB9FC 100%);'
            }}>
                <Box sx={{ display: 'flex', flexDirection: ['column', 'row'], justifyContent: 'space-between', alignItems: ['unset', 'center'] }}>
                    <CardTitle title={'Lock DDD'} />
                    <TopStatsTable stats={stats2} />
                </Box>
            </Box>
            <Box sx={{ p: '24px 24px' }}>
                <Grid item xs={12} lg={12}>
                    <Grid container columnSpacing={2} rowSpacing={0.5}>
                        <Grid item xs={12} lg={5}>
                            <StatsTable stats={stats1} />
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: 400, lineHeight: '140%', color: '#1A2037', mb: '20px' }}>
                                Lock DDD to gain voting power over which pools receive EPX emissions as well as bribes and 2.5% of EPX (as dEPX) earned by the DotDot protocol.&nbsp;
                                <Link
                                    target="__blank"
                                    rel="noreferrer"
                                    href="https://docs.dotdot.finance/about/ddd-token"
                                >
                                        Learn more about locking
                                </Link>
                            </Typography>
                            <Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: 700, lineHeight: '140%', color: '#1A2037' }}>
                                DDD locks start earning from the following epoch (Monday midnight UTC).
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Grid container>
                <Grid item xs={12} lg={12}>
                    <TabsPanel tabs={earningsUSD > 0 ? tabsWithRewards : tabsWithoutRewards} /> 
                </Grid>
            </Grid>
        </CardContent>
    </Card>);
}

export default LockDDD;
