import React from 'react';
import { Box } from '@mui/material';
import ModalHandler from '../../../../components/ModalHandler';
import HistoricalVotesHandler from '../HistoricalVotesHandler';

function HistoricalVotesModal() {
    return (<Box sx={{ '& > button': { width: '100%', fontSize: '18px', color: '#976CF3', '&:hover': { color: '#976CF3' } } }}>
        <ModalHandler headerBg='linear-gradient(90deg, #966DF3 0%, #0CB9FC 100%);' width={['350px', '850px']} buttonType='text' buttonTitle={'View vote history'} modalTitle={' '}>
            <HistoricalVotesHandler />
        </ModalHandler>
    </Box>);
}

export default HistoricalVotesModal;
