import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material';

export interface TabItem {
  title: React.ReactNode;
  icon: React.ReactElement;
  component: React.ReactNode;
  paddingStr?: string | undefined;
}

export interface TabsPanelProps {
  tabs: Array<TabItem>;
  sx?: SxProps<Theme> | undefined;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  paddingStr?: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, paddingStr = '32px 28px', ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ background: '#ECF2F4', padding: paddingStr }}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabsPanel({ tabs, sx }: TabsPanelProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs 
        sx={sx === undefined ? {
          '.MuiTabs-flexContainer': {
            flexDirection: ['column', 'row'],
            border: 'unset',
            '& > button': {
              minHeight: 'unset',
              maxWidth: ['unset', 'inherit'],
            },
          },
          '& > .MuiTabs-scroller > span': {
          }
        } : sx} 
        value={value} onChange={handleChange} aria-label="tabs panel">
          {tabs.map((t, i) => (
            <Tab
              disableRipple
              icon={t.icon}
              iconPosition="end"
              label={t.title}
              {...a11yProps(i)}
              key={i}
            />
          ))}
      </Tabs>
      {tabs.map((t, i) => (
        <TabPanel value={value} index={i} key={i} paddingStr={t.paddingStr}>
          {t.component}
        </TabPanel>
      ))}
    </Box>
  );
}
