import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import { Box, Skeleton, TableCell, Typography } from '@mui/material';
import DisplayValue, { DisplayValue2 } from '../../../../../components/DisplayValue';
import { useBlockNumberDataContext } from '../../../../../providers/blocknumber-provider';
import { UserLock } from '../../../../../web3/types';
import DisplayTable from '../../../../../components/DisplayTable';
import RelockModal from '../RelockModal';
import InitStream from '../InitStream';
import ModalHandler from '../../../../../components/ModalHandler';

type Props = {
    locks: Array<UserLock> | undefined;
    streamableBalance: number;
}

export default function Locks({ locks, streamableBalance }: Props) {
    const { blockTimestamp } = useBlockNumberDataContext();

    const [sortedLocks, setSortedLocks] = useState<any[]>([])

    useEffect(() => {
        if (locks) {
            const sortedLocks = locks.sort((a, b) => a.expiry - b.expiry);
            setSortedLocks(sortedLocks);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locks]);

    const headings = ['Amount', 'Expiry', 'Weeks', ''];

    const rows = sortedLocks.map((l, k) => {return {
        item: <>
            <TableCell>
                <Typography variant="h4">
                    <DisplayValue value={l.amount} decimals={1e18} />
                </Typography>
            </TableCell>
            <TableCell>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', }}>
                    {l.expiry > blockTimestamp ?
                        <Typography variant="h4" sx={{ color: '#4BC789', fontWeight: 600 }}>
                            <Moment unix format="ddd MMM D, YYYY" tz="UTC">
                                {l.expiry}
                            </Moment>
                        </Typography>
                    : <Typography variant="h4" sx={{ color: '#FF4E93', fontWeight: 600 }}>
                        Expired
                    </Typography>}
                </Box>
            </TableCell>
            <TableCell>
                <Typography variant="h4">
                    <DisplayValue2 value={l.weeksLeft} />
                </Typography>
            </TableCell>
            <TableCell>
                {l.expiry > blockTimestamp ?
                    <RelockModal lock={l} />
                : <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <ModalHandler buttonType="blue" buttonTitle={'Initiate Claim'}>
                        <InitStream expiredLocksBalance={streamableBalance} />
                    </ModalHandler>
                </Box>}
            </TableCell>
        </>,
        key: k.toString()
    }});

    return (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {locks !== undefined ? <DisplayTable headings={headings} rows={rows} /> : <>
            <Skeleton variant="rectangular" width={'100%'} height={10} sx={{ my: 2, borderRadius: '4px' }} />
            <Skeleton variant="rectangular" width={'100%'} height={10} sx={{ my: 2, borderRadius: '4px' }} />
        </>}
    </Box>);
}