import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import DDDVoterAbi from '../../abi/DDDVoter.json';
import { CONFIG } from '../../global';
import { calcGas } from '../utils';

export async function vote(
    web3: Web3,
    account: string,
    tokens: string[],
    votes: string[],
): Promise<any> {
    const c = new web3.eth.Contract(DDDVoterAbi as AbiItem[], CONFIG.DDDVoter);
    const est = await c.methods.vote(tokens, votes).estimateGas({'from': account});
    return c.methods.vote(tokens, votes).send({'from': account, gas: calcGas(est), maxFeePerGas: 3 * 1e9, maxPriorityFeePerGas: 3 * 1e9 });
}