import React, { useEffect, useState } from 'react';
import 'moment-timezone';
import { Box, Skeleton, TableCell, Tooltip, Typography } from '@mui/material';
import { DisplayValue2 } from '../../../../../components/DisplayValue';
import DisplayTable from '../../../../../components/DisplayTable';
import { Incentive } from '../../../../../providers/api-provider';
import TokenIcon from '../../../../../components/TokenIcon';

type Props = {
    incentives: Incentive[];
}

export default function BribesList({ incentives }: Props) {
    const [sortedBribes, setSortedBribes] = useState<Incentive[]>([])

    useEffect(() => {
        if (incentives) {
            const sortedLocks = incentives
            .filter((a) => a.weeklyAmounts > 0)
            .sort((a, b) => b.weeklyAmountsUSD - a.weeklyAmountsUSD);
            setSortedBribes(sortedLocks);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incentives]);

    const headings = ['Name', 'Amount', 'USD', 'vAPR'];

    const rows = sortedBribes.map((l, k) => {return {
        item: <>
            <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                    <TokenIcon address={l.address} />
                    <Tooltip title={l.name} placement="top" arrow>
                        <Typography variant="h4" sx={{ ml: 0.5 }}>
                            {l.symbol}
                        </Typography>
                    </Tooltip>
                </Box>
            </TableCell>
            <TableCell>
                <Typography variant="h4">
                    <DisplayValue2 value={l.weeklyAmounts} />
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="h4">
                    $<DisplayValue2 value={l.weeklyAmountsUSD} />
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="h4">
                    <DisplayValue2 value={l.apr} />%
                </Typography>
            </TableCell>
        </>,
        key: k.toString()
    }});

    return (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {incentives !== undefined ? <DisplayTable headings={headings} rows={rows} /> : <>
            <Skeleton variant="rectangular" width={'100%'} height={10} sx={{ my: 2, borderRadius: '4px' }} />
            <Skeleton variant="rectangular" width={'100%'} height={10} sx={{ my: 2, borderRadius: '4px' }} />
        </>}
    </Box>);
}