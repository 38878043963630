import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import ERC20Abi from '../abi/ERC20.json';
import { TxActions } from './types';
import { calcGas, maxUint256 } from './utils';

export async function approveTokenSpend(
    actions: TxActions,
    account: string,
    web3: Web3,
    token: string,
    spender: string,
): Promise<any> {
    actions.setTxState('confirm');
    const c = new web3.eth.Contract(ERC20Abi as AbiItem[], token);
    const est = await c.methods.approve(spender, maxUint256()).estimateGas({'from': account});
    c.methods.approve(spender, maxUint256()).send({'from': account, gas: calcGas(est), maxFeePerGas: 3 * 1e9, maxPriorityFeePerGas: 3 * 1e9 })
    .once('transactionHash', (hash: any) => {
        actions.setTxState('pending');
        actions.setTxHash(hash);
    }).once('error', (error: any) => {
        console.error(error)
        actions.setTxError(error);
        actions.setTxState('none');
    }).once('confirmation', (conf: any, receipt: any) => {
    }).then((receipt: any) => {
        actions.txFinished();
    }).finally(() => {
        actions.txFinished();
    });
}