
import React from 'react';
import TabsPanel from '../../../../../components/TabsPanel';
import LpDeposit from '../LpDeposit';
import LpWithdraw from '../LpWithdraw';
import { UserLpDeposit } from '../../../../../web3/types';
import ContractList from '../../../../../components/ContractList';
import { CONFIG } from '../../../../../global';
import PoolClaimableRewards from '../PoolClaimableRewards';
import { LpDetails } from '../../../../../providers/api-provider';

type Props = {
    lp: LpDetails;
    userLpDeposit: UserLpDeposit;
    withBonus: boolean;
}

function LpControls({ withBonus, userLpDeposit, lp }: Props) {

    const contracts = [
        {
            name: 'LpDepositor',
            address: CONFIG.DDDLpDepositor
        },
        {
            name: 'EPS LP Token',
            address: lp.token
        }
    ];

    const tabsWithRewards = [
        {
            paddingStr: '16px 0px',
            title: <>Rewards</>,
            icon: <></>,
            component: <PoolClaimableRewards userLpDeposit={userLpDeposit} withBonus={withBonus} />
        },
        {
            title: <>Stake</>,
            icon: <></>,
            component: <LpDeposit userLpDeposit={userLpDeposit} lp={lp} />
        },
        {
            title: <>Unstake</>,
            icon: <></>,
            component: <LpWithdraw userLpDeposit={userLpDeposit} />
        },
        {
            title: <>Info</>,
            icon: <></>,
            component: <ContractList contracts={contracts} />
        },
    ];

    const tabsWithoutRewards = [
        {
            title: <>Stake</>,
            icon: <></>,
            component: <LpDeposit userLpDeposit={userLpDeposit} lp={lp} />
        },
        {
            title: <>Unstake</>,
            icon: <></>,
            component: <LpWithdraw userLpDeposit={userLpDeposit} />
        },
        {
            paddingStr: '16px 0px',
            title: <>Rewards</>,
            icon: <></>,
            component: <PoolClaimableRewards userLpDeposit={userLpDeposit} withBonus={withBonus} />
        },
        {
            title: <>Info</>,
            icon: <></>,
            component: <ContractList contracts={contracts} />
        },
    ];

    return (<TabsPanel
        sx={{
            '.MuiTabs-flexContainer': {
                flexDirection: ['column', 'row'],
                marginTop: '8px',
                marginBottom: '8px',
                border: 'unset',
                '& > button': {
                    minHeight: 'unset',
                    maxWidth: ['unset', 'inherit'],
                },
            },
            '& > .MuiTabs-scroller > span': {
            }
        }}
        tabs={userLpDeposit.totalClaimableUSD > 0 ? tabsWithRewards : tabsWithoutRewards}
    />);
}

export default LpControls;
