import { Box, Typography } from '@mui/material';
import { TxState } from '../../web3/types';
import TxLink from '../TxLink';

type Props = {
    txState: TxState;
    currentTxHash: string;
}

export default function TxContent({ txState, currentTxHash }: Props) {
    return (<>
        {txState === 'confirm' ? <Box>
            <Typography sx={{ fontSize: '24px', lineHeight: '30px', fontWeight: 700, color: '#002237' }}>Confirm in Wallet</Typography>
        </Box> : <></>}
        {txState === 'pending' ? <>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '24px', lineHeight: '30px', fontWeight: 700, color: '#002237' }}>Transaction submitted</Typography>
                <TxLink currentTxHash={currentTxHash} />
            </Box>
        </> : <></>}
    </>);
}