import { useTheme, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ConnectionHandler from '../../ConnectionHandler';
import ConnectedNetwork from '../../ConnectionHandler/ConnectedNetwork';
import TopBarStats from '../../TopBarStats';
import ModalHandler from '../../ModalHandler';
import AllStats from '../../AllStats';
import { ReactComponent as DotDotLogo } from '../../../logo.svg';
import { ReactComponent as StatsButtonLogo } from '../../../images/statsbutton/logo.svg';

type LogoButtonProps = {
    open: boolean;
    theme: Theme;
    handleDrawerClose: () => void;
    handleDrawerOpen: () => void;
}
  
function LogoButton({ open, theme, handleDrawerClose, handleDrawerOpen }: LogoButtonProps) {
    return <>{open ? <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingX: '12px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <DotDotLogo />
          <IconButton onClick={handleDrawerClose} sx={{ ml: 3.5 }}>
            {theme.direction === 'rtl' ? <ChevronRightIcon color="secondary" /> : <ChevronLeftIcon color="secondary" />}
          </IconButton>
        </Box>
      </Box> :
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingX: '12px' }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
              display: open ? 'none' : 'flex',
              marginLeft: 'unset'
          }}
        >
          <MenuIcon />
        </IconButton>
      </Box>
    }</>;
}


type Props = {
    open: boolean;
    handleDrawerOpen: () => void;
    handleDrawerClose: () => void;
}


export default function DesktopToolBar({ open, handleDrawerClose, handleDrawerOpen }: Props) {
    const theme = useTheme();
    return (<>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
            <LogoButton open={open} theme={theme} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />
            <TopBarStats />
            <Box marginLeft={'12px'} sx={{ '& > button': { width: '130px' } }}>
            <ModalHandler buttonType='blue' buttonTitle={<>More stats&nbsp;&nbsp;<StatsButtonLogo /></>} disabled={false}>
                <AllStats />
            </ModalHandler>
            </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginRight: '24px' }}>
            <ConnectedNetwork />
            <ConnectionHandler />
        </Box>
    </>);
}