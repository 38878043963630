import React, { ReactNode } from 'react';
import { Box, CSSObject, Tooltip, Typography } from '@mui/material';
import { DisplayLargeNumber2 } from '../DisplayLargeNumber';
import { DisplayWholeNumber } from '../DisplayWholeNumber';
import { DisplayValue2 } from '../DisplayValue';
import DisplayPreciseNumber from '../DisplayPreciseNumber';

export interface Stat {
    title: string;
    value: number;
    width: string;
    tooltip?: ReactNode;
    compact?: boolean;
    symbolFront?: string;
    symbolBack?: string;
    wholeNumber?: boolean;
    precision?: number;
    preciseNumber?: boolean;
    subValue?: number;
    subCompact?: boolean;
    subSymbolFront?: string;
    subSymbolBack?: string;
}

type Props = {
    stats: Stat[];
}

export default function TopStatsTable({ stats }: Props) {
    const style: CSSObject = {
        padding: '9px 15px',
        textAlign: 'left',
        width: '100%',
        background: '#E0EEF9',
        border: '1px solid #B9EDFE',
        borderRadius: '4px'
    };

    return (<Box sx={{
        display: 'flex',
        flexDirection: ['column', 'row'],
        justifyContent: 'flex-start',
    }}>
        {stats.map((s, k) => (
            <Box key={k} sx={{ width: s.width, mb: ['8px', 'unset'], mr: ['unset', '8px'], ...style }}>
                {s.tooltip !== undefined ? <Tooltip
                    title={<>{s.tooltip}</>}
                    placement="top"
                    arrow
                >
                    <Box>
                    <Typography sx={{ color: '#000000', fontSize: '12px', lineHeight: '15px', fontWeight: 500, mb: '9px', opacity: '0.6' }}>
                        {s.title}
                    </Typography>
                    <Typography variant="body2">
                        {s.symbolFront ? s.symbolFront : ''}{
                            s.wholeNumber ? <DisplayWholeNumber value={s.value}  /> :
                            s.preciseNumber ? <DisplayPreciseNumber value={s.value} precision={s.precision} /> :
                            s.compact ? <DisplayLargeNumber2 value={s.value}  /> :
                            <DisplayValue2 value={s.value} precision={s.precision} />
                        }{s.symbolBack ? s.symbolBack : ''}
                    </Typography>
                    </Box>
                </Tooltip> : <>
                    <Typography sx={{ color: '#000000', fontSize: '12px', lineHeight: '15px', fontWeight: 500, mb: '9px', opacity: '0.6' }}>
                        {s.title}
                    </Typography>
                    <Typography variant="body2">
                        {s.symbolFront ? s.symbolFront : ''}{
                            s.wholeNumber ? <DisplayWholeNumber value={s.value}  /> :
                            s.preciseNumber ? <DisplayPreciseNumber value={s.value} precision={s.precision} /> :
                            s.compact ? <DisplayLargeNumber2 value={s.value}  /> :
                            <DisplayValue2 value={s.value} precision={s.precision} />
                        }{s.symbolBack ? s.symbolBack : ''}
                    </Typography>
                </>}
                <Typography sx={{ color: '#000000', fontSize: '12px', lineHeight: '15px', fontWeight: 500, mt: '4px', opacity: '0.6' }}>
                    {s.subSymbolFront ? s.subSymbolFront : ''}{s.subValue ?
                        s.subCompact ?
                            <DisplayLargeNumber2 value={s.subValue} /> : <DisplayValue2 value={s.subValue} precision={s.precision} />
                        : <></>
                    }{s.subSymbolBack ? s.subSymbolBack : ''}
                </Typography>
            </Box>
        ))}
    </Box>);
}
