import React, { useEffect, useState } from 'react';
import { Typography, Grid, Box, Tooltip } from '@mui/material';
import { BigNumber } from 'bignumber.js';
import { KeyedObject, UserVotes } from '../../../../../../web3/types';
import { DisplayValue2 } from '../../../../../../components/DisplayValue';
import { TokenVotes } from '../../../../../../providers/api-provider';
import InputNumberVote from '../../../../../../components/Inputs/InputNumberVote';
import TokenIcon from '../../../../../../components/TokenIcon';
import { DisplayLargeNumber2 } from '../../../../../../components/DisplayLargeNumber';
import DisplayPreciseNumber from '../../../../../../components/DisplayPreciseNumber';

type Props = {
  page: number;
  lpToken: TokenVotes;
  userVotes: UserVotes | undefined;
  userMaxVotes: BigNumber;
  existingVote: number;
  totalBribes: number;
  bribesPerVote: number;
  setUserVotes: (a: KeyedObject) => void;
}

export default function PoolTableItem({ page, lpToken, userVotes, userMaxVotes, existingVote, totalBribes, bribesPerVote, setUserVotes }: Props) {
  const [inputValue, setInputValue] = useState<number | string>(
    userVotes !== undefined && userVotes[lpToken.token] ? userVotes[lpToken.token].amount.toNumber() : ''
  );

  const setVoteAmount = (a: BigNumber) => {
    if (userVotes !== undefined)  {
      if (a.isZero()) {
        delete userVotes[lpToken.token];
        setInputValue('');
      } else {
        userVotes[lpToken.token] = {
          amount: a,
          address: lpToken.token,
          symbol: lpToken.symbol
        };
        setInputValue(a.toNumber());
      }

      const newUserVotes = {} as UserVotes;
      Object.assign(newUserVotes, userVotes)
      setUserVotes(newUserVotes);
    } else {
      userVotes = {} as UserVotes;
      userVotes[lpToken.token] = {
        amount: a,
        address: lpToken.token,
        symbol: lpToken.symbol
      };

      const newUserVotes = {} as UserVotes;
      Object.assign(newUserVotes, userVotes)
      setUserVotes(newUserVotes);
      setInputValue(a.toNumber());
    }
  }

  useEffect(() => {
    if (userVotes === undefined) {
      setInputValue('');
    }
  }, [userVotes]);

  useEffect(() => {
    setInputValue(userVotes !== undefined && userVotes[lpToken.token] ? userVotes[lpToken.token].amount.toNumber() : '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (<Grid
    container
    sx={{
      p: '5px',
      alignItems: 'center',
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
      }
    }}
  >
    <Grid item xs={1.7}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TokenIcon address={lpToken.token} />
        <Tooltip title={lpToken.name} placement="top" arrow>
            <Typography variant="h4" sx={{ ml: 0.5 }}>
                {lpToken.symbol}
            </Typography>
        </Tooltip>
      </Box>
    </Grid>
    <Grid item xs={1.7}>
      <Typography variant="h4">
        $<DisplayValue2 value={totalBribes} />
      </Typography>
    </Grid>
    <Grid item xs={1.7}>
      <Typography variant="h4">
        $<DisplayPreciseNumber precision={5} value={bribesPerVote}  />
      </Typography>
    </Grid>
    <Grid item xs={1.7}>
      <Typography variant="h4">
        <DisplayLargeNumber2 value={lpToken.epxRewardsPerDay} />
      </Typography>
    </Grid>
    <Grid item xs={1.7}>
      <Typography variant="h4">
        <DisplayLargeNumber2 value={lpToken.votes} />
      </Typography>
    </Grid>
    <Grid item xs={1.7}>
      <Typography variant="h4">
        <DisplayValue2 value={existingVote} />
      </Typography>
    </Grid>
    <Grid item xs={1.7}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > div > div': {
          width: '180px'
        }
      }}>
        <InputNumberVote
          placeholderText='Enter vote'
          setRealInput={setVoteAmount}
          setInputValue={setInputValue}
          inputValue={inputValue}
          maxAmount={typeof(inputValue) === 'string' ? userMaxVotes : userMaxVotes.plus(inputValue)}
        />
      </Box>
    </Grid>
  </Grid>);
}
