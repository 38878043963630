
import React, { ChangeEvent, useEffect, useState } from 'react';
import { SelectChangeEvent, Box, FormControl, Select, Typography, OutlinedInput, MenuItem, ListItemText, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { useApiDataContext } from '../../../../providers/api-provider';
import { useOnboardProvider } from '../../../../providers/onboard-provider';
import { BASE_API } from '../../../../providers/api-provider';
import { ZERO_ADDRESS } from '../../../../web3/utils';
import HistoricalVotesTable from '../HistoricalVotesTable';
import TopStatsTable from '../../../../components/TopStatsTable';
import CardTitle from '../../../../components/CardTitle';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export interface HistoricalVote {
    token: string,
    name: string,
    symbol: string,
    votes: number,
    userVote: number,
    hide: boolean,
    epxRewardsPerSecond: number,
    epxRewardsPerDay: number,
    totalBribesUSD: number,
    bribesPerVote: number
}

function HistoricalVotesHandler() {
    const { account } = useOnboardProvider();

    const { voterData } = useApiDataContext();

    const weeksToSelect = [];

    for (let i = 1; i < voterData.currentWeek; i++) {
        weeksToSelect.push(i);
    }

    const [week, setWeek] = useState<string>(weeksToSelect.length > 0 ? weeksToSelect[weeksToSelect.length-1].toString() : "1");
    const [historicalVotes, setHistoricalVotes] = useState<HistoricalVote[]>([]);
    const [totalBribesUSD, setTotalBribesUSD] = useState(0);
    const [totalBribesPerLockedDDD, setTotalBribesPerLockedDDD] = useState(0);
    const [sortedHistoricalVotes, setSortedHistoricalVotes] = useState<HistoricalVote[]>([]);

    const getVoterData = async (week: number, address: string) => {
        axios.get(`${BASE_API}/historicalVotes?week=${week}&address=${address}`)
        .then((result: any) => {
            setHistoricalVotes(result.data.data.votes);
            setSortedHistoricalVotes(result.data.data.votes);
            setTotalBribesUSD(result.data.data.totalBribesUSD);
            setTotalBribesPerLockedDDD(result.data.data.totalBribesPerLockedDDD);
        }).catch((e) => console.error(e));
    };

    useEffect(() => {
        const address = account !== undefined && account !== null ? account : ZERO_ADDRESS;
        getVoterData(parseInt(week), address);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [week]);

    const handleChange = (event: SelectChangeEvent<typeof week>) => {
        const {
            target: { value },
        } = event;
        setWeek(value);
        setSearchText('');
    };

    const [searchText, setSearchText] = useState('');

    const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value)
        if (historicalVotes !== undefined) {
            let _lps: HistoricalVote[] = [];
            _lps = _lps.concat(historicalVotes);
            setSortedHistoricalVotes(_lps.filter((p) => p.name.toLowerCase().includes(event.target.value.toLowerCase())));
        }
    }

    const stats2 = [
        {
            title: 'Total Bribes',
            value: totalBribesUSD,
            compact: true,
            width: '50%',
            symbolFront: '$'
        },
        {
            title: 'Total Bribes/vlDDD',
            value: totalBribesPerLockedDDD,
            compact: true,
            width: '50%',
            preciseNumber: true,
            precision: 6,
            symbolFront: '$'
        },
    ];

    return (<>
        <Box sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
            justifyContent: 'space-between',
            paddingBottom: '24px',
            paddingRight: '24px',
            paddingLeft: '24px',
            background: 'linear-gradient(90deg, #966DF3 0%, #0CB9FC 100%);' 
        }}>
            <CardTitle title={'Historical Votes'} />
            <TopStatsTable stats={stats2} />
        </Box>
        <Box sx={{ py: '24px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', mr: [0, 2], ml: [2, 0] }}>
            <FormControl sx={{ width: '200px', height: '100%', mr: 2 }}>
                <Select
                    sx={{ background: '#ffffff' }}
                    displayEmpty
                    value={week}
                    onChange={handleChange}
                    renderValue={(selected) => {
                        return <Typography sx={{
                            fontSize: '18px',
                            fontFamily: 'Outfit',
                            fontWeight: 500,
                            textAlign: 'left',
                            color: '#000000'
                        }}>
                            Week {selected} 
                        </Typography>;
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label'}}
                    input={<OutlinedInput />}
                >
                    {weeksToSelect.map((i) => (
                        <MenuItem key={i.toString()} value={i.toString()}>
                            <ListItemText primary={`Week ${i.toString()}`} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                sx={{ mr: 2 }}
                variant='outlined'
                id="filled-adornment-password"
                type={'text'}
                value={searchText}
                placeholder="Search Pools"
                onChange={handleChangeSearch}
                InputProps={{
                    endAdornment: (<InputAdornment position="end">
                        <SearchIcon />
                    </InputAdornment>)
                }}
            />
        </Box>
        <HistoricalVotesTable historicalVotes={sortedHistoricalVotes} />
    </>);

}

export default HistoricalVotesHandler;
