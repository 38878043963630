import React, { useEffect, useState } from 'react';
import { Box, Grid, Skeleton, Tooltip, Typography, useTheme, useMediaQuery } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { DisplayValue2 } from '../../../../../components/DisplayValue';
import DisplayTableAccordian from '../../../../../components/DisplayTableAccordian';
import { LpDetails, LpToTokenInfos, LpToWeeklyAmountUSD } from '../../../../../providers/api-provider';
import { DisplayLargeNumber2 } from '../../../../../components/DisplayLargeNumber';
import BribeControls from '../BribeControls';
import TokenIcon from '../../../../../components/TokenIcon';
import { ZERO_ADDRESS } from '../../../../../web3/utils';
import DisplayPreciseNumber from '../../../../../components/DisplayPreciseNumber';

type Props = {
    lpDeposits: Array<LpDetails> | undefined;
    lpToTokenInfo: LpToTokenInfos;
    lpToWeeklyAmountUSD: LpToWeeklyAmountUSD;
}

export default function LpList({ lpDeposits, lpToTokenInfo, lpToWeeklyAmountUSD }: Props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [sortedLpDeposits, setSortedLpDeposits] = useState<LpDetails[]>([])

    useEffect(() => {
        if (lpDeposits) {
            const sortedLpDeposits = lpDeposits
                .filter((a) => a.token !== ZERO_ADDRESS)
                .sort((a, b) => b.dddTvlUSD - a.dddTvlUSD)
                .sort((a, b) => lpToTokenInfo[b.token] && lpToTokenInfo[a.token] ?
                lpToWeeklyAmountUSD[b.token].totalBribes - lpToWeeklyAmountUSD[a.token].totalBribes : 0);
            setSortedLpDeposits(sortedLpDeposits);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lpDeposits]);

    const headings = isMobile ? [] : [
        '',
        'Total Bribes (USD)',
        'DDD Locks vAPR',
        <Tooltip title={'$/vlDDD is based on total bribe amount divided by vlDDD and is not going to be a specific number. It should be taken as an estimate only.'} placement="top" arrow>
            <Typography variant="subtitle2" sx={{ justifyContent: 'center' }}>
                $/vlDDD&nbsp;
                <InfoIcon fontSize='inherit'/>
            </Typography>
        </Tooltip>
    ];

    const rows = lpDeposits !== undefined ? sortedLpDeposits.map((l, k) => {return {
        item: <Grid container sx={{ alignItems: ['flex-start', 'center'], ml: ['16px', 'unset'] }}>
            <Grid item lg={2.4} xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: ['flex-start', 'center'], mb: ['8px', 'unset'] }}>
                    <TokenIcon address={l.token} />
                    <Tooltip title={l.name} placement="top" arrow>
                        <Typography variant="h4" sx={{ width: '100px' }}>
                            {l.symbol}
                        </Typography>
                    </Tooltip>
                </Box>
            </Grid>
            <Grid item xs={6} sx={{ display: ['flex', 'none'] }}>
                <Typography variant="h4">
                    Total Bribes (USD):
                </Typography>
            </Grid>
            <Grid item lg={2.4} xs={6}>
                <Typography variant="h4" sx={{ textAlign: ['left', 'inherit'] }}>
                    $<DisplayValue2 value={lpToWeeklyAmountUSD[l.token] !== undefined ? lpToWeeklyAmountUSD[l.token].totalBribes : 0} />
                </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: ['flex', 'none'] }}>
                <Typography variant="h4">
                    DDD Locks vAPR:
                </Typography>
            </Grid>
            <Grid item lg={2.4} xs={6}>
                <Typography variant="h4" sx={{ textAlign: ['left', 'inherit'] }}>
                    <DisplayLargeNumber2 value={lpToWeeklyAmountUSD[l.token] !== undefined ? lpToWeeklyAmountUSD[l.token].totalApr : 0} />%
                </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: ['flex', 'none'] }}>
                <Typography variant="h4">
                    $/vlDDD:
                </Typography>
            </Grid>
            <Grid item lg={2.4} xs={6}>
                <Typography variant="h4" sx={{ textAlign: ['left', 'inherit'] }}>
                    $<DisplayPreciseNumber precision={5} value={lpToWeeklyAmountUSD[l.token] !== undefined ? lpToWeeklyAmountUSD[l.token].totalBribesPerLockedDDD : 0} />
                </Typography>
            </Grid>
        </Grid>,
        key: k.toString(),
        content: <BribeControls lpDetails={l} lpToTokenInfo={lpToTokenInfo} />
    }}) : [];

    return (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {rows !== undefined ? <DisplayTableAccordian headings={headings} rows={rows} /> : <>
            <Skeleton variant="rectangular" width={'100%'} height={10} sx={{ my: 2, borderRadius: '4px' }} />
            <Skeleton variant="rectangular" width={'100%'} height={10} sx={{ my: 2, borderRadius: '4px' }} />
        </>}
    </Box>);
}