import React, { PropsWithChildren, ReactNode, useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Button, CSSObject, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import Multicall from '@dopex-io/web3-multicall';
import { AbiItem } from 'web3-utils';
import { useApiDataContext } from '../api-provider';
import { useOnboardProvider } from '../onboard-provider';
import ERC20Abi from '../../abi/ERC20.json';
import { CONFIG } from '../../global';
import { ReactComponent as Voting } from '../../images/globalsnackbar/voting/logo.svg';
import { ReactComponent as Locking } from '../../images/globalsnackbar/locking/logo.svg';
import { ReactComponent as Convert } from '../../images/globalsnackbar/convert/logo.svg';

const loadData = async (user: string) => {
    const web3 = new Web3(CONFIG.rpc);

    const multicall = new Multicall({
        multicallAddress: CONFIG.multicall,
        provider: CONFIG.rpc,
    });

    const ddd = new web3.eth.Contract(ERC20Abi as AbiItem[], CONFIG.Token)
    const epx = new web3.eth.Contract(ERC20Abi as AbiItem[], CONFIG.EPX)

    const [
        dotDotbalance,
        epxBalance,
    ] = await multicall.aggregate([
        ddd.methods.balanceOf(user),
        epx.methods.balanceOf(user)
    ]);

    return {
        dotDotBalance: new BigNumber(dotDotbalance).dividedBy(1e18).toNumber(),
        epxBalance: new BigNumber(epxBalance).dividedBy(1e18).toNumber(),
    };
}

export interface SnackbarContextData {
    closeSnackBar: () => void;
    showSnackbar: boolean;
    snackBarStyle: CSSObject;
    message: ReactNode;
}

const SnackbarDataContext = React.createContext({} as SnackbarContextData);

export function SnackbarProvider({ children }: PropsWithChildren<{}>) {
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [closeByUser, setCloseByUser] = useState<boolean>(false);
    const [message, setMessage] = useState<ReactNode>(undefined);
    const [snackBarStyle, setSnackBarStyle] = useState<CSSObject>({
        background: '#FFFFFF',
        borderRadius: '12px',
        paddingLeft: '64px',
        '& > div': {
            padding: 'unset'
        }
    });

    const location = useLocation();

    const votingCSS: CSSObject = {
        background: '#FFFFFF',
        borderRadius: '12px',
        paddingLeft: '64px',
        '& > div': {
            padding: 'unset'
        }
    };

    const votingMessage = <Box sx={{ position: 'relative' }}>
        <Box sx={{ position: 'absolute', left: -50, bottom: -2 }}>
            <Voting />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography sx={{ color: '#976CF3', fontWeight: 400, fontSize: '18px', lineHeight: '24px', mr: 3 }}>
                Voting is now open. Remember, <span style={{ fontWeight: 700 }}>the bribe attached to a pool is split between everyone who votes for it!</span>
            </Typography>
            <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/vote">
                <Button variant="blue">
                    Go to Vote page
                </Button>
            </Link>
        </Box>
    </Box>;

    const lockMessage = <Box sx={{ position: 'relative' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Box sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                <Locking />
            </Box>
            <Typography sx={{ color: '#FFFFFF', fontWeight: 400, fontSize: '18px', lineHeight: '24px', mr: 3 }}>
                You have DDD. <span style={{ fontWeight: 700 }}>You can lock these to earn platform fees and bribes by voting weekly!</span>
            </Typography>
            <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/lock">
                <Button variant="white">
                    Go to Lock page
                </Button>
            </Link>
        </Box>
    </Box>;

    const lockCSS: CSSObject = {
        background: '#976CF3',
        borderRadius: '12px',
        paddingLeft: '64px',
        '& > div': {
            padding: 'unset'
        }
    };

    const epxMessage = <Box sx={{ position: 'relative' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Box sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                <Convert />
            </Box>
            <Typography sx={{ color: '#FFFFFF', fontWeight: 400, fontSize: '18px', lineHeight: '24px', mr: 3 }}>
                You have EPX. <span style={{ fontWeight: 700 }}>Convert & Stake it on Dot Dot to earn a share of the platform’s EPX earnings and EPS trading fees!</span>
            </Typography>
            <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/convert">
                <Button variant="white">
                    Go to Convert page
                </Button>
            </Link>
        </Box>
    </Box>;

    const { voterData } = useApiDataContext();
    const { account } = useOnboardProvider();

    const [dotDotBalance, setDotDotBalance] = useState<number>(0);
    const [epxBalance, setEpxBalance] = useState<number>(0);

    const doAction = async (
        acc: string | undefined | null,
    ) => {
        if (acc !== undefined && acc != null) {
            try {
                const {
                    dotDotBalance,
                    epxBalance,
                } = await loadData(acc);
                setDotDotBalance(dotDotBalance);
                setEpxBalance(epxBalance);
            } catch (e) {
                console.error(e);
            }
        }
    }

    useEffect(() => {
        doAction(account);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    useEffect(() => {
        const interval = setInterval(() => doAction(account), 10000);
        return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    const closeSnackBar = () => {
        setCloseByUser(true);
        setShowSnackbar(false);
    }

    useEffect(() => {
        if (closeByUser === false && dotDotBalance > 0 && location.pathname !== '/lock') {
            setMessage(lockMessage);
            setSnackBarStyle(lockCSS);
            setShowSnackbar(true);
        } else if (closeByUser === false && epxBalance > 0 && location.pathname !== '/convert') {
            setMessage(epxMessage);
            setSnackBarStyle(lockCSS);
            setShowSnackbar(true);
        } else if (closeByUser === false && voterData.votingOpen && location.pathname !== '/vote') {
            setMessage(votingMessage);
            setSnackBarStyle(votingCSS);
            setShowSnackbar(true);
        } else {
            setShowSnackbar(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [voterData, location]);

    return (
        <SnackbarDataContext.Provider
            value={{
                closeSnackBar,
                showSnackbar,
                snackBarStyle,
                message
            }}
        >
            {children}
        </SnackbarDataContext.Provider>
    );
}

export const useSnackbarDataContext = () => useContext(SnackbarDataContext);
