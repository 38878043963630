import React from 'react';
import { Box, Button } from '@mui/material';
import { useOnboardProvider } from '../../../providers/onboard-provider';

export const shortenAddress = (address: string) => address.substr(0, 4) + "...." + address.substr(-4);

function WalletAddress() {
  const { account, connectWallet } = useOnboardProvider();
  return (<Box sx={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > button': { fontSize: ['12px', 'inherit'] }
  }}>
    <Button variant="connect" onClick={connectWallet}>
      {account !== undefined && account !== null ? shortenAddress(account) : <span>Connect Wallet</span>}
    </Button>
  </Box>);
}

export default WalletAddress;
