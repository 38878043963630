import React from 'react';
import { Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import DisplayValue from '../DisplayValue';

type Props = {
    title: string;
    tokenBalance: BigNumber;
    tokenSymbol: string;
    decimals?: number;
}

export default function Balance({ title, tokenBalance, tokenSymbol, decimals = 10 ** 18 }: Props) {
    return (<Typography variant="h4" color="inherit">
        {title}:&nbsp;
        <DisplayValue value={tokenBalance} decimals={decimals} precision={4} />&nbsp;
        {tokenSymbol}
    </Typography>);
}