
import React from 'react';
import TabsPanel from '../../../../../components/TabsPanel';
import DepositBribe from '../DepositBribe';
import { LpDetails, LpToTokenInfos } from '../../../../../providers/api-provider';
import BribesList from '../BribesList';

type Props = {
    lpDetails: LpDetails;
    lpToTokenInfo: LpToTokenInfos;
}

function BribeControls({ lpDetails, lpToTokenInfo }: Props) {
    return (<TabsPanel
        sx={{
            '.MuiTabs-flexContainer': {
                flexDirection: ['column', 'row'],
                marginTop: '8px',
                marginBottom: '8px',
                border: 'unset',
                '& > button': {
                    minHeight: 'unset',
                    maxWidth: ['unset', 'inherit'],
                },
            },
            '& > .MuiTabs-scroller > span': {
            }
        }}
        tabs={[
            {
                title: <>All Bribes</>,
                icon: <></>,
                component: <BribesList incentives={lpToTokenInfo[lpDetails.token]} />
            },
            {
                title: <>Deposit</>,
                icon: <></>,
                component: <DepositBribe lp={lpDetails} />
            },
        ]}
    />);
}

export default BribeControls;
