import { Box, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

type Props = {
    title: string | ReactNode;
    subtitle?: string | ReactNode;
}

export default function CardTitle({ title, subtitle }: Props) {
    return (<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'left', marginBottom: ['8px', 0] }}>
        <Typography variant="h3" sx={{ color: '#FEFEFF' }}>{title}</Typography>
        {subtitle ? <Typography variant="subtitle1" sx={{ color: '#FEFEFF' }}>{subtitle}</Typography> : <></>}
    </Box>);
}