import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import LockedEPXAbi from '../../abi/LockedEPX.json';
import { CONFIG } from '../../global';
import { TxActions } from '../types';
import { calcGas, toHex } from './../utils';

export async function deposit(
    actions: TxActions,
    web3: Web3,
    account: string,
    amount: BigNumber,
    bond: boolean,
): Promise<any> {
    actions.setTxState('confirm');
    const c = new web3.eth.Contract(LockedEPXAbi as AbiItem[], CONFIG.LockedEPX);
    const est = await c.methods.deposit(account, toHex(amount), bond).estimateGas({'from': account});
    c.methods.deposit(account, toHex(amount), bond).send({'from': account, gas: calcGas(est), maxFeePerGas: 3 * 1e9, maxPriorityFeePerGas: 3 * 1e9 })
    .once('transactionHash', (hash: any) => {
        actions.setTxState('pending');
        actions.setTxHash(hash);
    }).once('error', (error: any) => {
        console.error(error)
        actions.setTxError(error);
        actions.setTxState('none');
    }).once('confirmation', (conf: any, receipt: any) => {
    }).then((receipt: any) => {
        actions.txFinished();
    }).finally(() => {
        actions.txFinished();
    });
}