import prod from "./deployments/prod.json";
import test from "./deployments/test.json";

export const PRICE_LINK = '';
export const TWITTER_LINK = '';
export const TELEGRAM_LINK = '';
export const DISCORD_LINK = '';
export const MEDIUM_LINK = '';
export const GITHUB_LINK = '';
export const DOCS_LINK = '';

export const CONFIG = process.env.REACT_APP_PROD === 'true' ? prod : test;
export const PRELAUNCH = process.env.REACT_APP_PRELAUNCH === 'true';
export const APPROVAL_VOTE_BIRBS = process.env.REACT_APP_APPROVAL_VOTE_BIRBS === 'true';

export const CHAIN_ID = CONFIG.chainId;
export const CHAIN_ID_HEX = CONFIG.chainIdHex;
export const CHAIN_NAME = CONFIG.chainName;
export const RPC_URL = CONFIG.rpc;
export const EXPLORER_URL = CONFIG.explorer;

export const SUPPORTED_CHAINS = [CHAIN_ID];

export const NATIVE_SYMBOL = CONFIG.chainSymbol;
export const WNATIVE_SYMBOL = `W${CONFIG.chainSymbol}`;

export const METAMASK_NETWORK_PARAMS = {
    chainId: CHAIN_ID_HEX,
    chainName: CHAIN_NAME,
    nativeCurrency: {
      name: CHAIN_NAME,
      symbol: NATIVE_SYMBOL,
      decimals: 18,
    },
    rpcUrls: [RPC_URL],
    blockExplorerUrls: [EXPLORER_URL],
  };
