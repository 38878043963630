import React, { useState } from 'react';
import { useOnboardProvider } from '../../../../../providers/onboard-provider';
import LoadingButton from '../../../../../components/LoadingButton';
import CompleteButton from '../../../../../components/CompleteButton';
import { TxState } from '../../../../../web3/types';
import { claim } from '../../../../../web3/BondedDistributor/claim';
import TxModal from '../../../../../components/TxModal';

type Props = {
    tokens: string[];
}

export default function ClaimAllTradingFees({ tokens }: Props) {
    const { signer, account } = useOnboardProvider();

    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const handleClaim = async () => {
        if (signer !== undefined && account !== undefined && account !== null && tokens.length > 0) {
            const txFinished = () => {
                setTxState('finished');
            }
            await claim(
                {
                    setTxHash: setCurrentTxHash,
                    setTxState: setTxState,
                    setTxError: () => {},
                    txFinished: txFinished,
                },
                signer,
                account,
                tokens
            );
        }
    }
   
    return (<>
        {txState === 'none' || txState === 'pending' || txState === 'confirm' ? <LoadingButton
            content={`Claim all`}
            variant={"blue"}
            onClick={handleClaim}
            disabled={txState === 'pending' || txState === 'confirm' ? true : false}
            loading={txState === 'pending' || txState === 'confirm' ? true : false }
        /> : <CompleteButton content={'Claimed'} />}
        <TxModal txState={txState} currentTxHash={currentTxHash} setTxState={setTxState} />
    </>);
}

