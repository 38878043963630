import React from 'react';
import { useTheme, useMediaQuery, Box } from '@mui/material';

type Props = {
    background: string;
    height?: string;
    width?: string;
}

export default function PageBackground({ background, height = '100%', width = '100%' }: Props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (<Box sx={{
            display: isMobile ? 'none' : 'block',
            position: 'fixed',
            right: '0',
            bottom: '0',
            objectFit: 'cover',
            width: width,
            height: height,
            background: `url(/bg/${background}.svg); no-repeat`,
            backgroundRepeat: 'no-repeat',
            //backgroundPositionX: 'center',
            margin: 'auto'
        }}></Box>
    );
}