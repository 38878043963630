import  React, { ReactNode, useState } from 'react';
import { Typography, Grid, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export type RowItem = {
    item: ReactNode;
    key: string;
    content: ReactNode;
}

type Props = {
    headings: string[] | ReactNode[],
    rows: RowItem[],
    headingSpace?: number;
}

const Accordion = styled((props: AccordionProps) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        borderTop: 'unset',
        borderLeft: 'unset',
        borderRight: 'unset',
        borderBottom: '1px solid #E1E8F7',
        '&:not(:last-child)': {
        },
        '&:before': {
            display: 'none',
        },
    })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: 'unset',
        },
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '14.4px'
    })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 'unset',
    borderTop: '1px solid #E1E8F7',
}));

export default function DisplayTableAccordian({ headings, rows, headingSpace = 2.4 }: Props)  {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

    const [viewNumber, setViewNumber] = useState(5);

    const viewMore = () => {
        setViewNumber(rows.length);
    }

    return (<Grid container>
        <Grid container sx={{
            pl: ['unset', '28.8px'],
            pr: ['unset', '18px'],
            pb: '8px',
            borderBottom: '1px solid #E1E8F7',
            alignItems: 'center'
        }}>
            {headings.map((h, k) => 
                <Grid item key={k} lg={headingSpace} xs={6}>
                    {typeof(h) === 'string' ?
                        <Typography variant="subtitle2" sx={{ justifyContent: 'center' }}>{h}</Typography>
                    : h}
                </Grid>
            )}
        </Grid>
        <Grid container>
            <Grid item lg={12} xs={12}>
                {rows.slice(0, viewNumber).map((r) => (
                    <Accordion key={r.key} expanded={expanded === r.key} onChange={handleChange(r.key)}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            {r.item}
                        </AccordionSummary>
                        <AccordionDetails>
                            {r.content}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Grid>
        </Grid>
        {viewNumber === 5 && rows.length > 5 ? <Grid item lg={12} xs={12}>
            <Button variant="text" sx={{ width: "100%", p: 2, '&:hover': { background: 'rgba(151, 108, 243, 0.05)', opacity: 1 } }} onClick={viewMore}>
                <Typography sx={{ fontSize: '18px', fontWeight: 500, color: '#976CF3' }}>
                    View more
                </Typography>
            </Button>
        </Grid> : <></>}
    </Grid>)
}
