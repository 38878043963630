
import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import Multicall from '@dopex-io/web3-multicall';
import { AbiItem } from 'web3-utils';
import Moment from 'react-moment';
import 'moment-timezone';
import PoolsVoteTable from './components/PoolsVoteTable';
import CardTitle from '../../../components/CardTitle';
import StatsTable from '../../../components/StatsTable';
import { useApiDataContext } from '../../../providers/api-provider';
import { CONFIG } from '../../../global';
import DDDVoterAbi from '../../../abi/DDDVoter.json';
import DDDLockerAbi from '../../../abi/DDDLocker.json';
import { useOnboardProvider } from '../../../providers/onboard-provider';
import { UserTokenVotes } from '../../../web3/types';
import TopStatsTable from '../../../components/TopStatsTable';
import { DisplayValue2 } from '../../../components/DisplayValue';
import ZeroState from '../../../components/ZeroState';
import { Countdown } from '../../../components/Countdown';
import { Link } from 'react-router-dom';

const loadData = async (user: string, currentWeek: number) => {
    console.log(`loading voter data for ${user}`);
    const web3 = new Web3(CONFIG.rpc);

    const multicall = new Multicall({
        multicallAddress: CONFIG.multicall,
        provider: CONFIG.rpc,
    });

    const dddVoter = new web3.eth.Contract(DDDVoterAbi as AbiItem[], CONFIG.DDDVoter)
    const dddLocker = new web3.eth.Contract(DDDLockerAbi as AbiItem[], CONFIG.DDDLocker)

    const userCalls = [
        dddVoter.methods.getUserCurrentVotes(user),
        dddVoter.methods.availableVotes(user),
        dddLocker.methods.weeklyWeightOf(user, currentWeek)
    ];

    const [voteData, availableVotes, totalAvailableVotes] = await multicall.aggregate(userCalls);

    let userTokenVotes: { [key: string]: number } = {};

    for (let i = 0; i < voteData[1].length; i++) {
        userTokenVotes[voteData[1][i][0]] =  new BigNumber(voteData[1][i][1]).toNumber();
    }

    return {
        totalVoted: new BigNumber(voteData[0]).toNumber(),
        availableVotes: new BigNumber(availableVotes),
        totalAvailableVotes: new BigNumber(totalAvailableVotes),
        userTokenVotes: userTokenVotes
    };
}

function Vote() {
    const { voterData, lpToWeeklyAmountUSD } = useApiDataContext();

    const { account } = useOnboardProvider();

    const [totalVoted, setTotalVoted] = useState<number>(0);
    const [availableVotes, setAvailableVotes] = useState<BigNumber>(new BigNumber(0));
    const [totalAvailableVotes, setTotalAvailableVotes] = useState<BigNumber>(new BigNumber(0));
    const [userTokenVotes, setUserTokenVotes] = useState<UserTokenVotes>({ '0x0': 0 });

    const doAction = async (acc: string | undefined | null, week: number) => {
        if (acc !== undefined && acc != null) {
            try {
                const { totalVoted, availableVotes, totalAvailableVotes, userTokenVotes } = await loadData(acc, week);
                setTotalVoted(totalVoted);
                setAvailableVotes(availableVotes);
                setTotalAvailableVotes(totalAvailableVotes);
                setUserTokenVotes(userTokenVotes);
            } catch (e) {
                console.error(e);
            }
        }
    }

    useEffect(() => {
        doAction(account, voterData.currentWeek);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    useEffect(() => {
        const interval = setInterval(() => doAction(account, voterData.currentWeek), 20000);
        return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    const stats1 = [
        {
            title: 'Your remaining vote weight',
            value: availableVotes.toNumber(),
            compact: true,
            width: '38%',
            tooltip: <><DisplayValue2 value={availableVotes.toNumber()} /> vlDDD</>
        },
        {
            title: 'Your total vote weight',
            value: totalAvailableVotes.dividedBy(1e18).toNumber(),
            compact: true,
            width: '32%',
        },
        {
            title: 'Your total voted',
            value: totalVoted,
            compact: true,
            width: '30%'
        },
    ];

    const stats2 = [
        {
            title: 'Current week',
            value: voterData.currentWeek,
            compact: true,
            width: '50%',
            wholeNumber: true
        },
        {
            title: '1 vlDDD is equal to',
            value: voterData.currentVoteRatio,
            compact: true,
            width: '50%',
            wholeNumber: true,
            symbolBack: ' vlEPX'
        },
        {
            title: 'Total Votes',
            value: voterData.totalVotes,
            compact: true,
            width: '50%',
            wholeNumber: false
        },
    ];

    const countDown = (<Box sx={{
        display: 'flex',
        flexDirection: ['column', 'row'],
        justifyContent: 'flex-start',
    }}>
        <Box sx={{
            width: '100%',
            mr: ['unset', '8px'], 
            padding: '9px 15px',
            textAlign: 'left',
            background: '#E0EEF9',
            border: '1px solid #B9EDFE',
            borderRadius: '4px'
        }}>
            <Typography sx={{ color: '#1A2037', fontSize: '12px', lineHeight: '15px', fontWeight: 700, mb: '9px', opacity: '0.6' }}>
                Next voting epoch starts:
            </Typography>
            <Typography sx={{ color: '#1A2037', fontSize: '30px', lineHeight: '38px', fontWeight: 600, mb: '9px' }}>
                <Countdown endTime={voterData.startTime + ((86400 * 7) * voterData.currentWeek) + (86400 * 4)} />
            </Typography>
            <Typography sx={{ color: '#1A2037', fontSize: '12px', lineHeight: '15px', fontWeight: 700, mb: '9px', opacity: '0.6' }}>
                <Moment unix format="ddd M.D.Y&nbsp;&nbsp;&nbsp;HH:MM z" tz="UTC">
                    {voterData.startTime + ((86400 * 7) * voterData.currentWeek) + (86400 * 4)}
                </Moment>
            </Typography>
        </Box>
    </Box>);

    return (<Card>
        <CardContent sx={{ paddingBottom: '24px !important' }}>
            <Box sx={{
                p: '24px 24px',
                background: 'linear-gradient(90deg, #966DF3 0%, #0CB9FC 100%);' 
            }}>
                <Box sx={{ display: 'flex', flexDirection: ['column', 'row'], justifyContent: 'space-between', alignItems: ['unset', 'center'] }}>
                    <CardTitle title={'Vote'} />
                    <TopStatsTable stats={stats2} />
                </Box>
            </Box>
            <Box sx={{
                p: '24px 24px',
            }}>
                {voterData.votingOpen ? <Grid item xs={12} lg={12}>
                    <Grid container columnSpacing={2} rowSpacing={0.5}>
                        <Grid item xs={12} lg={7}>
                            <StatsTable stats={stats1} />
                        </Grid>
                    </Grid>
                </Grid> : <></>}
            </Box>
            {!voterData.votingOpen ? <ZeroState
                height='84px'
                title={<Typography sx={{ textAlign: 'center', fontSize: '28px', fontWeight: 400, lineHeight: '38px', color: '#ffffff' }}>
                    Voting is <span style={{ fontWeight: 700 }}>currently closed</span>
                </Typography>} background='vote' /> : <></>}
            {voterData.votingOpen ? <Grid container>
                <Grid item xs={12} lg={12}>
                    <PoolsVoteTable
                        lpTokenVotes={voterData.votes.filter((v) => !v.hide)}
                        userTokenVotes={userTokenVotes}
                        userAvailableVotes={availableVotes}
                        lpToWeeklyAmountUSD={lpToWeeklyAmountUSD}
                        firstLoad={voterData.votes.length === 0 ? true : false}
                    />
                </Grid>
            </Grid> : <></>}
            {!voterData.votingOpen ? <Box sx={{ pt: '24px', pb: '8px', px: '24px' }}>
                <Grid container columnSpacing={2} rowSpacing={1}>
                    <Grid item xs={12} lg={3.5}>
                        {countDown}
                    </Grid>
                    <Grid item xs={12} lg={8.5}>
                        <Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: 400, lineHeight: '140%', color: '#1A2037' }}>
                            Vote weekly to determine which pools receive more EPX emissions. If you vote for a pool with a bribe, you’ll receive your share streamed out to you the following week.
                        </Typography>
                    </Grid>
                </Grid>
            </Box> : <></>}
            {!voterData.votingOpen ? <Box sx={{ pb: '8px', pt: '8px', px: '24px' }}>
                <Grid container columnSpacing={2} rowSpacing={1}>
                    <Grid item xs={12} lg={6}>
                        <Box sx={{
                            px: '60px',
                            py: '20px',
                            borderRadius: '12px',
                            background: 'linear-gradient(95.88deg, #00BFFC -34.56%, #1A2037 6.58%, #1A2037 86.18%, #976CF3 133.17%)'
                        }}>
                            <Typography sx={{ mb: '10px', textAlign: 'center', fontSize: '28px', fontWeight: 400, lineHeight: '38px', color: '#ffffff' }}>
                                How is <span style={{ fontWeight: 700 }}>Vote Weight determined?</span>
                            </Typography>
                            <Typography sx={{ mb: '18px', textAlign: 'center', fontSize: '16px', fontWeight: 400, lineHeight: '22px', color: '#ffffff' }}>
                                Vote weight is determined by the amount of DDD you have locked before voting opens.
                            </Typography>
                            <Button variant="blue">
                                <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/lock">Lock some DDD</Link>
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Box sx={{
                            px: '60px',
                            py: '20px',
                            borderRadius: '12px',
                            boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)'
                        }}>
                            <Typography sx={{ mb: '10px', textAlign: 'center', fontSize: '28px', fontWeight: 400, lineHeight: '38px', color: '#1B2138' }}>
                                Can I <span style={{ fontWeight: 700 }}>add a Bribe?</span>
                            </Typography>
                            <Typography sx={{ mb: '18px', textAlign: 'center', fontSize: '16px', fontWeight: 400, lineHeight: '22px', color: '#1B2138' }}>
                                Yes, protocols wishing to bribe for the next voting epoch can add them now.
                            </Typography>
                            <Button variant="outlined">
                                <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/bribe">Add a bribe</Link>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box> : <></>}
        </CardContent>
    </Card>);
}

export default Vote;
