import React, { ChangeEvent, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import { Box, Button, Grid, TextField } from '@mui/material';
import { useOnboardProvider } from '../../../../../providers/onboard-provider';
import InputNumber from '../../../../../components/Inputs/InputNumber';
import CompleteButton from '../../../../../components/CompleteButton';
import LoadingButton from '../../../../../components/LoadingButton';
import { TxState } from '../../../../../web3/types';
import Balance from '../../../../../components/Balance';
import { CONFIG } from '../../../../../global';
import { ApprovalVotes } from '../../../../../providers/api-provider';
import ERC20Abi from '../../../../../abi/ERC20.json';
import Multicall from '@dopex-io/web3-multicall';
import Info from '../../../../../components/Info';
import ApproveButton from '../../../../../components/ApproveButton';
import TxModal from '../../../../../components/TxModal';
import { addIncentive } from '../../../../../web3/TokenApprovalIncentives/addIncentive';

type Props = {
    approvalVote: ApprovalVotes;
}

export default function DepositBribe({ approvalVote }: Props) {
    const { signer, account } = useOnboardProvider();

    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const [amount, setAmount] = useState<BigNumber>();
    const [inputValue, setInputValue] = useState<number | string>('');

    const [selectedToken, setSelectedToken] = useState<string>('');
    const [balance, setBalance] = useState<BigNumber>(new BigNumber(0));
    const [isApproved, setIsApproved] = useState<boolean>(false);
    const [symbol, setSymbol] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [decimals, setDecimals] = useState<number>(10 ** 18);

    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        const doLoad = async () => {
            if (account !== undefined && account != null && selectedToken.length > 0) {
                const web3 = new Web3(CONFIG.rpc);
                
                try {
                    const token = new web3.eth.Contract(ERC20Abi as AbiItem[], selectedToken)

                    const multicall = new Multicall({
                        multicallAddress: CONFIG.multicall,
                        provider: CONFIG.rpc,
                    });
                    
                    const calls = [
                        token.methods.symbol(),
                        token.methods.name(),
                        token.methods.decimals(),
                        token.methods.balanceOf(account),
                        token.methods.allowance(account, CONFIG.TokenApprovalIncentives),
                    ];

                    const [symbol, name, decimals, balance, allowance] = await multicall.aggregate(calls);

                    const b = new BigNumber(balance);
                    const a = new BigNumber(allowance);

                    setSymbol(symbol);
                    setName(name);
                    setDecimals(10 ** (new BigNumber(decimals).toNumber()));
                    setBalance(b);
                    setIsApproved(!b.isGreaterThan(a));
                } catch (e) {
                    console.error(e);
                    setError(true);
                }
            }
        }
        doLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedToken])

    const handleSubmit = async () => {
        if (signer !== undefined && account !== undefined && account !== null && amount !== undefined) {
            const txFinished = () => {
                setInputValue('');
                setAmount(undefined);
                setSymbol('');
                setName('');
                setDecimals(10**18);
                setBalance(new BigNumber(0));
                setIsApproved(false);
                setSelectedToken('')
                setTxState('finished');
            }
            await addIncentive(
                {
                    setTxHash: setCurrentTxHash,
                    setTxState: setTxState,
                    setTxError: () => {},
                    txFinished: txFinished,
                },
                signer,
                account,
                approvalVote.index,
                selectedToken,
                amount
            );
        }
    }

    const setMaxAmount = (event: any) => {
        event.preventDefault();
        if (balance) {
          setInputValue(balance.toNumber() / decimals)
          setAmount(balance);
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setError(false);
        const input = event.target.value;
        setSelectedToken(input);
        setSymbol('');
        setName('');
    };

    const message = <>
        {symbol.length ? `Bribe: ${name} (${symbol})` : error ? 'Please enter a valid ERC-20 address':'Please enter an address'}
    </>

    return (<>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mb: 1 }}>
                <Grid container columnSpacing={0.5} rowSpacing={0.5}>
                    <Grid item xs={12} lg={4}>
                        <TextField
                            autoComplete='off'
                            value={selectedToken}
                            error={error}
                            placeholder={'Input bribe'}
                            onChange={handleChange}
                            id="input"
                            sx={{ width: '100%', background: '#ffffff', }}
                            InputProps={{
                                sx: {
                                    padding: '2px 0px 2px 0px'
                                },
                            }}
                            inputProps={{
                                inputMode: 'text',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Info>
                            {message}
                        </Info>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1, width: '100%' }}>
                <Button disableRipple onClick={setMaxAmount} variant="text" sx={{ p: 0 }}>
                    <Balance title={'Wallet balance'} tokenBalance={balance} tokenSymbol={symbol} decimals={decimals} />
                </Button>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Grid container columnSpacing={0.5} rowSpacing={0.5}>
                    <Grid item xs={12} lg={3}>
                        <InputNumber
                            assetSymbol={symbol}
                            maxAmount={balance}
                            setRealInput={setAmount}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            decimals={decimals}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <ApproveButton isApproved={isApproved} token={selectedToken} contract={CONFIG.TokenApprovalIncentives} />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        {txState === 'none' || txState === 'pending' || txState === 'confirm' ? <LoadingButton
                            content={'Deposit bribe'}
                            variant={isApproved ? "blue" : "outlined"}
                            onClick={handleSubmit}
                            disabled={!isApproved || txState === 'pending' || txState === 'confirm' ? true : false || amount === undefined || amount.isZero()}
                            loading={txState === 'pending' || txState === 'confirm' ? true : false }
                        /> : <CompleteButton content={'Deposited bribe'} />}
                    </Grid>
                    <Grid item xs={12} lg={3}>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <TxModal txState={txState} currentTxHash={currentTxHash} setTxState={setTxState} />
    </>);
}