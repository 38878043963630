import React, { useState } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import { Box, Grid, Typography } from '@mui/material';
import { DisplayValue2 } from '../../../../../components/DisplayValue';
import { useOnboardProvider } from '../../../../../providers/onboard-provider';
import LoadingButton from '../../../../../components/LoadingButton';
import CompleteButton from '../../../../../components/CompleteButton';
import { TxState } from '../../../../../web3/types';
import { withdrawExitStream } from '../../../../../web3/TokenLocker/withdrawExitStream';
import TxModal from '../../../../../components/TxModal';

type Props = {
    currentClaiamble: number;
    totalClaimable: number;
    exitStreamEndTime: number;
}

export default function ClaimExitStream({
    currentClaiamble,
    totalClaimable,
    exitStreamEndTime
}: Props) {
    const { signer, account } = useOnboardProvider();

    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const handleClaim = async () => {
        if (signer !== undefined && account !== undefined && account !== null && currentClaiamble !== 0) {
            const txFinished = () => {
                setTxState('finished');
            }
            await withdrawExitStream(
                {
                    setTxHash: setCurrentTxHash,
                    setTxState: setTxState,
                    setTxError: () => {},
                    txFinished: txFinished,
                },
                signer,
                account
            );
        }
    }

    return (<>
        <Grid container sx={{ borderBottom: '1px solid #E1E8F7' }}>
            <Grid item xs={6} lg={2.25}>
                <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                    <Typography variant="subtitle2">
                        Current Exit Stream
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={6} lg={2}>
                <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                    <Typography variant="subtitle2">
                        Completion
                    </Typography>
                </Box>
            </Grid>
        </Grid>
        <Grid container sx={{ alignItems: 'center', borderBottom: '1px solid #E1E8F7' }}>
            <Grid item xs={6} lg={2.25}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '16px' }}>
                    <Typography variant="h4">
                        <DisplayValue2 value={currentClaiamble} />
                    </Typography>
                    <Typography variant="h4">
                        /<DisplayValue2 value={totalClaimable} />
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={6} lg={2}>
                <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                        <Moment unix format="ddd MMM D, YYYY" tz="UTC">
                            {exitStreamEndTime}
                        </Moment>
                    </Typography>
                </Box>
            </Grid>
            <Grid item lg={2}></Grid>
            <Grid item lg={2}></Grid>
            <Grid item xs={12} lg={2}>
                <Box sx={{ display: 'flex', flexDirection: 'row', padding: '16px' }}>
                    {txState === 'none' || txState === 'pending' || txState === 'confirm' ? <LoadingButton
                        content={`Claim`}
                        variant={"blue"}
                        onClick={handleClaim}
                        disabled={currentClaiamble === 0 || txState === 'pending' ? true : false}
                        loading={txState === 'pending' || txState === 'confirm'  ? true : false }
                    /> : <CompleteButton content={'Claimed'} />}
                </Box>
            </Grid>
            <Grid item lg={2}></Grid>
        </Grid>
        <TxModal txState={txState} currentTxHash={currentTxHash} setTxState={setTxState} />
    </>);
}

