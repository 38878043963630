import React, { useEffect, useState } from 'react';
import { Box, Link, Skeleton, TableCell, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import Moment from 'react-moment';
import 'moment-timezone';
import { DisplayValue2 } from '../../../../../components/DisplayValue';
import DisplayTable from '../../../../../components/DisplayTable';
import ModalHandler from '../../../../../components/ModalHandler';
import { ApprovalVotes } from '../../../../../providers/api-provider';
import { UserApprovalVote } from '../../../../../web3/types';
import ApprovalVoter from '../ApprovalVoter';
import DisplayPreciseNumber from '../../../../../components/DisplayPreciseNumber';
import { APPROVAL_VOTE_BIRBS } from '../../../../../global';

type Props = {
    approvalVotes: Array<ApprovalVotes> | undefined;
    userApprovalVotes: Array<UserApprovalVote>;
}

export default function VotesList({ approvalVotes, userApprovalVotes }: Props) {
    const [sortedVotes, setSortedVotes] = useState<ApprovalVotes[]>([])

    useEffect(() => {
        if (approvalVotes) {
            const sortedLocks = approvalVotes.sort((a, b) => b.index - a.index);
            setSortedVotes(sortedLocks);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approvalVotes]);

    const headings = APPROVAL_VOTE_BIRBS ?
        ['Pool', 'Total Bribes (USD)', '$/vlDDD', 'Required', 'Given', 'Expires', 'Status', 'Your Vote', ''] :
        ['Pool', 'Required', 'Given', 'Expires', 'Status', 'Your Vote', ''];

    const rows = sortedVotes.filter((av) => !av.hide).map((l, k) => {return {
        item: <>
            <TableCell>
                <Typography variant="h4">
                    <Link
                        sx={{
                            color: '#976CF3',
                            '&:hover': { color: '#976CF3' },
                            '& > svg > path': { fill: '#976CF3', fillOpacity: 'unset' }
                        }}
                        target="__blank"
                        rel="noreferrer"
                        href={`https://ellipsis.finance/pool/${l.pool}`}>
                        {l.symbol}
                    </Link>
                </Typography>
            </TableCell>
            {APPROVAL_VOTE_BIRBS ? <><TableCell>
                <Typography variant="h4">
                    $<DisplayValue2 value={l.totalIncentivesUSD} />
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="h4">
                    $<DisplayPreciseNumber precision={6} value={l.totalIncentivesUSD / l.dddGivenVotes} />
                </Typography>
            </TableCell></> : <></>}
            <TableCell>
                <Typography variant="h4">
                    <DisplayValue2 value={l.dddRequiredVotes} />
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="h4">
                    <DisplayValue2 value={l.dddGivenVotes} />
                </Typography>
            </TableCell>
            <TableCell>
                {l.expired ? 
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                        Finished
                    </Typography>
                : <Typography variant="h4" sx={{ color: '#4BC789', fontWeight: 600 }}>
                    <Moment unix format="ddd MMM D, YYYY" tz="UTC">
                        {l.startTime + 86400 * 7}
                    </Moment>
                </Typography>}
            </TableCell>
            <TableCell>
                <Typography variant="h4" sx={{
                    color: l.epxGivenVotes > l.epsRequiredVotes ? '#4BC789' : 'inherit',
                    fontWeight: 600
                }}>
                    {l.epxGivenVotes > l.epsRequiredVotes ? 'Passed' : l.expired ? 'Failed' : 'Voting'}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="h4">
                    <DisplayValue2 value={userApprovalVotes.length > 0 ? userApprovalVotes[l.index].usedVotes : 0} />
                </Typography>
            </TableCell>
            <TableCell>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <ModalHandler disabled={l.expired || (l.epxGivenVotes > l.epsRequiredVotes)} buttonType='blue' buttonTitle={'Vote'} modalTitle={`Vote for approval on ${l.symbol}`}>
                        <ApprovalVoter
                            name={l.symbol}
                            index={l.index}
                            availableVotes={userApprovalVotes.length > 0 ? userApprovalVotes[l.index].availableVotes : new BigNumber(0)}
                        />
                    </ModalHandler>
                </Box>
            </TableCell>
        </>,
        key: k.toString()
    }});

    return (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {approvalVotes !== undefined ? <DisplayTable headings={headings} rows={rows} /> : <>
            <Skeleton variant="rectangular" width={'100%'} height={10} sx={{ my: 2, borderRadius: '4px' }} />
            <Skeleton variant="rectangular" width={'100%'} height={10} sx={{ my: 2, borderRadius: '4px' }} />
        </>}
    </Box>);
}