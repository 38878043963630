import React, { ReactNode } from 'react';
import { Button, CircularProgress } from '@mui/material';

type Props = {
    variant?: 'text' | 'outlined' | 'contained' | 'blue' | 'white';
    content: ReactNode;
    disabled: boolean;
    onClick: () => void;
    loading: boolean;
}

export default function LoadingButton({ content, variant = 'outlined', disabled, onClick, loading }: Props) {
    return (<Button
        variant={variant}
        sx={{
            width: '100%',
            alignItems: 'center',
            display: 'flex'
        }}
        disabled={disabled || loading}
        onClick={onClick}
    >
        {loading ?
            <CircularProgress color="inherit" sx={{ ml: '-20px', mr: 0.5, height: '20px !important', width: '20px !important' }} /> : <></>}
            {content}
    </Button>);
}