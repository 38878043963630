import React from 'react';
import { Typography, Grid, Box, Tooltip } from '@mui/material';
import { DisplayValue2 } from '../../../../../components/DisplayValue';
import TokenIcon from '../../../../../components/TokenIcon';
import { DisplayLargeNumber2 } from '../../../../../components/DisplayLargeNumber';
import DisplayPreciseNumber from '../../../../../components/DisplayPreciseNumber';
import { HistoricalVote } from '../../HistoricalVotesHandler';

type Props = {
  votes: HistoricalVote
}

export default function TableItem({ votes }: Props) {
  return (<Grid
    container
    sx={{
      p: '5px',
      alignItems: 'center',
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
      }
    }}
  >
    <Grid item xs={4} lg={2}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TokenIcon address={votes.token} />
        <Tooltip title={votes.name} placement="top" arrow>
            <Typography variant="h4" sx={{ ml: 0.5 }}>
                {votes.symbol}
            </Typography>
        </Tooltip>
      </Box>
    </Grid>
    <Grid item xs={4} lg={2}>
      <Typography variant="h4">
        $<DisplayValue2 value={votes.totalBribesUSD} />
      </Typography>
    </Grid>
    <Grid item xs={4} lg={2}>
      <Typography variant="h4">
        $<DisplayPreciseNumber precision={5} value={votes.bribesPerVote}  />
      </Typography>
    </Grid>
    <Grid item xs={4} lg={2}>
      <Typography variant="h4">
        <DisplayLargeNumber2 value={votes.epxRewardsPerDay} />
      </Typography>
    </Grid>
    <Grid item xs={4} lg={2}>
      <Typography variant="h4">
        <DisplayLargeNumber2 value={votes.votes} />
      </Typography>
    </Grid>
    <Grid item xs={4} lg={2}>
      <Typography variant="h4">
        <DisplayValue2 value={votes.userVote} />
      </Typography>
    </Grid>
  </Grid>);
}
