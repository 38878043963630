import React, { useState } from 'react';
import BigNumber from 'bignumber.js';
import { Box, Button, Grid } from '@mui/material';
import { useOnboardProvider } from '../../../../../providers/onboard-provider';
import InputNumber from '../../../../../components/Inputs/InputNumber';
import CompleteButton from '../../../../../components/CompleteButton';
import LoadingButton from '../../../../../components/LoadingButton';
import Balance from '../../../../../components/Balance';
import TxModal from '../../../../../components/TxModal';
import { TxState, UserLpDeposit } from '../../../../../web3/types';
import { withdraw } from '../../../../../web3/LpDepositor/withdraw';

type Props = {
    userLpDeposit: UserLpDeposit;
}

export default function LpWithdraw({ userLpDeposit }: Props) {
    const { signer, account } = useOnboardProvider();

    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const [amount, setAmount] = useState<BigNumber>();
    const [inputValue, setInputValue] = useState<number | string>('');

    const handleSubmit = async () => {
        if (signer !== undefined && account !== undefined && account !== null && amount !== undefined) {
            const txFinished = () => {
                setInputValue('');
                setAmount(undefined);
                setTxState('finished');
            }
            await withdraw(
                {
                    setTxHash: setCurrentTxHash,
                    setTxState: setTxState,
                    setTxError: () => {},
                    txFinished: txFinished,
                },
                signer,
                account,
                userLpDeposit.token,
                amount
            );
        }
    }

    const setMaxAmount = (event: any) => {
        event.preventDefault();
        if (userLpDeposit.deposit) {
          setInputValue(userLpDeposit.deposit.toNumber() / 1e18)
          setAmount(userLpDeposit.deposit);
        }
    }

    return (<>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1, width: '100%' }}>
                <Button disableRipple onClick={setMaxAmount} variant="text" sx={{ p: 0 }}>
                    <Balance title={'Staked amount'} tokenBalance={userLpDeposit.deposit} tokenSymbol={userLpDeposit.symbol} />
                </Button>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Grid container columnSpacing={0.5} rowSpacing={0.5}>
                    <Grid item xs={12} lg={3}>
                        <InputNumber
                            assetSymbol={userLpDeposit.symbol}
                            maxAmount={userLpDeposit.deposit}
                            setRealInput={setAmount}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        {txState === 'none' || txState === 'pending' || txState === 'confirm' ? <LoadingButton
                            content={'Unstake'}
                            variant={"blue"}
                            onClick={handleSubmit}
                            disabled={userLpDeposit.deposit.isZero() || txState === 'pending' ? true : false || amount === undefined || amount.isZero()}
                            loading={txState === 'pending' ? true : false }
                        /> : <CompleteButton content={'LP tokens unstaked'} />}
                    </Grid>
                    <Grid item xs={12} lg={3}>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <TxModal txState={txState} currentTxHash={currentTxHash} setTxState={setTxState} />
    </>);
}