import React from 'react';
import './App.css';
import App from './App';
import { BlockNumberProvider } from './providers/blocknumber-provider';
import { ApiProvider } from './providers/api-provider';
import { ConnectedNetworkProvider } from './providers/connected-network-provider';
import { OnboardProvider } from './providers/onboard-provider';
import { SnackbarProvider } from './providers/snackbar-provider';
import { PRELAUNCH } from './global';
import { ReactComponent as Logo } from './images/dotdotcomingsoon/logo.svg';

function Main() {
    if (PRELAUNCH) {
        return (<div className="App" style={{
            background: 'linear-gradient(113.88deg, #976CF3 -31.83%, #1A2037 19.84%, #1A2037 77.73%, #00BFFC 156.55%)',
            position: 'relative',
            height: '100vh',
            width: '100vw',
            margin: 0,
            overflow: 'hidden'
        }}>
            <Logo width={'100%'} height={'100%'} />
        </div>);
    }

    return (<ConnectedNetworkProvider>
        <ApiProvider>
            <BlockNumberProvider>
                <OnboardProvider>
                    <SnackbarProvider>
                        <App />
                    </SnackbarProvider>
                </OnboardProvider>
            </BlockNumberProvider>
        </ApiProvider>
    </ConnectedNetworkProvider>);
}

export default Main;