import React, { useEffect, useState } from 'react';
import { Box, Skeleton, TableCell, Typography } from '@mui/material';
import { DisplayValue2 } from '../../../../../components/DisplayValue';
import { useOnboardProvider } from '../../../../../providers/onboard-provider';
import LoadingButton from '../../../../../components/LoadingButton';
import CompleteButton from '../../../../../components/CompleteButton';
import { FeeUserClaimable, TxState } from '../../../../../web3/types';
import DisplayTable from '../../../../../components/DisplayTable';
import TokenIcon from '../../../../../components/TokenIcon';
import TxModal from '../../../../../components/TxModal';
import { claim } from '../../../../../web3/Pool2/claim';

type Props = {
    earnings: Array<FeeUserClaimable>,
    earningsTotalUSD: number,
}

export default function ClaimableRewards({
    earnings,
    earningsTotalUSD,
}: Props) {
    const { signer, account } = useOnboardProvider();

    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const handleClaimRewards = async (token: string) => {
        if (signer !== undefined && account !== undefined && account !== null) {
            const txFinished = () => {
                setTxState('finished');
            }
            await claim(
                {
                    setTxHash: setCurrentTxHash,
                    setTxState: setTxState,
                    setTxError: () => {},
                    txFinished: txFinished,
                },
                signer,
                account,
            );
        }
    }
    const [sortedEarnings, setSortedEarnings] = useState<FeeUserClaimable[]>([])

    useEffect(() => {
        if (earnings) {
            const sortedEarnings = earnings.sort(
                (a, b) => (b.claimableUSD - a.claimableUSD)).filter((e) => e.claimable > 0.01
            );
            setSortedEarnings(sortedEarnings);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [earnings]);

    const headings = ['', 'Claimable (USD)', 'Amount', ''];

    const rows = sortedEarnings.map((e, k) => {return {
        item: <>
            <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TokenIcon address={e.feeToken} />
                    <Typography variant="h6" sx={{ ml: 0.5, fontSize: ['12px', '14px'] }}>
                        {e.feeSymbol}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Typography variant="h6">
                    $<DisplayValue2
                        value={e.claimableUSD}
                    />
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="h6">
                    <DisplayValue2 value={e.claimable} precision={6} />
                </Typography>
            </TableCell>
            <TableCell>
                <Box width={'60%'} margin={['0', 'auto']}>
                    {txState === 'none' || txState === 'pending' || txState === 'confirm' ? <LoadingButton
                        content={`Claim`}
                        variant={"blue"}
                        onClick={() => handleClaimRewards('')}
                        disabled={earningsTotalUSD === 0 || txState === 'pending' ? true : false}
                        loading={txState === 'pending' ? true : false }
                    /> : <CompleteButton content={`Claimed`} />}
                </Box>
            </TableCell>
            </>,
        key: e.feeToken
    }});

    return (<>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {earnings.length > 0 ? <DisplayTable headings={headings} rows={rows} pagination={false} /> : <>
                <Skeleton variant="rectangular" width={'100%'} height={10} sx={{ my: 2, borderRadius: '4px' }} />
                <Skeleton variant="rectangular" width={'100%'} height={10} sx={{ my: 2, borderRadius: '4px' }} />
            </>}
        </Box>
        <TxModal txState={txState} currentTxHash={currentTxHash} setTxState={setTxState} />
    </>);
}

