
import React from 'react';
import { Box, Typography } from '@mui/material';
import { getNetworkName, useConnectedNetworkContext } from '../../../providers/connected-network-provider';
import { ReactComponent as BscLogo } from '../../../images/bsc/logo.svg';

function ConnectedNetwork() {
    const { chainId } = useConnectedNetworkContext();
    return (
        <Box sx={{
            minHeight: '42px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: ['6px 14.4px', '10px 24px'],
            background: '#ffffff',
            borderRadius: '4px',
            mr: 1
        }}>
            <BscLogo />
            <Typography sx={{ ml: 1, color: '#1A2037', fontWeight: 500, fontSize: '16px', lineHeight: '20px', display: ['none', 'block'] }}>
                {getNetworkName(chainId)}
            </Typography>
        </Box>
    );
}

export default ConnectedNetwork;
