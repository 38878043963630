import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { Grid, Box, InputAdornment, Skeleton, TablePagination, TextField, Typography, Button, Link } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RemoveIcon from '@mui/icons-material/Remove';
import PoolTableItem from './PoolTableItem';
import { UserTokenVotes, UserVotes } from '../../../../../web3/types';
import BigNumber from 'bignumber.js';
import ModalHandler from '../../../../../components/ModalHandler';
import Voter from '../Voter';
import { LpToWeeklyAmountUSD, TokenVotes } from '../../../../../providers/api-provider';

type SorterButtonProps = {
    title: string;
    state: number;
    handleState: () => void;
    arrowSpace?: number;
};

function SorterButton({ title, handleState, state, arrowSpace }: SorterButtonProps) {
    return <Link onClick={handleState} sx={{ '&:hover': { cursor: 'pointer' } }}>
        <Typography variant="subtitle2" sx={{ position: 'relative', color: state !== 1 ? '#7251F5' : '#687692' }}>
            {title}
            {state === 2 ?
                <KeyboardArrowDownIcon fontSize='medium' sx={{ position: "absolute", left: arrowSpace }} /> :
                state === 3 ?
                <KeyboardArrowUpIcon fontSize='medium' sx={{ position: "absolute", left: arrowSpace }} /> :
                <RemoveIcon fontSize='small' sx={{ position: "absolute", left: arrowSpace }} />
            }
        </Typography>
    </Link>;
}

type PoolHeadersProps = {
    headers: Array<{
        title: string | ReactNode,
        spacing: number,
    }>;
}

function PoolTableHeaders({ headers }: PoolHeadersProps) {
    return (<Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            border: 'unset',
            boxShadow: 'unset',
            mb: 'unset'
        }}>
        <Grid container sx={{ paddingX: '24px' }}>
            {headers.map((h: any, k) =>
                <Grid item xs={h.spacing} key={k}>
                    {typeof(h.title) === 'string' ? <Typography variant="subtitle2">{h.title}</Typography> : h.title}
                </Grid>
            )}
        </Grid>
    </Box>);
}

type Props = {
    lpTokenVotes: Array<TokenVotes>;
    userAvailableVotes: BigNumber;
    userTokenVotes: UserTokenVotes;
    firstLoad: boolean;
    lpToWeeklyAmountUSD: LpToWeeklyAmountUSD;
}

const DEFAULT_LP_TOKENS_PER_PAGE = 5;

export default function LpTokenVotesVoteTable({ lpTokenVotes, userAvailableVotes, userTokenVotes, lpToWeeklyAmountUSD, firstLoad }: Props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_LP_TOKENS_PER_PAGE);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [searchLpTokenVotes, setSearchLpTokenVotes] = useState(lpTokenVotes);
    const [sortByBribes, setSortByBribes] = useState(1);
    const [sortByCurrentVotes, setSortByCurrentVotes] = useState(2);
    const [sortByYourVotes, setSortByYourVotes] = useState(1);

    const [searchText, setSearchText] = useState('');

    const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value)
        setSearchLpTokenVotes(lpTokenVotes.filter((p) => p.name.toLowerCase().includes(event.target.value.toLowerCase())));
    }

    useEffect(() => {
        setSearchLpTokenVotes(lpTokenVotes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstLoad]);

    const handleSetSortByBribes = () => {
        switch (sortByBribes) {
            case 1:
                setSortByBribes(2);
                break;
            case 2:
                setSortByBribes(3);
                break;
            default:
                setSortByBribes(1);
        }
    }

    const handleSetSortByCurrentVotes = () => {
        switch (sortByCurrentVotes) {
            case 1:
                setSortByCurrentVotes(2);
                break;
            case 2:
                setSortByCurrentVotes(3);
                break;
            default:
                setSortByCurrentVotes(1);
        }
    }

    const handleSetSortByYourVotes = () => {
        switch (sortByYourVotes) {
            case 1:
                setSortByYourVotes(2);
                break;
            case 2:
                setSortByYourVotes(3);
                break;
            default:
                setSortByYourVotes(1);
        }
    }

    const [userMaxVotes, setUserMaxVotes] = useState(userAvailableVotes);

    const [userVotes, setUserVotes] = useState<UserVotes>();

    const clearVotes = () => {
        setUserVotes(undefined);
    }

    useEffect(() => {
        if (userVotes && Object.keys(userVotes).length === 0) {
            clearVotes()
        }
    }, [userVotes]);

    useEffect(() => {
        if (userVotes !== undefined) {
            let total = new BigNumber(0);
            const voteKeys = Object.keys(userVotes);
            // eslint-disable-next-line array-callback-return
            voteKeys.map((k) => {
                if (!userVotes[k].amount.isZero()) {
                    total = total.plus(userVotes[k].amount);
                }
            });
            setUserMaxVotes(userAvailableVotes.minus(total));
        } else {
            setUserMaxVotes(userAvailableVotes);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userVotes]);

    return (<Box>
        <Box sx={{ mb: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', mr: 2 }}>
            <TextField
                sx={{ mr: 2 }}
                variant='outlined'
                id="filled-adornment-password"
                type={'text'}
                value={searchText}
                placeholder="Search Pools"
                onChange={handleChangeSearch}
                InputProps={{
                    endAdornment: (<InputAdornment position="end">
                        <SearchIcon />
                    </InputAdornment>)
                }}
            />
            
        </Box>
        
        <PoolTableHeaders headers={[
            {
                title: 'Pool',
                spacing: 1.7
            },
            {
                title: <SorterButton title='Total Bribes (USD)' handleState={handleSetSortByBribes} state={sortByBribes} arrowSpace={115} />,
                spacing: 1.7
            },
            {
                title: 'Bribe/vlDDD',
                spacing: 1.7
            },
            {
                title: 'Projected EPX/day',
                spacing: 1.7
            },
            {
                title: <SorterButton title='Current Votes' handleState={handleSetSortByCurrentVotes} state={sortByCurrentVotes} arrowSpace={90} />,
                spacing: 1.7
            },
            {
                title: <SorterButton title='Your Votes' handleState={handleSetSortByYourVotes} state={sortByYourVotes} arrowSpace={70} />,
                spacing: 1.7
            },
        ]} />
        {lpTokenVotes.length > 0 ?
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid container sx={{ height: rowsPerPage === 5 ? '350px' : rowsPerPage === 10 ? '720px' : '350px' }}>
                    {searchLpTokenVotes
                        .sort((a, b) =>
                            sortByCurrentVotes === 2 ? b.votes - a.votes :
                            sortByCurrentVotes === 3 ? a.votes - b.votes :
                            0
                        )
                        .sort((a, b) => lpToWeeklyAmountUSD[b.token] && lpToWeeklyAmountUSD[a.token] ?
                                sortByBribes === 2 ? lpToWeeklyAmountUSD[b.token].totalBribes - lpToWeeklyAmountUSD[a.token].totalBribes :
                                sortByBribes === 3 ? lpToWeeklyAmountUSD[a.token].totalBribes - lpToWeeklyAmountUSD[b.token].totalBribes :
                                0
                            : 0
                        )
                        .sort((a, b) => userTokenVotes[a.token] !== undefined && userTokenVotes[b.token] !== undefined ?
                                sortByYourVotes === 2 ? userTokenVotes[b.token] - userTokenVotes[a.token] :
                                sortByYourVotes === 3 ? userTokenVotes[a.token] - userTokenVotes[b.token] :
                                0
                            : 0
                        )
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((p, k) => (
                            <Grid item xs={12} key={k} sx={{
                                borderTop: '1px solid #A7B3CE',
                                padding: '8px 24px',
                                '&:last-child': {
                                    borderBottom: '1px solid #A7B3CE'
                                }
                            }}>
                                <PoolTableItem
                                    page={page}
                                    userMaxVotes={userMaxVotes}
                                    lpToken={p}
                                    userVotes={userVotes}
                                    setUserVotes={setUserVotes}
                                    existingVote={userTokenVotes[p.token] ? userTokenVotes[p.token] : 0}
                                    totalBribes={lpToWeeklyAmountUSD[p.token] ? lpToWeeklyAmountUSD[p.token].totalBribes : 0}
                                    bribesPerVote={lpToWeeklyAmountUSD[p.token] && p.votes > 0 ? (lpToWeeklyAmountUSD[p.token].totalBribes / p.votes) : 0}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                <Box sx={{ marginTop: '24px', paddingX: '24px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <TablePagination
                        sx={{
                            '& > div > p.MuiTablePagination-displayedRows': { fontSize: '14px' },
                            '& > div > div > .MuiTablePagination-select': { paddingRight: '24px !important' }
                        }}
                        rowsPerPageOptions={[5, 10]}
                        labelRowsPerPage=""
                        component="div"
                        count={searchLpTokenVotes.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <Box sx={{ display: 'flex', '& > button': { width: '200px' } }}>
                        <Button sx={{ mr: 1 }} variant="outlined" onClick={clearVotes}>Clear votes</Button>
                        <ModalHandler buttonType='blue' buttonTitle={'Vote'} disabled={userVotes === undefined}>
                            <Voter userVotes={userVotes} setUserVotes={setUserVotes} />
                        </ModalHandler>
                    </Box>
                </Box>
            </Box>
        : <>
            <Skeleton variant="rectangular" width={'100%'} height={50} sx={{ borderRadius: '4px' }} />
            <Skeleton variant="rectangular" width={'100%'} height={50} sx={{ my: 2, borderRadius: '4px' }} />
            <Skeleton variant="rectangular" width={'100%'} height={50} sx={{ borderRadius: '4px' }} />
        </>}
    </Box>);
}
