import React, { ReactNode } from 'react';
import { Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type Props = {
    content: ReactNode;
}

export default function CompleteButton({ content }: Props) {
    return (<Button
        sx={{
            width: '100%',
            opacity: '1 !important',
            color: '#976CF3 !important',
            border: '1px solid #976CF3 !important',
            background: 'rgba(151, 108, 243, 0.2)',
            alignItems: 'center',
            display: 'flex',
        }}
        disabled={true}
    >
        <CheckCircleIcon fontSize="inherit" color="inherit" sx={{ color: '#976CF3', mr: 0.5 }} />{content}
    </Button>);
}