import React from 'react';
import { Box, Button, Grid, Skeleton, Tooltip, Typography, useTheme, useMediaQuery } from '@mui/material';
import BigNumber from 'bignumber.js';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RemoveIcon from '@mui/icons-material/Remove';
import DisplayValue, { DisplayValue2 } from '../../../../../components/DisplayValue';
import DisplayTableAccordian from '../../../../../components/DisplayTableAccordian';
import LpControls from '../LpControls';
import { LpDetails } from '../../../../../providers/api-provider';
import { DisplayLargeNumber2 } from '../../../../../components/DisplayLargeNumber';
import { UserLpDeposts } from '../../../../../web3/types';
import TokenIcon from '../../../../../components/TokenIcon';

type SorterButtonProps = {
    title: string;
    state: number;
    handleState: () => void;
    arrowSpace?: number;
};

function SorterButton({ title, handleState, state, arrowSpace }: SorterButtonProps) {
    return <Button variant="sort" disableRipple onClick={handleState}>
        <Typography variant="subtitle2" sx={{ position: 'relative', color: state !== 1 ? '#7251F5' : '#687692' }}>
            {title}
            {state === 2 ?
                <KeyboardArrowDownIcon fontSize='medium' sx={{ position: "absolute", left: arrowSpace }} /> :
                state === 3 ?
                <KeyboardArrowUpIcon fontSize='medium' sx={{ position: "absolute", left: arrowSpace }} /> :
                <RemoveIcon fontSize='small' sx={{ position: "absolute", left: arrowSpace }} />
            }
        </Typography>
    </Button>;
}

type Props = {
    lpDeposits: Array<LpDetails> | undefined;
    userLpDeposits: UserLpDeposts;
    withBonus: boolean;
    tvlState: number;
    handleSetSortByTVL: () => void;
    aprState: number;
    handleSetSortByAPR: () => void;
    depositState: number;
    handleSetSortByDeposits: () => void;
    balanceState: number;
    handleSetSortByBalance: () => void;
}

export default function LpDepositsList({
    lpDeposits,
    userLpDeposits,
    withBonus,
    tvlState,
    handleSetSortByTVL,
    aprState,
    handleSetSortByAPR,
    depositState,
    handleSetSortByDeposits,
    balanceState,
    handleSetSortByBalance,
}: Props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const headings = isMobile ? 
        [
            <SorterButton title='Wallet Balance' handleState={handleSetSortByBalance} state={balanceState} arrowSpace={95} />,
            <SorterButton title='Your Deposits' handleState={handleSetSortByDeposits} state={depositState} arrowSpace={90} />,
            <SorterButton title='TVL' handleState={handleSetSortByTVL} state={tvlState} arrowSpace={25} />,
            <SorterButton title='vAPR' handleState={handleSetSortByAPR} state={aprState} arrowSpace={35} />,
        ] : [
            '',
            <SorterButton title='Wallet Balance' handleState={handleSetSortByBalance} state={balanceState} arrowSpace={95} />,
            <SorterButton title='Your Deposits' handleState={handleSetSortByDeposits} state={depositState} arrowSpace={90} />,
            'Claimable (USD)',
            <SorterButton title='TVL' handleState={handleSetSortByTVL} state={tvlState} arrowSpace={25} />,
            <SorterButton title='vAPR' handleState={handleSetSortByAPR} state={aprState} arrowSpace={35} />,
            'Boost'
        ];

    const rows = lpDeposits !== undefined ? lpDeposits.map((l, k) => {return {
        item: <Grid container sx={{ alignItems: ['flex-start', 'center'], ml: ['16px', 'unset'] }}>
            <Grid item lg={1.7} xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: ['flex-start', 'center'], mb: ['8px', 'unset'] }}>
                    <TokenIcon address={l.token} />
                    <Tooltip title={l.name} placement="top" arrow>
                        <Typography variant="h4" sx={{ width: '100px' }}>
                            {l.symbol}
                        </Typography>
                    </Tooltip>
                </Box>
            </Grid>
            <Grid item xs={6} sx={{ display: ['flex', 'none'] }}>
                <Typography variant="h4">
                    Wallet Balance:
                </Typography>
            </Grid>
            <Grid item lg={1.7} xs={6}>
                <Typography variant="h4" sx={{ textAlign: ['left', 'inherit'] }}>
                    <DisplayValue value={userLpDeposits[l.token] !== undefined ? userLpDeposits[l.token].balance : new BigNumber(0)} decimals={1e18} />
                </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: ['flex', 'none'] }}>
                <Typography variant="h4">
                    Your Deposit:
                </Typography>
            </Grid>
            <Grid item lg={1.7} xs={6}>
                <Typography variant="h4" sx={{ textAlign: ['left', 'inherit'] }}>
                    $<DisplayValue2 value={userLpDeposits[l.token] !== undefined ? userLpDeposits[l.token].depositUSD : 0} />
                </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: ['flex', 'none'] }}>
                <Typography variant="h4">
                    Claimable (USD):
                </Typography>
            </Grid>
            <Grid item lg={1.7} xs={6}>
                <Tooltip title={<>
                    DDD: <DisplayLargeNumber2 value={userLpDeposits[l.token] !== undefined ? userLpDeposits[l.token].dddClaimable : 0} /><br />
                    EPX: <DisplayLargeNumber2 value={userLpDeposits[l.token] !== undefined ? userLpDeposits[l.token].epxClaimable : 0} /><br />
                    {userLpDeposits[l.token] !== undefined ? userLpDeposits[l.token].extraClaimable.map((ec, k) => <div key={k}>
                        {ec.symbol}: <DisplayLargeNumber2 value={ec.amount} /><br />
                    </div>) : <></>}
                </>} placement="top" arrow>
                    <Typography variant="h4" sx={{ textAlign: ['left', 'inherit'] }}>
                        $<DisplayValue2 value={userLpDeposits[l.token] !== undefined ? userLpDeposits[l.token].totalClaimableUSD : 0} />
                    </Typography>
                </Tooltip>
            </Grid>
            <Grid item xs={6} sx={{ display: ['flex', 'none'] }}>
                <Typography variant="h4">
                    TVL:
                </Typography>
            </Grid>
            <Grid item lg={1.7} xs={6}>
                <Tooltip title={<>
                    DDD Share: <DisplayLargeNumber2 value={l.dddPercentage} />%<br />
                    DDD TVL: $<DisplayLargeNumber2 value={l.dddTvlUSD} /><br />
                    EPS TVL: $<DisplayLargeNumber2 value={l.epsTvlUSD} />
                </>} placement="top" arrow>
                    <Typography variant="h4" sx={{ textAlign: ['left', 'inherit'] }}>
                        $<DisplayLargeNumber2 value={l.dddTvlUSD} />
                    </Typography>
                </Tooltip>
            </Grid>
            <Grid item xs={6} sx={{ display: ['flex', 'none'] }}>
                <Typography variant="h4">
                    vAPR:
                </Typography>
            </Grid>
            <Grid item lg={1.7} xs={6}>
                <Tooltip title={<>
                    Base APY: <DisplayLargeNumber2 value={l.baseApr} />%<br />
                    DDD APR: <DisplayLargeNumber2 value={l.realDddAPR} />%<br />
                    EPX APR: <DisplayLargeNumber2 value={l.realEpxAPR} />%<br />
                    {l.extraRewards.map((er, k) => <div key={k}>
                        {er.symbol} APR: <DisplayLargeNumber2 value={er.apr} />%<br />
                    </div>)}
                </>} placement="top" arrow>
                    <Typography variant="h4" sx={{ textAlign: ['left', 'inherit'] }}>
                        <DisplayLargeNumber2 value={l.realDddAPR + l.realEpxAPR + l.extraRewardsTotalApr + l.baseApr} />%
                    </Typography>
                </Tooltip>
            </Grid>
            <Grid item xs={6} sx={{ display: ['flex', 'none'] }}>
                <Typography variant="h4">
                    Boost:
                </Typography>
            </Grid>
            <Grid item lg={1.7} xs={6}>
                <Typography variant="h4" sx={{ textAlign: ['left', 'inherit'] }}>
                    <DisplayValue2 value={l.boost} />x
                </Typography>
            </Grid>
        </Grid>,
        key: k.toString(),
        content: <LpControls
            withBonus={withBonus}
            lp={l}
            userLpDeposit={userLpDeposits[l.token] !== undefined ? userLpDeposits[l.token] : {
                symbol: '',
                token: '',
                balance: new BigNumber(0),
                allowance: new BigNumber(0),
                deposit: new BigNumber(0),
                depositUSD: 0,
                epxClaimable: 0,
                epxClaimableUSD: 0,
                dddClaimable: 0,
                dddClaimableUSD: 0,
                totalBaseClaimableUSD: 0,
                totalExtraClaimableUSD: 0,
                totalClaimableUSD: 0,
                extraClaimable: [],
            }}
        />
    }}) : [];

    return (<Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {rows !== undefined ? <DisplayTableAccordian headings={headings} rows={rows} headingSpace={1.7} /> : <>
            <Skeleton variant="rectangular" width={'100%'} height={10} sx={{ my: 2, borderRadius: '4px' }} />
            <Skeleton variant="rectangular" width={'100%'} height={10} sx={{ my: 2, borderRadius: '4px' }} />
        </>}
    </Box>);
}