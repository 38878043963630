import React, { useState } from 'react';
import CompleteButton from '../CompleteButton';
import LoadingButton from '../LoadingButton';
import { useOnboardProvider } from '../../providers/onboard-provider';
import { TxState } from '../../web3/types';
import { approveTokenSpend } from '../../web3/approveTokenSpend';
import TxModal from '../TxModal';

type Props = {
    isApproved: boolean;
    token: string;
    contract: string;
}

export default function ApproveButton({ isApproved, token, contract }: Props) {
    const { signer, account } = useOnboardProvider();

    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const handleApprove = async () => {
        if (signer && account !== undefined && account !== null) {
            const txFinished = () => {
                setTxState('finished');
            }
            await approveTokenSpend(
                {
                    setTxHash: setCurrentTxHash,
                    setTxState: setTxState,
                    setTxError: () => {},
                    txFinished: txFinished,
                },
                account,
                signer,
                token,
                contract
            );
        }
    }

    return (<>{isApproved ? 
        <CompleteButton content={'Approved'} /> :
        <LoadingButton
            content={'Approve'}
            variant="blue"
            disabled={isApproved || txState === 'pending' || txState === 'confirm' ? true : false}
            onClick={handleApprove}
            loading={txState === 'pending' || txState === 'confirm' ? true : false }
        />
    }
    <TxModal txState={txState} currentTxHash={currentTxHash} setTxState={setTxState} />
    </>);
}