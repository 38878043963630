import React from 'react';
import ExtendLockToken from '../ExtendLockToken';
import { UserLock } from '../../../../../web3/types';
import ModalHandler from '../../../../../components/ModalHandler';

type Props = {
    lock: UserLock;
}

export default function ReLockModal({ lock }: Props) {
    return (<ModalHandler buttonType='blue' buttonTitle={'Extend Lock'} modalTitle={`Extend Lock`}>
        <ExtendLockToken lock={lock} />
    </ModalHandler>);
}