import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Link, Typography } from '@mui/material';
import { DisplayLargeNumber2 } from '../DisplayLargeNumber';
import { useApiDataContext } from '../../providers/api-provider';
import { ReactComponent as AllStatsLogo } from '../../images/allstats/logo.svg'; 
import { ReactComponent as LinkLogo } from '../../images/link/logo.svg'; 
import { ReactComponent as MetamaskLogo } from '../../images/metamask/logo.svg';
import { useOnboardProvider } from '../../providers/onboard-provider';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import ERC20Abi from '../../abi/ERC20.json';
import DDDLockerAbi from '../../abi/DDDLocker.json';
import BigNumber from 'bignumber.js';
import { CONFIG } from '../../global';
import { DisplayValue2 } from '../DisplayValue';
import TokenIcon from '../TokenIcon';
import DisplayPreciseNumber from '../DisplayPreciseNumber';

export interface WatchAssetParams {
    type: string;
    options: {
        address: string;
        symbol: string;
        decimals: number;
        image: string;
    }
}

const getAsset = (id: string): WatchAssetParams => {
    switch (id) {
        case 'DDD':
            return {
                type: 'ERC20',
                options: {
                    address: CONFIG.Token,
                    symbol: 'DDD',
                    decimals: 18,
                    image: `https://dotdot.finance/ddd.svg`,
                },
            };
        case 'dEPX':
            return {
                type: 'ERC20',
                options: {
                    address: CONFIG.LockedEPX,
                    symbol: 'dEPX',
                    decimals: 18,
                    image: `https://dotdot.finance/depx.svg`,
                },
            };
        default:
            return {
                type: 'ERC20',
                options: {
                    address: CONFIG.Token,
                    symbol: 'DDD',
                    decimals: 18,
                    image: `https://dotdot.finance/ddd.svg`,
                },
            };
    }
}

export default function AllStats() {
    const { tokenStats, tvls, dddPrice, voterData } = useApiDataContext();

    const { account } = useOnboardProvider();
    const [balance, setBalance] = useState<number>(0);
    const [locked, setLocked] = useState<number>(0);
    const doLoad = async (acc: string | undefined | null) => {
        if (acc !== undefined && acc != null) {
            const web3 = new Web3(CONFIG.rpc);
            const ddd = new web3.eth.Contract(ERC20Abi as AbiItem[], CONFIG.Token)
            const dddLocker = new web3.eth.Contract(DDDLockerAbi as AbiItem[], CONFIG.DDDLocker)
            const locked = await dddLocker.methods.userBalance(acc).call();
            const balance = await ddd.methods.balanceOf(acc).call();
            setBalance(new BigNumber(balance).dividedBy(1e18).toNumber());
            setLocked(new BigNumber(locked).dividedBy(1e18).toNumber());
        }
    }

    useEffect(() => {
        doLoad(account);
    }, [account])

    useEffect(() => {
        const interval = setInterval(() => doLoad(account), 60000);
        return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    const handeAddAsset = (e: any, assetID: string) => {
        e.stopPropagation();
        (global.window as any)?.ethereum?.request({
            method: 'wallet_watchAsset',
            params: getAsset(assetID),
        })
        .catch((error: any) => {
            console.error(error);
        });
    }

    return (<Box sx={{
        padding: '20px 32px',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        width: '100%',
        alignItems: 'center'
    }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', marginBottom: ['14px', '22px'] }}>
            <Typography sx={{ color: '#000000', fontWeight: 700, fontSize: ['18px', '20px'], lineHeight: '160%' }}>
                Dot Dot Overview
            </Typography>
        </Box>
        <Box sx={{ marginBottom: ['20px', '32px'] }}>
            <AllStatsLogo />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography sx={{ color: '#000000', fontWeight: 400, fontSize: ['16px', '20px'], lineHeight: '32px', opacity: '0.8' }}>
                Wallet Balance
            </Typography>
            <Typography sx={{ color: '#000000', fontWeight: 700, fontSize: ['16px', '20px'], lineHeight: '160%' }}>
                <DisplayValue2 value={balance} /> DDD
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography sx={{ color: '#000000', fontWeight: 400, fontSize: ['16px', '20px'], lineHeight: '32px', opacity: '0.8' }}>
                DDD Locked
            </Typography>
            <Typography sx={{ color: '#000000', fontWeight: 700, fontSize: ['16px', '20px'], lineHeight: '160%' }}>
                <DisplayValue2 value={locked} /> DDD
            </Typography>
        </Box>
        <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.23)', width: '100%', opacity: '0.23', padding: '0.1px', marginY: '12px' }} />
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography sx={{ color: '#000000', fontWeight: 400, fontSize: ['16px', '20px'], lineHeight: '32px', opacity: '0.8' }}>
                Platform TVL
            </Typography>
            <Typography sx={{ color: '#000000', fontWeight: 700, fontSize: ['16px', '20px'], lineHeight: '160%' }}>
                $<DisplayLargeNumber2 value={tvls.totalTvl} />
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography sx={{ color: '#000000', fontWeight: 400, fontSize: ['16px', '20px'], lineHeight: '32px', opacity: '0.8' }}>
                Total Locked DDD
            </Typography>
            <Typography sx={{ color: '#000000', fontWeight: 700, fontSize: ['16px', '20px'], lineHeight: '160%' }}>
                <DisplayLargeNumber2 value={tokenStats.dddSupplyLocked / tokenStats.dddTotalSupply * 100} />%
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography sx={{ color: '#000000', fontWeight: 400, fontSize: ['16px', '20px'], lineHeight: '32px', opacity: '0.8' }}>
                DDD Price
            </Typography>
            <Typography sx={{ color: '#976CF3', fontWeight: 700, fontSize: ['16px', '20px'], lineHeight: '160%' }}>
                <Link
                    sx={{ color: '#976CF3', '&:hover': { color: '#976CF3' } }}
                    href={'https://www.coingecko.com/en/coins/dot-dot-finance'}
                    target="__blank"
                    rel="noreferrer">
                    $<DisplayPreciseNumber value={dddPrice} precision={3} />&nbsp;
                    <LinkLogo />
                </Link>
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography sx={{ color: '#000000', fontWeight: 400, fontSize: ['16px', '20px'], lineHeight: '32px', opacity: '0.8' }}>
                DDD in circulation
            </Typography>
            <Typography sx={{ color: '#000000', fontWeight: 700, fontSize: ['16px', '20px'], lineHeight: '160%' }}>
                <DisplayLargeNumber2 value={tokenStats.dddTotalSupply} />
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography sx={{ color: '#000000', fontWeight: 400, fontSize: ['16px', '20px'], lineHeight: '32px', opacity: '0.8' }}>
                Total supply
            </Typography>
            <Typography sx={{ color: '#000000', fontWeight: 700, fontSize: ['16px', '20px'], lineHeight: '160%' }}>
                <Link
                    sx={{ color: '#000000' }}
                    href={'https://docs.dotdot.finance/about/tokenomics#ddd-token'}
                    target="__blank"
                    rel="noreferrer">
                    n/a&nbsp;
                    <LinkLogo />
                </Link>
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography sx={{ color: '#000000', fontWeight: 400, fontSize: ['16px', '20px'], lineHeight: '32px', opacity: '0.8' }}>
                vlEPX Dominance
            </Typography>
            <Typography sx={{ color: '#000000', fontWeight: 700, fontSize: ['16px', '20px'], lineHeight: '160%' }}>
                <DisplayLargeNumber2 value={tokenStats.vlEPXdominance} />%
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography sx={{ color: '#000000', fontWeight: 400, fontSize: ['16px', '20px'], lineHeight: '32px', opacity: '0.8' }}>
                Current vote ratio
            </Typography>
            <Typography sx={{ color: '#000000', fontWeight: 700, fontSize: ['16px', '20px'], lineHeight: '160%' }}>
                1 DDD = <DisplayLargeNumber2 value={tokenStats.dddEllipsisWeight / tokenStats.votingPower} /> vlEPX
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography sx={{ color: '#000000', fontWeight: 400, fontSize: ['16px', '20px'], lineHeight: '32px', opacity: '0.8' }}>
                Weekly vote ratio
            </Typography>
            <Typography sx={{ color: '#000000', fontWeight: 700, fontSize: ['16px', '20px'], lineHeight: '160%' }}>
                1 DDD = <DisplayLargeNumber2 value={voterData.currentVoteRatio} /> vlEPX
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '32px' }}>
            <Button variant="blue" sx={{
                width: ['100%', '60%'],
                color: '#976CF3',
                background: 'rgba(114, 81, 245, 0.2)',
                border: 'unset',
                '&:hover': {
                    border: 'unset',
                    color: '#976CF3',
                    background: 'rgba(114, 81, 245, 0.2)'
                },
                mb: '8px'
            }} onClick={(e: any) => handeAddAsset(e, 'DDD')}>
                <TokenIcon address={CONFIG.Token} />&nbsp;
                Add DDD to your Metamask&nbsp;
                <MetamaskLogo />
            </Button>
            <Button variant="blue" sx={{
                width: ['100%', '60%'],
                color: '#976CF3',
                background: 'rgba(114, 81, 245, 0.2)',
                border: 'unset',
                '&:hover': {
                    border: 'unset',
                    color: '#976CF3',
                    background: 'rgba(114, 81, 245, 0.2)'
                }
            }} onClick={(e: any) => handeAddAsset(e, 'dEPX')}>
                <TokenIcon address={CONFIG.LockedEPX} />&nbsp;
                Add dEPX to your Metamask&nbsp;
                <MetamaskLogo />
            </Button>
        </Box>
    </Box>);
}