
import React from 'react';

import { Button } from '@mui/material';

import { useOnboardProvider } from '../../../providers/onboard-provider';

function ConnectWallet() {
  const { active, connectWallet, disconnectWallet } = useOnboardProvider();
  return (
    <Button
      variant="connect"
      onClick={active ? disconnectWallet : connectWallet}>
        {active ? 'Disconnect Wallet' : 'Connect Wallet'}
    </Button>
  );
}

export default ConnectWallet;
