import * as React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Box } from '@mui/material';

type Props = {
    open: boolean;
    toggleDrawer: () => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

export default function MobileDrawer({ open, toggleDrawer, children }: React.PropsWithChildren<Props>) {
    return (<SwipeableDrawer
            anchor={'right'}
            open={open}
            onClose={toggleDrawer()}
            onOpen={toggleDrawer()}
        >
            <Box
                sx={{ paddingTop: '72px', width: 250 }}
                role="presentation"
                onClick={toggleDrawer()}
                onKeyDown={toggleDrawer()}
                >
                {children}
            </Box>
        </SwipeableDrawer>
    );
}
