
import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import Multicall from '@dopex-io/web3-multicall';
import { AbiItem } from 'web3-utils';
import ConvertEPX from './components/ConvertEPX';
import TabsPanel from '../../../components/TabsPanel';
import Unbond from './components/Unbond';
import BonddEPX from './components/BonddEPX';
import ClaimUnbonding from './components/ClaimUnbonding';
import CardTitle from '../../../components/CardTitle';
import StatsTable from '../../../components/StatsTable';
import { FeeToken, useApiDataContext } from '../../../providers/api-provider';
import { useOnboardProvider } from '../../../providers/onboard-provider';
import { CONFIG } from '../../../global';
import BondedFeeDistributorAbi from '../../../abi/BondedFeeDistributor.json';
import LockedEPXAbi from '../../../abi/LockedEPX.json';
import ERC20Abi from '../../../abi/ERC20.json';
import { BondingStream, FeeUserClaimable } from '../../../web3/types';
import ClaimableRewards from './components/ClaimableRewards';
import ClaimableTradingFees from './components/ClaimableTradingFees';
import TopStatsTable from '../../../components/TopStatsTable';

const loadRewardsData = async (user: string, feeTokens: FeeToken[], epxPrice: number, dddPrice: number) => {
    console.log(`loading claimable depx data for ${user}`);
    const web3 = new Web3(CONFIG.rpc);

    const bonbedDistro = new web3.eth.Contract(BondedFeeDistributorAbi as AbiItem[], CONFIG.BondedFeeDistributor);

    const feeTokenAddresses = feeTokens.map((ft) => ft.address);

    const claimable = await bonbedDistro.methods.claimable(user, feeTokenAddresses).call();

    let earningsUSD = 0;
    let rewards: FeeUserClaimable[] = [];
    let tradingFees: FeeUserClaimable[] = [];

    for (let i = 0; i < feeTokenAddresses.length; i++) {
        if (feeTokenAddresses[i] === CONFIG.EPX.toLowerCase()) {
            const claimableAmount = new BigNumber(claimable[i]).dividedBy(10 ** 18).toNumber();
            const claimableUSD = claimableAmount * epxPrice;
            rewards.push({
                lpSymbol: '',
                lpToken: '',
                feeToken: CONFIG.EPX,
                feeSymbol: 'EPX',
                claimable: claimableAmount,
                claimableUSD: claimableUSD
            });
            earningsUSD += claimableUSD;
            continue;
        }
        if (feeTokenAddresses[i] === CONFIG.Token.toLowerCase()) {
            const claimableAmount = new BigNumber(claimable[i]).dividedBy(10 ** 18).toNumber();
            const claimableUSD = claimableAmount * dddPrice;
            rewards.push({
                lpSymbol: '',
                lpToken: '',
                feeToken: CONFIG.Token,
                feeSymbol: 'DDD',
                claimable: claimableAmount,
                claimableUSD: claimableUSD
            });
            earningsUSD += claimableUSD;
            continue;
        }
        const claimableAmount = new BigNumber(claimable[i]).dividedBy(10 ** feeTokens[i].decimals).toNumber();
        // fix for feeTokens[i].price undefined
        const claimableUSD = (typeof feeTokens[i].price != 'undefined') ? claimableAmount * feeTokens[i].price:0;

        tradingFees.push({
            lpSymbol: '',
            lpToken: '',
            feeToken: feeTokenAddresses[i],
            feeSymbol: feeTokens[i].symbol,
            claimable: claimableAmount,
            claimableUSD: claimableUSD
        });
        earningsUSD += claimableUSD;
    }

    return {
        rewards: rewards,
        tradingFees: tradingFees,
        earningsUSD: earningsUSD
    }
}


const loadData = async (user: string) => {
    console.log(`loading depx data for ${user}`);
    const web3 = new Web3(CONFIG.rpc);

    const multicall = new Multicall({
        multicallAddress: CONFIG.multicall,
        provider: CONFIG.rpc,
    });

    const bonbedDistro = new web3.eth.Contract(BondedFeeDistributorAbi as AbiItem[], CONFIG.BondedFeeDistributor);
    const lockedEPX = new web3.eth.Contract(LockedEPXAbi as AbiItem[], CONFIG.LockedEPX);
    const epx = new web3.eth.Contract(ERC20Abi as AbiItem[], CONFIG.EPX);

    const userCalls = [
        epx.methods.balanceOf(user),
        epx.methods.allowance(user, CONFIG.LockedEPX),
        lockedEPX.methods.balanceOf(user),
        lockedEPX.methods.allowance(user, CONFIG.BondedFeeDistributor),
        bonbedDistro.methods.bondedBalance(user),
        bonbedDistro.methods.unbondableBalance(user),
        bonbedDistro.methods.streamingBalances(user),
    ];

    const [
        epxBalance,
        epxAllowance,
        balance,
        allowance,
        bondedBalance,
        unbondableBalance,
        activeStream,
    ] = await multicall.aggregate(userCalls);

    return {
        epxBalance: new BigNumber(epxBalance),
        epxAllowance: new BigNumber(epxAllowance),
        balance: new BigNumber(balance),
        allowance: new BigNumber(allowance),
        activeStream: { claimable: new BigNumber(activeStream[0]), total: new BigNumber(activeStream[1]) } as BondingStream,
        bondedBalance: new BigNumber(bondedBalance),
        unbondableBalance: new BigNumber(unbondableBalance),
    }
}

function StakeDepx() {
    const { depxData, tokenStats, epxPrice, dddPrice, depxPrice } = useApiDataContext();

    const { account } = useOnboardProvider();

    const [epxBalance, setEpxBalance] = useState<BigNumber>(new BigNumber(0));
    const [epxAllowance, setEpxAllowance] = useState<BigNumber>(new BigNumber(0));
    const [balance, setBalance] = useState<BigNumber>(new BigNumber(0));
    const [allowance, setAllowance] = useState<BigNumber>(new BigNumber(0));
    const [bondedBalance, setBondedBalance] = useState<BigNumber>(new BigNumber(0));
    const [unbondableBalance, setUnbondableBalance] = useState<BigNumber>(new BigNumber(0));
    const [activeStream, setActiveStream] = useState<BondingStream>(
        { claimable: new BigNumber(0), total: new BigNumber(0) } 
    );
    const [rewards, setRewards] = useState<FeeUserClaimable[]>([]);
    const [tradingFees, setTradingFees] = useState<FeeUserClaimable[]>([]);
    const [earningsUSD, setEarningsUSD] = useState<number>(0);

    const doAction = async (acc: string | undefined | null, feeTokens: FeeToken[]) => {
        if (acc !== undefined && acc != null) {
            try {
                const { epxBalance, epxAllowance, balance, allowance, activeStream, bondedBalance, unbondableBalance } = await loadData(acc);
                setEpxBalance(epxBalance);
                setEpxAllowance(epxAllowance);
                setBalance(balance);
                setAllowance(allowance);
                setActiveStream(activeStream);
                setBondedBalance(bondedBalance);
                setUnbondableBalance(unbondableBalance);
                const { rewards, tradingFees, earningsUSD } = await loadRewardsData(acc, feeTokens, epxPrice, dddPrice);
                setRewards(rewards);
                setTradingFees(tradingFees);
                setEarningsUSD(earningsUSD);
            } catch (e) {
                console.error(e);
            }
        }
    }

    useEffect(() => {
        doAction(account, depxData.feeTokens);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    useEffect(() => {
        const interval = setInterval(() => doAction(account, depxData.feeTokens), 5000);
        return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    const stats1 = [
        {
            title: 'Your staked dEPX',
            value: (bondedBalance.toNumber() / 1e18) * depxPrice,
            compact: true,
            width: '50%',
            symbolFront: '$',
            subValue: bondedBalance.toNumber() / 1e18,
            subSymbolBack: ' dEPX',
            subCompact: true,
        },
        {
            title: 'Claimable',
            value: earningsUSD,
            compact: true,
            width: '50%',
            symbolFront: '$',
            subSymbolBack: 'USD',
        },
    ];

    const stats2 = [
        {
            title: 'TVL',
            value: tokenStats.depxLockedSupplyUSD,
            compact: true,
            width: '50%',
            symbolFront: '$'
        },
        {
            title: 'vAPR',
            value: depxData.totalApr,
            compact: false,
            width: '50%',
            symbolBack: '%'
        },
    ];

    const tabsWithRewards = [
        {
            paddingStr: '16px 0px',
            title: <>Rewards & Trading Fees</>,
            icon: <></>,
            component: <>
                <Grid container>
                    <Grid item lg={12}>
                        {earningsUSD > 0 ? <ClaimableRewards earnings={rewards} earningsTotalUSD={earningsUSD} /> : <></>}
                    </Grid>
                    <Grid item lg={12}>
                        {tradingFees.length > 0 && earningsUSD > 0 ?
                            <ClaimableTradingFees earnings={tradingFees} feeTokens={depxData.feeTokens.map((t) => t.address)}/> : <></>
                        }
                    </Grid>
                    {earningsUSD === 0 ? <Grid item lg={12}>
                        <Typography variant='h3' sx={{ p: 2 }}>Stake dEPX to earn EPX and trading fees.</Typography>
                    </Grid> : <></>}
                </Grid>
            </>
        },
        {
            title: <>Convert & Stake</>,
            icon: <></>,
            component: <>
                <ConvertEPX
                    tokenSymbol={'EPX'}
                    tokenBalance={epxBalance}
                    tokenAllowance={epxAllowance}
                    tokenAllowanceBond={allowance}
                />
            </>
        },
        {
            title: <>Stake</>,
            icon: <></>,
            component: <>
                <BonddEPX
                    tokenSymbol={'dEPX'}
                    tokenBalance={balance}
                    tokenAllowance={allowance}
                />
            </>
        },
        {
            title: <>Unstake</>,
            icon: <></>,
            component: <>
                {!unbondableBalance.isZero() ?
                    <Unbond
                        unbondableBalance={unbondableBalance}
                        bondingStream={activeStream.total}
                    /> :
                    <Typography>When the minimum stake duration has expired for staked dEPX you will be able to initiate an unstaking stream from here.</Typography>
                }
            </>
        },
    ];

    const tabsWithoutRewards = [
        {
            title: <>Convert & Stake</>,
            icon: <></>,
            component: <>
                <ConvertEPX
                    tokenSymbol={'EPX'}
                    tokenBalance={epxBalance}
                    tokenAllowance={epxAllowance}
                    tokenAllowanceBond={allowance}
                />
            </>
        },
        {
            title: <>Stake</>,
            icon: <></>,
            component: <>
                <BonddEPX
                    tokenSymbol={'dEPX'}
                    tokenBalance={balance}
                    tokenAllowance={allowance}
                />
            </>
        },
        {
            title: <>Unstake</>,
            icon: <></>,
            component: <>
                {!unbondableBalance.isZero() ?
                    <Unbond
                        unbondableBalance={unbondableBalance}
                        bondingStream={activeStream.total}
                    /> :
                    <Typography>When the minimum stake duration has expired for staked dEPX you will be able to initiate an unstaking stream from here.</Typography>
                }
            </>
        },
        {
            paddingStr: '16px 0px',
            title: <>Rewards & Trading Fees</>,
            icon: <></>,
            component: <>
                <Grid container>
                    <Grid item lg={12}>
                        {earningsUSD > 0 ? <ClaimableRewards earnings={rewards} earningsTotalUSD={earningsUSD} /> : <></>}
                    </Grid>
                    <Grid item lg={12}>
                        {tradingFees.length > 0 && earningsUSD > 0 ?
                            <ClaimableTradingFees earnings={tradingFees} feeTokens={depxData.feeTokens.map((t) => t.address)}/> : <></>
                        }
                    </Grid>
                    {earningsUSD === 0 ? <Grid item lg={12}>
                        <Typography variant='h3' sx={{ p: 2 }}>Stake dEPX to earn EPX and trading fees.</Typography>
                    </Grid> : <></>}
                </Grid>
            </>
        },
    ];

    return (<Card>
        <CardContent>
            <Box sx={{
                p: '24px 24px',
                background: 'linear-gradient(90deg, #966DF3 0%, #0CB9FC 100%);'
            }}>
                <Box sx={{ display: 'flex', flexDirection: ['column', 'row'], justifyContent: 'space-between', alignItems: ['unset', 'center'] }}>
                    <CardTitle title={'Convert and stake EPX'} />
                    <TopStatsTable stats={stats2} />
                </Box>
            </Box>
            <Box sx={{ p: '24px 24px' }}>
                <Grid item xs={12} lg={12}>
                    <Grid container columnSpacing={2} rowSpacing={0.5}>
                        <Grid item xs={12} lg={!activeStream.total.isZero() ? 4 : 5}>
                            <StatsTable stats={stats1} />
                        </Grid>
                        <Grid item xs={12} lg={!activeStream.total.isZero() ? 6 : 7}>
                            <Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: 400, lineHeight: '140%', color: '#1A2037', mb: '20px' }}>
                                Convert EPX to dEPX then stake dEPX to earn 10% of all EPX farmed by the protocol, all ellipsis trading fees earned by the protocol, and the equivalent DDD minted from all EPX taken as protocol fees (15% at a 20:1 ratio).
                            </Typography>
                            <Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: 700, lineHeight: '140%', color: '#1A2037' }}>
                                Staked dEPX cannot be unstaked for 8 days after staking and when unstaked will be streamed out over 15 days.
                            </Typography>
                        </Grid>
                        {!activeStream.total.isZero() ? <Grid item xs={12} lg={2}>
                            <ClaimUnbonding
                                currentClaiamble={activeStream.claimable}
                                totalClaimable={activeStream.total}
                            />
                        </Grid> : <></>}
                    </Grid>
                </Grid>
            </Box>
            <Grid container>
                <Grid item xs={12} lg={12}>
                    <TabsPanel tabs={earningsUSD ? tabsWithRewards : tabsWithoutRewards} />
                </Grid>
            </Grid>
        </CardContent>
    </Card>);
}

export default StakeDepx;
