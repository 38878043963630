import React, { useEffect, useState } from 'react';
import { Box, TableCell, Typography } from '@mui/material';
import { DisplayValue2 } from '../../../../../components/DisplayValue';
import { useOnboardProvider } from '../../../../../providers/onboard-provider';
import LoadingButton from '../../../../../components/LoadingButton';
import CompleteButton from '../../../../../components/CompleteButton';
import { RewardsFromApprovalVote, TxState } from '../../../../../web3/types';
import DisplayTable from '../../../../../components/DisplayTable';
import TokenIcon from '../../../../../components/TokenIcon';
import TxModal from '../../../../../components/TxModal';
import { claimIncentive } from '../../../../../web3/TokenApprovalIncentives/claimIncentive';

type Props = {
    rewardsFromApprovalVote: Array<RewardsFromApprovalVote>,
}

export default function ClaimableRewardsFromApprovalVote({ rewardsFromApprovalVote }: Props) {
    const { signer, account } = useOnboardProvider();

    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const handleClaimRewards = async (e: any) => {
        if (signer !== undefined && account !== undefined && account !== null) {
            const txFinished = () => {
                setTxState('finished');
            }
            await claimIncentive(
                {
                    setTxHash: setCurrentTxHash,
                    setTxState: setTxState,
                    setTxError: () => {},
                    txFinished: txFinished,
                },
                signer,
                account,
                e.index,
                e.rewardToken
            );
        }
    }
    const [sortedEarnings, setSortedEarnings] = useState<any[]>([])

    useEffect(() => {
        if (rewardsFromApprovalVote.length > 0) {
            const rewards = [];
            for (let i = 0; i < rewardsFromApprovalVote.length; i++) {
                for (let j = 0; j < rewardsFromApprovalVote[i].rewards.length; j++) {
                    if (rewardsFromApprovalVote[i].passed && rewardsFromApprovalVote[i].rewards[j].amount > 0) {
                        rewards.push({
                            passed: rewardsFromApprovalVote[i].passed,
                            expired: rewardsFromApprovalVote[i].expired,
                            index: rewardsFromApprovalVote[i].index,
                            token: rewardsFromApprovalVote[i].token,
                            symbol: rewardsFromApprovalVote[i].symbol,
                            name: rewardsFromApprovalVote[i].name,
                            rewardToken: rewardsFromApprovalVote[i].rewards[j].token,
                            rewardSymbol: rewardsFromApprovalVote[i].rewards[j].symbol,
                            rewardName: rewardsFromApprovalVote[i].rewards[j].name,
                            rewardAmount: rewardsFromApprovalVote[i].rewards[j].amount,
                            rewardAmountUSD: rewardsFromApprovalVote[i].rewards[j].amountUSD,
                        });
                    }
                }
            }
            setSortedEarnings(rewards);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rewardsFromApprovalVote]);

    const headings = [<Typography variant="h4" sx={{ fontSize: ['12px', '14px'] }}>Pool Incentives Vote Bribes</Typography>, 'Token', 'Claimable (USD)', 'Amount', ''];

    const rows = sortedEarnings.map((e, k) => {return {
        item: <>
            <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                    <TokenIcon address={e.token} />
                    <Typography variant="h6" sx={{ fontSize: ['12px', '14px'] }}>
                        {e.symbol}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TokenIcon address={e.rewardToken} />
                    <Typography variant="h6" sx={{ fontSize: ['12px', '14px'] }}>
                        {e.rewardSymbol}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Typography variant="h6">
                    $<DisplayValue2
                        value={e.rewardAmountUSD}
                        precision={2}
                    />
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="h6">
                    <DisplayValue2 value={e.rewardAmount} precision={3} />
                </Typography>
            </TableCell>
            <TableCell>
                <Box width={'60%'} margin={['0', 'auto']}>
                    {txState === 'none' || txState === 'pending' || txState === 'confirm' ? <LoadingButton
                        content={`Claim ${e.rewardSymbol}`}
                        variant={"outlined"}
                        onClick={() => handleClaimRewards(e)}
                        disabled={!e.passed || e.rewardAmount === 0 || txState === 'pending' ? true : false}
                        loading={txState === 'pending' ? true : false }
                    /> : <CompleteButton content={`${e.rewardSymbol} claimed`} />}
                </Box>
            </TableCell>
            </>,
        key: k.toString()
    }});

    return (<>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {sortedEarnings.length > 0 ? <DisplayTable headings={headings} rows={rows} pagination={false} /> : <Typography>Pool Incentive Vote Bribes will appear here</Typography>}
        </Box>
        <TxModal txState={txState} currentTxHash={currentTxHash} setTxState={setTxState} />
    </>);
}

