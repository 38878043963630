import React from 'react';
import { Box, Typography } from '@mui/material';
import { DisplayLargeNumber2 } from '../DisplayLargeNumber';
import { useApiDataContext } from '../../providers/api-provider';
import DisplayPreciseNumber from '../DisplayPreciseNumber';

export default function TopBarStats() {
    const { tokenStats, tvls, dddPrice } = useApiDataContext();

    return (<Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        textAlign: 'left',
        width: '100%',
        alignItems: 'center'
    }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingX: '15px' }}>
            <Typography sx={{ color: '#ffffff', fontWeight: 500, fontSize: '12px', lineHeight: '15px', opacity: '0.8' }}>
                Platform TVL
            </Typography>
            <Typography sx={{ color: '#ffffff', fontWeight: 700, fontSize: '24px', lineHeight: '30px' }}>
                $<DisplayLargeNumber2 value={tvls.totalTvl} />
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingX: '15px' }}>
            <Typography sx={{ color: '#ffffff', fontWeight: 500, fontSize: '12px', lineHeight: '15px', opacity: '0.8' }}>
                DDD Locked
            </Typography>
            <Typography sx={{ color: '#ffffff', fontWeight: 700, fontSize: '24px', lineHeight: '30px' }}>
                <DisplayLargeNumber2 value={tokenStats.dddSupplyLocked / tokenStats.dddTotalSupply * 100} />%
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingX: '15px' }}>
            <Typography sx={{ color: '#ffffff', fontWeight: 500, fontSize: '12px', lineHeight: '15px', opacity: '0.8' }}>
                DDD Price
            </Typography>
            <Typography sx={{ color: '#ffffff', fontWeight: 700, fontSize: '24px', lineHeight: '30px' }}>
                $<DisplayPreciseNumber value={dddPrice} precision={3} />
            </Typography>
        </Box>
    </Box>);
}