import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

type Props = {
    title: ReactNode;
    subtitle?: ReactNode;
    background: string;
    height?: string;
}

export default function ZeroState({ title, subtitle, background, height = '200px' }: Props) {
    return (<Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: height,
        background: `url(/zero/${background}.svg); no-repeat`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        margin: 'auto'
    }}>
        <Box sx={{ marginBottom: subtitle ? '8px' : '0px' }}>{title}</Box>
        {subtitle ? <Box>{subtitle}</Box> : <></>}
    </Box>);
}