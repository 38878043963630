import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import TokenApprovalIncentivesAbi from '../../abi/TokenApprovalIncentives.json';
import { CONFIG } from '../../global';
import { TxActions } from '../types';
import { calcGas } from '../utils';

export async function claimIncentive(
    actions: TxActions,
    web3: Web3,
    account: string,
    voteId: number,
    reward: string,
): Promise<any> {
    actions.setTxState('confirm');
    const c = new web3.eth.Contract(TokenApprovalIncentivesAbi as AbiItem[], CONFIG.TokenApprovalIncentives);
    const est = await c.methods.claimIncentive(voteId, reward).estimateGas({'from': account});
    c.methods.claimIncentive(voteId, reward).send({'from': account, gas: calcGas(est), maxFeePerGas: 3 * 1e9, maxPriorityFeePerGas: 3 * 1e9 })
    .once('transactionHash', (hash: any) => {
        actions.setTxState('pending');
        actions.setTxHash(hash);
    }).once('error', (error: any) => {
        console.error(error)
        actions.setTxError(error);
        actions.setTxState('none');
    }).once('confirmation', (conf: any, receipt: any) => {
    }).then((receipt: any) => {
        actions.txFinished();
    }).finally(() => {
        actions.txFinished();
    });
}