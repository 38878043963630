import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import CardTitle from '../../../components/CardTitle';
import { useApiDataContext } from '../../../providers/api-provider';
import ApprovalVoteList from './components/ApprovalVoteList';

function ApprovalVoteIncentives() {
    const { approvalVotesData } = useApiDataContext();
    return (<Card>
        <CardContent>
            <Box sx={{
                p: '24px 24px',
                background: 'linear-gradient(90deg, #966DF3 0%, #0CB9FC 100%);'
            }}>
                <Box sx={{ display: 'flex', flexDirection: ['column', 'row'], justifyContent: 'space-between', alignItems: ['unset', 'center'] }}>
                    <CardTitle
                        title={'Pool Incentive Vote Bribes'}
                    />
                </Box>
            </Box>
            <Box sx={{ p: '24px 24px' }}>
                <Grid item xs={12} lg={12}>
                    <Grid container columnSpacing={2} rowSpacing={0.5}>
                        <Grid item xs={12} lg={7}>
                            <Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: 400, lineHeight: '140%', color: '#1A2037' }}>
                                Bribes are paid out in the following week to DDD Lockers who voted for the pool.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Grid container>
                <Grid item xs={12} lg={12}>
                    <ApprovalVoteList approvalVotes={approvalVotesData.tokenApprovalVotes} />
                </Grid>
            </Grid>
        </CardContent>
    </Card>);
}

export default ApprovalVoteIncentives;
