
import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import HistoricalVotesModal from './HistoricalVotesModal';

function HistoricalVotes() {
    return (<Card sx={{ border: '1px solid rgba(151, 108, 243, 0.4)' }}>
        <CardContent sx={{ paddingY: '8px !important' }}>
            <Grid container>
                <Grid item xs={12} lg={12}>
                    <HistoricalVotesModal />
                </Grid>
            </Grid>
        </CardContent>
    </Card>);
}

export default HistoricalVotes;
