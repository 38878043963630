import React, { createContext, useContext, useEffect, useState } from "react";
import Onboard from 'bnc-onboard';
import { API, Wallet as OnboardWallet } from 'bnc-onboard/dist/src/interfaces';
import { CONFIG } from "../../global";
import Web3 from "web3";

type OnboardContextValue = {
  signer: Web3 | undefined,
  account: string | undefined | null,
  active: boolean,
  connectWallet: () => void,
  disconnectWallet: () => void,
  selectWallet: () => void
};

const OnboardContext = createContext<OnboardContextValue | undefined>(undefined);

export const OnboardProvider: React.FC = ({ children }) => {
  const [onboard, setOnboard] = useState({} as any);
  const [signer, setSigner] = useState<Web3>();
  const [account, setAddress] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [balance, setBalance] = useState(0)
  const [wallet, setWallet] = useState<OnboardWallet | undefined>(undefined)
  const [active, setActive] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [network, setNetwork] = useState();

  useEffect(() => {
    const onboard = initOnboard({
      address: setAddress,
      network: setNetwork,
      balance: setBalance,
      wallet: (wallet: OnboardWallet) => {
        if (wallet.provider) {
          setWallet(wallet)
          let provider = new Web3(wallet.provider)
          setSigner(provider);
        } else {
          setWallet(undefined)
        }
      }
    })
    setOnboard(onboard)
    const prevWallet = window.localStorage.getItem('selectedWallet');
    if (prevWallet !== null) {
      tryConnect(onboard, prevWallet);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tryConnect = async (onboard: API, prevWallet: string) => {
    try {
      const selectResult = await onboard.walletSelect(prevWallet);
      if (selectResult) {
        const checkResult = await onboard.walletCheck();
        if (selectResult && checkResult) {
          if (prevWallet !== undefined) {
            const currentState = onboard.getState()

            console.log('prevWallet', prevWallet)
            window.localStorage.setItem('selectedWallet', currentState.wallet.name !== null ? currentState.wallet.name : '')
          }
          setActive(true);
        }
      }
    } catch (e) {
      console.error(e);
      await onboard.walletReset()
    }
  }

  const connectWallet = async () => {
    try {
      const prevWallet = window.localStorage.getItem('selectedWallet');
      if (prevWallet !== null) {
        const selectResult = await onboard.walletSelect(prevWallet);
        if (selectResult) {
          const checkResult = await onboard.walletCheck();
          if (selectResult && checkResult) {
            if (prevWallet !== undefined) {
              window.localStorage.setItem('selectedWallet', prevWallet !== null ? prevWallet : '')
            }
            setActive(true);
          }
        }
      } else {
        const selectResult = await onboard.walletSelect();
        if (selectResult) {
          const checkResult = await onboard.walletCheck();
          if (selectResult && checkResult) {
            const currentState = onboard.getState()
            if (currentState.wallet !== undefined) {
              window.localStorage.setItem('selectedWallet', currentState.wallet.name !== null ? currentState.wallet.name : '')
            }
            setActive(true);
          }
        }
      }
    } catch (e) {
      console.error(e);
      await onboard.walletReset()
    }
  }
  
  const disconnectWallet = async () => {
    await onboard.walletReset();
  };

  const selectWallet = async () => {
    try {
      window.localStorage.setItem('selectedWallet', '');
      await onboard.walletReset();
      const selectResult = await onboard.walletSelect();
      if (selectResult) {
        const checkResult = await onboard.walletCheck();
        if (selectResult && checkResult) {
          if (wallet !== undefined) {
            window.localStorage.setItem('selectedWallet', wallet.name !== null ? wallet.name : '')
          }
          setActive(true);
        }
      }
    } catch (e) {
      await onboard.walletReset()
      console.error(e);
    }
  }

  return (
    <OnboardContext.Provider value={{
      signer,
      account,
      active,
      connectWallet,
      disconnectWallet,
      selectWallet,
    }}>
      {children}
    </OnboardContext.Provider>
  );
};

export const useOnboardProvider = () => {
  const dAppContext = useContext(OnboardContext);

  if (!dAppContext) {
    throw new Error("You must provide a dAppContext via dAppProvider");
  }

  return dAppContext;
};

export function initOnboard(subscriptions: any) {
  return Onboard({
    hideBranding: true,
    networkId: CONFIG.chainId,
    subscriptions,
    walletSelect: {
      wallets: [
        { walletName: 'metamask', preferred: true },
        { walletName: "binance", preferred: true },
        { walletName: "coinbase", preferred: true },
        { walletName: "walletLink", rpcUrl: CONFIG.rpc, appName: 'DotDot Finance', preferred: true },
        { walletName: 'walletConnect', rpc: { [CONFIG.chainId]: CONFIG.rpc }, preferred: true },
        { walletName: "trust", rpcUrl: CONFIG.rpc },
        { walletName: 'gnosis' },
        {
          walletName: 'ledger',
          rpcUrl: CONFIG.rpc,
        },
        {
          walletName: 'trezor',
          appUrl: '',
          email: '',
          rpcUrl: CONFIG.rpc 
        },
      ]
    },
    walletCheck: [
      { checkName: 'derivationPath' },
      { checkName: 'connect' },
      { checkName: 'accounts' },
      { checkName: 'network' },
    ]
  })
}
