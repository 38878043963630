
import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import Bribes from './bribes';
import ApprovalVoteIncentives from './approval-vote-incentives';
import { APPROVAL_VOTE_BIRBS } from '../../global';

function BribesPage() {

    return (<Container sx={{ marginTop: 10, paddingBottom: 4, marginBottom: 3 }}>
        <Box sx={{ width: '100%', my: 3 }}>
            <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} lg={12}>
                  <Bribes />
              </Grid>
              {APPROVAL_VOTE_BIRBS ? <Grid item xs={12} lg={12}>
                  <ApprovalVoteIncentives />
              </Grid> : <></>}
            </Grid>
        </Box>
    </Container>);
}

export default BribesPage;
