
import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import StakePool2 from './pool2';
import StakeEpsLp from './epslp';

function StakePage() {

    return (<Container sx={{ marginTop: 10, paddingBottom: 4, marginBottom: 3 }}>
        <Box sx={{ width: '100%', my: 3 }}>
            <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} lg={12}>
                    <StakeEpsLp />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <StakePool2 />
                </Grid>
            </Grid>
        </Box>
    </Container>);
}

export default StakePage;
