
import React from 'react';

import { Button } from '@mui/material';
import { CHAIN_NAME, METAMASK_NETWORK_PARAMS } from '../../../global';

function ConnectToNetwork() {
  const addNetwork = async () => {
    (global.window as any)?.ethereum
      ?.request({
        method: 'wallet_addEthereumChain',
        params: [METAMASK_NETWORK_PARAMS],
      })
      .then(() => {
        window.location.reload();
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  return (<Button variant="connect" onClick={addNetwork}>
    Switch to {CHAIN_NAME}
  </Button>);
}

export default ConnectToNetwork;
