import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Skeleton, TableCell, Typography } from '@mui/material';
import { DisplayValue2 } from '../../../../../components/DisplayValue';
import { useOnboardProvider } from '../../../../../providers/onboard-provider';
import LoadingButton from '../../../../../components/LoadingButton';
import CompleteButton from '../../../../../components/CompleteButton';
import { FeeUserClaimable, TxState } from '../../../../../web3/types';
import DisplayTableRewards from '../../../../../components/DisplayTableRewards';
import { claim } from '../../../../../web3/BondedDistributor/claim';
import TokenIcon from '../../../../../components/TokenIcon';
import ClaimAllTradingFees from '../ClaimAllTradingFees';
import TxModal from '../../../../../components/TxModal';

type Props = {
    earnings: Array<FeeUserClaimable>;
    feeTokens: string[];
}

type SelectedTokens = {
    [key: string]: boolean;
}

export default function ClaimableTradingFees({
    earnings,
    feeTokens
}: Props) {
    const { signer, account } = useOnboardProvider();

    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const [selectedTokens, setSelectedTokens] = useState({} as SelectedTokens);
    const [totalSelected, setTotalSelected] = useState(0);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        selectedTokens[event.target.name] = event.target.checked;
        const newSelectedTokens = {} as SelectedTokens;
        Object.assign(newSelectedTokens, selectedTokens)
        setSelectedTokens(newSelectedTokens);
        let totalSelected = 0;
        for (let i = 0; i < earnings.length; i++) {
            if (newSelectedTokens[earnings[i].feeToken] === true) {
                totalSelected += earnings[i].claimableUSD;
            }
        }
        setTotalSelected(totalSelected);
    };

    const handleClaimRewards = async () => {
        const keys = Object.keys(selectedTokens);

        let tokens = [];
        for (let i = 0; i < keys.length; i++) {
            if (selectedTokens[keys[i]] === true) {
                tokens.push(keys[i])
            }
        }

        if (signer !== undefined && account !== undefined && account !== null) {
            const txFinished = () => {
                setTxState('finished');
            }
            await claim(
                {
                    setTxHash: setCurrentTxHash,
                    setTxState: setTxState,
                    setTxError: () => {},
                    txFinished: txFinished,
                },
                signer,
                account,
                tokens
            );
        }
    }
    const [sortedEarnings, setSortedEarnings] = useState<FeeUserClaimable[]>([])

    useEffect(() => {
        if (earnings) {
            const sortedEarnings = earnings.sort(
                (a, b) => (b.claimableUSD - a.claimableUSD)).filter((e) => e.claimable !== 0
            );
            setSortedEarnings(sortedEarnings);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [earnings]);

    const headings = [<Typography variant="h4">Trading Fees</Typography>, 'Claimable (USD)', 'Amount', ''];

    const rows = sortedEarnings.map((e, k) => {return {
        item: <>
            <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                    <TokenIcon address={e.feeToken} />
                    <Typography variant="h6" sx={{ ml: 0.5, fontSize: ['12px', '14px'] }}>
                        {e.feeSymbol}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Typography variant="h6">
                    $<DisplayValue2
                        value={e.claimableUSD}
                        precision={2}
                    />
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="h6">
                    <DisplayValue2 value={e.claimable} precision={3} />
                </Typography>
            </TableCell>
            <TableCell>
                <Checkbox
                    disableRipple
                    sx={{ color: '#976CF3', width: '100%', p: 'unset', minWidth: '110px', justifyContent: 'flex-end', paddingRight: '32px' }}
                    checked={selectedTokens[e.feeToken] === undefined ? false : selectedTokens[e.feeToken]}
                    onChange={handleChange}
                    name={e.feeToken}
                />
            </TableCell>
            </>,
        key: k.toString()
    }});

    const claimButton = <Box sx={{ display: 'flex', flexDirection: 'row', '& > div > button': { width: 'max-content' } }}>
        <Box sx={{ mr: 0.5 }}>
            <ClaimAllTradingFees tokens={feeTokens} />
        </Box>
        {txState === 'none' || txState === 'pending' || txState === 'confirm' ? <LoadingButton
            content={'Claim selected'}
            variant={"blue"}
            onClick={handleClaimRewards}
            disabled={totalSelected === 0 || txState === 'pending' || txState === 'confirm' ? true : false}
            loading={txState === 'pending' ? true : false }
        /> : <CompleteButton content={'Earnings claimed'} />}
    </Box>

    return (<>
        <Box sx={{ display: 'flex', background: '#ECF2F4' }}>
            {earnings !== undefined ? <DisplayTableRewards headings={headings} rows={rows} claimButton={claimButton} /> : <>
                <Skeleton variant="rectangular" width={'100%'} height={10} sx={{ my: 2, borderRadius: '4px' }} />
                <Skeleton variant="rectangular" width={'100%'} height={10} sx={{ my: 2, borderRadius: '4px' }} />
            </>}
        </Box>
        <TxModal txState={txState} currentTxHash={currentTxHash} setTxState={setTxState} />
    </>);
}

