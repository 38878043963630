import React from 'react';
import { Box, CSSObject, Typography } from '@mui/material';
import { DisplayLargeNumber2 } from '../DisplayLargeNumber';
import { DisplayWholeNumber } from '../DisplayWholeNumber';
import { DisplayValue2 } from '../DisplayValue';
import DisplayPreciseNumber from '../DisplayPreciseNumber';

export interface Stat {
    title: string;
    width: string;
    value: number;
    compact?: boolean;
    symbolFront?: string;
    symbolBack?: string;
    wholeNumber?: boolean;
    precision?: number;
    preciseNumber?: boolean;
    subValue?: number;
    subCompact?: boolean;
    subSymbolFront?: string;
    subSymbolBack?: string;
    subValueTwo?: number;
    subCompactTwo?: boolean;
    subSymbolFrontTwo?: string;
    subSymbolBackTwo?: string;
}

type Props = {
    stats: Stat[];
}

export default function TopStatsTable({ stats }: Props) {
    const style: CSSObject = {
        padding: '8px 16px',
        textAlign: 'left',
        background: 'rgba(255, 255, 255, 0.2);',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    };

    return (<Box sx={{
        display: 'flex',
        flexDirection: ['column', 'row'],
        justifyContent: 'flex-start',
    }}>
        {stats.map((s, k) => (
            <Box key={k} sx={{
                mb: ['8px', 'unset'],
                ml: ['unset', '8px'],
                ...style
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: ['space-between', 'flex-start'],
                    width: '100%'
                }}>
                    <Typography sx={{ color: '#BDE7FF', fontSize: '16px', lineHeight: '19px', fontWeight: 800, mr: '8px' }}>
                        {s.title}
                    </Typography>
                    <Typography sx={{ color: '#FFFFFF', fontSize: '24px', lineHeight: '30px', fontWeight: 600 }}>
                        {s.symbolFront ? s.symbolFront : ''}{
                            s.wholeNumber ? <DisplayWholeNumber value={s.value}  /> :
                            s.preciseNumber ? <DisplayPreciseNumber value={s.value} precision={s.precision} /> :
                            s.compact ? <DisplayLargeNumber2 value={s.value}  /> :
                            <DisplayValue2 value={s.value} precision={s.precision} /> 
                        }{s.symbolBack ? s.symbolBack : ''}
                    </Typography>
                </Box>
                {s.subValue ? <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                }}>
                    <Typography sx={{ color: '#BDE7FF', fontSize: '12px', lineHeight: '15px', fontWeight: 500, mt: '4px' }}>
                        {s.subSymbolFront ? s.subSymbolFront : ''}{s.subValue ?
                            s.subCompact ?
                                <DisplayLargeNumber2 value={s.subValue} /> : <DisplayValue2 value={s.subValue} precision={s.precision} />
                            : <></>
                        }{s.subSymbolBack ? s.subSymbolBack : ''}
                    </Typography>
                    <Typography sx={{ color: '#FFFFFF', fontSize: '12px', lineHeight: '15px', fontWeight: 500, mt: '4px' }}>
                        {s.subSymbolFrontTwo ? s.subSymbolFrontTwo : ''}{s.subValueTwo ?
                            s.subCompactTwo ?
                                <DisplayLargeNumber2 value={s.subValueTwo} /> : <DisplayValue2 value={s.subValueTwo} precision={s.precision} />
                            : <></>
                        }{s.subSymbolBackTwo ? s.subSymbolBackTwo : ''}
                    </Typography>
                </Box> : <></>}
            </Box>
        ))}
    </Box>);
}
