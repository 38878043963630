import BigNumber from 'bignumber.js';
import numeral from 'numeral';

type Props = {
    value: number;
    decimals?: number;
    short?: boolean;
    precision?: number;
}

const intlFormat = new Intl.NumberFormat('en-US');

export function prettify(value: BigNumber, precision: number, decimals?: number)  {
    if (decimals) {
        return intlFormat.format(
            parseFloat(new BigNumber(value.toNumber() / decimals).toFixed(precision))
        );
    }
    return intlFormat.format(parseFloat(value.toNumber().toFixed(precision)));
}

export function DisplayWholeNumber({ value, decimals, precision = 1, short = false }: Props) {

    return (<>
      {numeral(value).format('0')}
    </>);
}

type Props2 = {
    value: number;
}

export function DisplayWholeNumber2({ value }: Props2) {
    return (<>
      {numeral(value).format('0.00a')}
    </>);
}
