import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

type Props = {
    children: ReactNode;
}

export default function Info({ children }: Props) {
    return (<Box sx={{
        minHeight: '42px',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        background: 'rgba(20, 103, 251, 0.1)',
        border: '1px solid #1467FB',
        borderRadius: '4px',
        padding: '9px 13px',
        alignItems: 'center'
    }}>
        <Typography variant="h5">
            {children}
        </Typography>
    </Box>);
}