
import React from 'react';
import { Box } from '@mui/material';
import WalletAddress from './WalletAddress';
import ConnectWallet from './ConnectWallet';
import { useConnectedNetworkContext } from '../../providers/connected-network-provider';
import ConnectToNetwork from './ConnectToNetwork';
import { useOnboardProvider } from '../../providers/onboard-provider';
import { SUPPORTED_CHAINS } from '../../global';

export const supportedNetwork = (chainId: number) => {
    if (SUPPORTED_CHAINS.filter((c) => c === chainId).length > 0) {
        return true;
    }
    return false;
  }

function ConnectionHandler() {
    const { active } = useOnboardProvider();
    const { chainId } = useConnectedNetworkContext();

    const isSupportedNetwork = supportedNetwork(chainId);

    return (
        <Box sx={{
            flexGrow: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#ffffff',
            borderRadius: '4px',
        }}>
            {isSupportedNetwork ? <>
                <Box>{active ? <WalletAddress /> : <ConnectWallet />}</Box>
            </> :  <>
                <Box><ConnectToNetwork /></Box>
            </>}
        </Box> 
    );
}

export default ConnectionHandler;
