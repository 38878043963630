import React, { useState } from 'react';
import BigNumber from 'bignumber.js';
import { Box, Button } from '@mui/material';
import CompleteButton from '../../../../../components/CompleteButton';
import LoadingButton from '../../../../../components/LoadingButton';
import Balance from '../../../../../components/Balance';
import TxContent from '../../../../../components/TxContent';
import { useOnboardProvider } from '../../../../../providers/onboard-provider';
import { TxState } from '../../../../../web3/types';
import { voteForTokenApproval } from '../../../../../web3/DotDotVoting/voteForTokenApproval';
import { CONFIG } from '../../../../../global';
import InputNumberVote from '../../../../../components/Inputs/InputNumberVote';

type Props = {
    name: string;
    index: number;
    availableVotes: BigNumber;
}

export default function ApprovalVoter({ name, index, availableVotes }: Props) {
    const { signer, account } = useOnboardProvider();

    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const [amount, setAmount] = useState<BigNumber>();
    const [inputValue, setInputValue] = useState<number | string>('');

    const handleSubmit = async () => {
        if (signer !== undefined && account !== undefined && account !== null && amount !== undefined) {
            const txFinished = () => {
                setInputValue('');
                setAmount(undefined);
                setTxState('finished');
            }
            await voteForTokenApproval(
                {
                    setTxHash: setCurrentTxHash,
                    setTxState: setTxState,
                    setTxError: () => {},
                    txFinished: txFinished,
                },
                signer,
                account,
                index,
                amount,
            );
        }
    }

    const setMaxAmount = (event: any) => {
        event.preventDefault();
        if (availableVotes) {
          setInputValue(availableVotes.toNumber());
          setAmount(availableVotes);
        }
    }

    return (<Box sx={{ my: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', px: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, width: '100%' }}>
            <Button disableRipple onClick={setMaxAmount} variant="text">
                <Balance title='Current vote weight' tokenBalance={availableVotes} tokenSymbol={'vl' + CONFIG.symbol} decimals={1} />
            </Button>
        </Box>
        <Box sx={{ width: '100%', mb: txState === 'pending' || txState === 'confirm' ? '8px' : 'unset' }}>
            <InputNumberVote
                maxAmount={availableVotes}
                setRealInput={setAmount}
                inputValue={inputValue}
                setInputValue={setInputValue}
            />
            <Box sx={{ mt: 3, width: '100%', display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
                {txState === 'none' || txState === 'pending' || txState === 'confirm' ? <LoadingButton
                    content={`Vote for approval on ${name}`}
                    variant={"blue"}
                    onClick={handleSubmit}
                    disabled={txState === 'pending' || txState === 'confirm' ? true : false || amount === undefined || amount.isZero()}
                    loading={txState === 'pending' || txState === 'confirm' ? true : false }
                /> : <CompleteButton content={`Voted on ${name}`} />}
            </Box>
        </Box>
        <TxContent txState={txState} currentTxHash={currentTxHash} />
    </Box>);
}
