import { createTheme } from '@mui/material/styles';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    white: true;
    blue: true;
    connect: true;
    sort: true;
    transparent: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#976CF3',
    },
    secondary: {
      main: '#fff',
    },
  },
  typography: {
    fontFamily: `'Outfit', sans-serif`,
    h1: {
      fontFamily: `'Outfit', sans-serif`,
      fontWeight: 600,
      color: '#000000',
    },
    h2: {
      fontFamily: `'Outfit', sans-serif`,
      fontWeight: 700,
      color: '#000000',
    },
    h3: {
      fontSize: '24px',
      fontFamily: `'Outfit', sans-serif`,
      fontWeight: 700,
      color: '#000000',
    },
    h4: {
      fontSize: '14px',
      fontFamily: `'Outfit', sans-serif`,
      fontWeight: 600,
      color: '#002237',
    },
    h5: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
      color: '#1467FB',
    },
    h6: {
      fontWeight: 'normal',
      lineHeight: '20px',
      color: '#002237',
    },
    body1: {
      color: '#000000',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '20px',
    },
    body2: {
      fontWeight: 'bold',
      fontSize: '20px',
      color: '#000000',
      lineHeight: '25px',
    },
    subtitle1: {
      fontSize: '16px',
      fontFamily: `'Outfit', sans-serif`,
      color: '#1A2037',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '14px',
      fontFamily: `'Outfit', sans-serif`,
      color: '#687692',
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center'
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          '& .MuiButton-outlined': {
            fontFamily: `'Ariel', sans-serif`,
            fontSize: '18px',
            color: '#ffffff',
          },
          '& .MuiButton-text': {
            fontFamily: `'Ariel', sans-serif`,
            fontSize: '18px',
            color: '#ffffff',
          },
          '& a': {
            fontFamily: `'Ariel', sans-serif`,
            fontSize: '18px',
            color: '#ffffff',
            border: 'unset',
            textDecoration: 'none'
          },
          color: '#ffffff',
          background: 'transparent',
          boxShadow: 'unset',
          border: 'unset',
          padding: 'unset'
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          color: '#ffffff',
          border: 'unset',
        },
        paper: {
          border: 'unset',
          background: '#1A2037',
          color: '#ffffff',
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between',
          padding: 'unset !important',
          //paddingRight: '24px !important'
        },
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {}
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: '#ffffff',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: 'unset',
          background: 'rgba(255, 255, 255, 1)',
          boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
          borderRadius: '12px'
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          background: 'unset',
          padding: 'unset',
          '&:last-child': {
            paddingBottom: 'unset'
          }
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: {
            variant: 'transparent'
          },
          style: {
            minHeight: '42px',
            minWidth: '90px',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '20px',
            background: 'rgba(151, 108, 243, 0.2)',
            color: '#976CF3',
            transition: 'all .2s ease',
            backfaceVisibility: 'hidden',
            '&:hover': {
              color: '#976CF3',
              background: 'rgba(151, 108, 243, 0.3)',
            },
            textTransform: 'none',
            '&.Mui-disabled':{
              color: '#976CF3',
              background: 'rgba(151, 108, 243, 0.1)',
            },
          }
        },
        {
          props: {
            variant: 'sort'
          },
          style: {
            minHeight: '42px',
            minWidth: '90px',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '20px',
            background: 'transparent',
            border: 'transparent',
            color: 'inherit',
            transition: 'all .2s ease',
            backfaceVisibility: 'hidden',
            '&:hover': {
              opacity: 0.5,
              color: 'inherit',
              background: 'transparent',
            },
            textTransform: 'none',
            '&.Mui-disabled':{
              opacity: 0.5,
              color: 'inherit',
              background: 'transparent',
            },
          }
        },
        {
          props: {
            variant: 'white'
          },
          style: {
            minHeight: '42px',
            minWidth: '90px',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '20px',
            background: '#ffffff',
            border: '1px solid #976CF3',
            color: '#976CF3',
            transition: 'all .2s ease',
            backfaceVisibility: 'hidden',
            '&:hover': {
              color: '#976CF3',
              background: 'rgba(255, 255, 255, 0.9)',
              border: '1px solid #976CF3',
            },
            textTransform: 'none',
            '&.Mui-disabled':{
              opacity: 0.9,
              color: '#976CF3',
              background: '#FFFFFF',
              border: '1px solid #976CF3',
            },
          }
        },
        {
          props: {
            variant: 'connect'
          },
          style: {
            minHeight: '42px',
            minWidth: '90px',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '20px',
            borderRadius: '4px',
            color: '#1A2037',
            transition: 'all .2s ease',
            backfaceVisibility: 'hidden',
            '&:hover': {
              color: '#1A2037',
              background: 'rgba(0, 191, 252, 0.2)',
            },
            textTransform: 'none',
            '&.Mui-disabled':{
              opacity: 0.7,
              color: '#1A2037',
              background: 'rgba(0, 191, 252, 0.2)',
            },
          }
        },
        {
          props: {
            variant: 'blue'
          },
          style: {
            minHeight: '42px',
            minWidth: '90px',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '20px',
            background: '#976CF3',
            color: '#ffffff',
            transition: 'all .2s ease',
            backfaceVisibility: 'hidden',
            '&:hover': {
              color: '#FFFFFF',
              background: 'rgba(151, 108, 243, 0.7)',
            },
            textTransform: 'none',
            '&.Mui-disabled':{
              opacity: 0.7,
              color: '#FFFFFF',
              border: '1px solid #976CF3',
            },
          }
        },
      ],
      styleOverrides: {
        outlined: {
          minHeight: '42px',
          minWidth: '90px',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '20px',
          border: '1px solid #976CF3',
          color: '#976CF3',
          transition: 'all .2s ease',
          backfaceVisibility: 'hidden',
          '&:hover': {
            color: '#976CF3',
            border: '1px solid #976CF3',
            background: 'rgba(151, 108, 243, 0.2)'
          },
          textTransform: 'none',
          '&.Mui-disabled':{
            opacity: 0.5,
            color: '#000000',
            border: '1px solid #000000',
          },
          //'&::after': {
          //  content: '" "',
          //  borderRadius: '4px',
          //  borderBottom: '1px solid #000000',
          //  position: 'absolute',
          //  paddingTop: '50%',
          //  width: '103%',
          //}
        },
        text: {
          fontSize: '16px',
          fontWeight: 700,
          color: '#1A2037',
          transition: 'all .2s ease',
          backfaceVisibility: 'hidden',
          '&:hover': {
            color: '#8477F4',
            backgroundColor: 'unset'
          },
          textTransform: 'none',
          '&.Mui-disabled':{
            color: '#A1ADB5',
          },
        },
        contained: {
          minHeight: '42px',
          minWidth: '90px',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '20px',
          color: '#000000',
          transition: 'all .2s ease',
          backfaceVisibility: 'hidden',
          background: '#ffffff',
          '&:hover': {
          },
          textTransform: 'none',
          '&.Mui-disabled':{
            opacity: 0.5,
          },
        }
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          '& input': {
            color: '#ffffff',
            //fontSize: '14px',
            //padding: '11px 14px 10px',
          },
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
          },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
        input: {
          padding: '7px 0px 7px 8px !important',
        }
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#000000',
          focused: {
            color: '#000000',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#000000',
          '& > label': {
            color: '#000000',
          },
          '&$focused': {
            color: '#000000'
          }
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          color: '#000000',
          '& > label,span': {
            color: '#000000',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          color: '#976CF3 !important'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: '#000000',
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: '#FFFFFF',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '22.5px',
          background: '#1A2037',
          padding: '7px 10px',
          border: '1px solid #333D64',
        },
        arrow: {
          '&:before': {
            border: '1px solid #333D64',
          },
          color: '#1A2037',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontStyle: 'Roboto',
          fontSize: '14px',
          fontWeight: 500,
          color: '#000000',
          opacity: 0.6,
          '&.Mui-selected': {
            opacity: 1,
            color: '#976CF3'
          },
          '& > span': {
            color: '#000000',
          },
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& > button': {
            minHeight: 'unset'
          }
        },
        indicator: {
          width: ['100%', 'auto'],
          padding: '0.5px',
          backgroundColor: '#976CF3'
        },
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          padding: '0.5px',
          opacity: 0.6,
          backgroundColor: '#000000'
        },
      }
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          display: 'flex',
          alignItems: 'center'
        },
        icon: {
          color: '#000000'
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '.MuiBackdrop-root': {
            background: 'rgba(255, 255, 255, 0.6)',
            backdropFilter: 'blur(20px)'
          }
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          '.MuiBackdrop-root': {
            background: 'rgba(255, 255, 255, 0.2)',
            backdropFilter: 'blur(0.3px)'
          }
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #E1E8F7'
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: 'rgba(132, 119, 244, 0.5)',
            textDecoration: 'none',
          },
          fontWeight: 700,
          textDecoration: 'none',
          transition: 'all .2s ease',
          color: '#8477F4'
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: '2px 0px 2px 0px',
          color: '#000000',
          border: '1px solid #A7B3CE',
          position: 'relative',
          '& $notchedOutline': {
              border: '1px solid #A7B3CE'
          },
          '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
              borderColor: '#A7B3CE',
              // Reset on touch devices, it doesn't add specificity
              '@media (hover: none)': {
                  border: '1px solid #A7B3CE'
              },
          },
          '&$focused $notchedOutline': {
              borderColor: '#A7B3CE',
              borderWidth: 1,
          },
          '& label.Mui-focused': {
            border: '1px solid #A7B3CE'
          },
          '& > .MuiOutlinedInput-notchedOutline': {
            border: 'unset'
          },
          '& input': {
            color: '#000000',
          }
        },
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
        }
      },
    },
    MuiToggleButton: {
      styleOverrides: {
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          color: '#ffffff'
        },
        root: {
          '& > .MuiChip-deleteIcon': {
            transition: 'all .2s ease',
            color: '#ffffff',
            ':hover': {
              color: '#ffffff',
              opacity: '0.6',
            }
          }
        }
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#000000'
        }
      }
    }
  },
});

export default theme;
