import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useOnboardProvider } from '../../../../../providers/onboard-provider';
import LoadingButton from '../../../../../components/LoadingButton';
import CompleteButton from '../../../../../components/CompleteButton';
import { TxState } from '../../../../../web3/types';
import BigNumber from 'bignumber.js';
import { withdrawUnbondedTokens } from '../../../../../web3/BondedDistributor/withdrawUnbondedTokens';
import { DisplayLargeNumber } from '../../../../../components/DisplayLargeNumber';
import TxModal from '../../../../../components/TxModal';

type Props = {
    currentClaiamble: BigNumber;
    totalClaimable: BigNumber;
}

export default function ClaimUnbonding({
    currentClaiamble,
    totalClaimable,
}: Props) {
    const { signer, account } = useOnboardProvider();

    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const handleClaim = async () => {
        if (signer !== undefined && account !== undefined && account !== null && !currentClaiamble.isZero()) {
            const txFinished = () => {
                setTxState('finished');
            }
            await withdrawUnbondedTokens(
                {
                    setTxHash: setCurrentTxHash,
                    setTxState: setTxState,
                    setTxError: () => {},
                    txFinished: txFinished,
                },
                signer,
                account
            );
        }
    }
   
    return (<>
        <Box sx={{
            padding: '24px 16px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: '#976CF3',
            border: '1px solid #F8FAFD',
            boxSizing: 'border-box',
            borderRadius: '4px'
        }}>
            <Box sx={{ mb: 1 }}>
                <Typography sx={{ fontWeight: 500, fontSize: '12px', lineHeight: '15px', color: '#ffffff', opacity: 0.6 }}>
                    Current exit stream
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', mr: 1, alignItems: 'center', mb: 1 }}>
                <Typography sx={{ fontWeight: 700, fontSize: '20px', lineHeight: '25px', color: '#ffffff' }}>
                    <DisplayLargeNumber value={currentClaiamble} decimals={1e18} />&nbsp;
                </Typography>
                <Typography sx={{ fontWeight: 700, fontSize: '14px', lineHeight: '18px', color: '#ffffff' }}>
                    /<DisplayLargeNumber value={totalClaimable} decimals={1e18} />
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                {txState === 'none' || txState === 'pending' || txState === 'confirm' ? <LoadingButton
                    content={`Claim`}
                    variant={"white"}
                    onClick={handleClaim}
                    disabled={currentClaiamble.isZero() || txState === 'pending' || txState === 'confirm' ? true : false}
                    loading={txState === 'pending' ? true : false }
                /> : <CompleteButton content={'Claimed'} />}
            </Box>
        </Box>
        <TxModal txState={txState} currentTxHash={currentTxHash} setTxState={setTxState} />
    </>);
}

