import React from 'react';
import { Grid, Link, Typography } from '@mui/material';
import { CONFIG } from '../../global';

export type ContractInfo = {
    name: string;
    address: string;
}

type Props = {
    contracts: ContractInfo[];
}

export default function ContractList({ contracts }: Props) {
    return (
        <Grid container rowSpacing={0.5}>
            {contracts.map((c, key) => <Grid key={key} item lg={12}>
                <Grid container>
                    <Grid item>
                        <Typography variant="h4">{c.name}:&nbsp;</Typography>
                    </Grid>
                    <Grid item>
                        <Link target="__blank" rel="noreferrer" href={`${CONFIG.explorer}address/${c.address}#code`}>
                            <Typography variant="h5" sx={{ lineHeight: '1.235'}}>
                                {c.address}
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>)}
        </Grid>
    );
}