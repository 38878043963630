import React, { useState } from 'react';
import BigNumber from 'bignumber.js';
import { Typography, Box, Button,  Grid } from '@mui/material';
import { useOnboardProvider } from '../../../../../providers/onboard-provider';
import { CONFIG } from '../../../../../global';
import InputNumber from '../../../../../components/Inputs/InputNumber';
import CompleteButton from '../../../../../components/CompleteButton';
import LoadingButton from '../../../../../components/LoadingButton';
import DisplayValue from '../../../../../components/DisplayValue';
import { TxState } from '../../../../../web3/types';
import Info from '../../../../../components/Info';
import Balance from '../../../../../components/Balance';
import { deposit } from '../../../../../web3/Pool2/deposit';
import ApproveButton from '../../../../../components/ApproveButton';
import TxModal from '../../../../../components/TxModal';

type Props = {
    tokenBalance: BigNumber,
    tokenAllowance: BigNumber,
    lpDepositFee: number,
}

export default function LpDeposit({ tokenBalance, tokenAllowance, lpDepositFee }: Props) {
    const { signer, account } = useOnboardProvider();

    const [txState, setTxState] = useState<TxState>('none');
    const [currentTxHash, setCurrentTxHash] = useState('');

    const [amount, setAmount] = useState<BigNumber>();
    const [inputValue, setInputValue] = useState<number | string>('');

    const isApproved = tokenAllowance.gt(tokenBalance);

    const handleSubmit = async () => {
        if (signer !== undefined && account !== undefined && account !== null && amount !== undefined) {
            const txFinished = () => {
                setInputValue('');
                setAmount(undefined);
                setTxState('finished');
            }
            await deposit(
                {
                    setTxHash: setCurrentTxHash,
                    setTxState: setTxState,
                    setTxError: () => {},
                    txFinished: txFinished,
                },
                signer,
                account,
                amount,
                false
            );
        }
    }

    const setMaxAmount = (event: any) => {
        event.preventDefault();
        if (tokenBalance) {
          setInputValue(tokenBalance.toNumber() / 1e18)
          setAmount(tokenBalance);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const feeMessage = 'The deposit fee starts at 2% and decreases by 0.25% every 8 weeks until reaching 0. The LP tokens taken as a fee are held within the contract as protocol owned liquidity forever.';

    return (<>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1, width: '100%' }}>
                <Button disableRipple onClick={setMaxAmount} variant="text" sx={{ p: 0 }}>
                    <Balance title={'Wallet balance'} tokenBalance={tokenBalance} tokenSymbol={`${CONFIG.lpSymbol} LP`} />
                </Button>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Grid container columnSpacing={0.5} rowSpacing={0.5}>
                    <Grid item xs={12} lg={3}>
                        <InputNumber
                            assetSymbol={CONFIG.lpSymbol}
                            maxAmount={tokenBalance}
                            setRealInput={setAmount}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                        />
                        <Typography variant="body2">
                           
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <ApproveButton isApproved={isApproved} token={CONFIG.LpToken} contract={CONFIG.Pool2} />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        {txState === 'none' || txState === 'pending' || txState === 'confirm' ? <LoadingButton
                            content={'Stake'}
                            variant={isApproved ? "blue" : "outlined"}
                            onClick={handleSubmit}
                            disabled={!isApproved || txState === 'pending' ? true : false || amount === undefined || amount.isZero()}
                            loading={txState === 'pending' ? true : false }
                        /> : <CompleteButton content={'Staked LP tokens'} />}
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Info>
                            LP stake fee:&nbsp;
                            <DisplayValue value={amount !== undefined ? amount.multipliedBy(lpDepositFee / 100) : new BigNumber(0)} decimals={1e18} precision={4} />&nbsp;
                            ({lpDepositFee.toFixed(1)}%)
                        </Info>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <TxModal txState={txState} currentTxHash={currentTxHash} setTxState={setTxState} />
    </>);
}